export const importFields = [
  {
    // Visible in table header and when matching columns.
    label: "First Name",
    // This is the key used for this field when we call onSubmit.
    key: "firstName",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["first name", "first"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Stefanie",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "First Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    label: "Last Name",
    key: "lastName",
    alternateMatches: ["last name", "last"],
    fieldType: {
      type: "input",
    },
    example: "Zanetti",
    validations: [
      {
        rule: "required",
        errorMessage: "Last Name is required",
        level: "error",
      },
    ],
  },
  {
    label: "Group Name",
    key: "company",
    alternateMatches: ["business", "company name", "group name"],
    fieldType: {
      type: "input",
    },
    example: "Success, LLC",
    //   validations: [
    //     {
    //       rule: "required",
    //       errorMessage: "Company is required",
    //       level: "error",
    //     },
    //   ],
  },
  {
    label: "Industry",
    key: "industry",
    alternateMatches: ["field", "type", "industry"],
    fieldType: {
      type: "input",
    },
    example: "Non-Profit",
    //   validations: [
    //     {
    //       rule: "required",
    //       errorMessage: "Company is required",
    //       level: "error",
    //     },
    //   ],
  },
  {
    label: "Email",
    key: "email",
    alternateMatches: ["mail", "e-mail"],
    fieldType: {
      type: "input",
    },
    example: "customer@example.com",
    validations: [
      {
        rule: "required",
        errorMessage: "Email is required",
        level: "error",
      },
    ],
  },
  {
    label: "Phone",
    key: "phone",
    alternateMatches: ["telephone", "phone number"],
    fieldType: {
      type: "input",
    },
    example: "777-555-1234",
    validations: [
      {
        rule: "required",
        errorMessage: "Phone is required",
        level: "error",
      },
    ],
  },
  {
    label: "Address",
    key: "address",
    alternateMatches: ["street", "street address", "address #1"],
    fieldType: {
      type: "input",
    },
    example: "123 Calico Lane",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "City",
    key: "city",
    alternateMatches: ["town", "municipality"],
    fieldType: {
      type: "input",
    },
    example: "Encino",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "State",
    key: "state",
    alternateMatches: ["county"],
    fieldType: {
      type: "input",
    },
    example: "CA",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Zip Code",
    key: "zipCode",
    alternateMatches: ["postal code", "zip"],
    fieldType: {
      type: "input",
    },
    example: "90210",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Group Size",
    key: "groupSize",
    alternateMatches: ["size", "party", "group"],
    fieldType: {
      type: "input",
    },
    example: "20",
    validations: [
      {
        rule: "required",
        errorMessage: "Group Size is required",
        level: "error",
      },
    ],
  },
  {
    label: "Event Date",
    key: "date",
    alternateMatches: ["day", "date", "event date"],
    fieldType: {
      type: "input",
    },
    example: "2023-09-21",
    validations: [
      {
        rule: "required",
        errorMessage: "Event Date is required.",
        level: "error",
      },
    ],
  },
  {
    label: "Event Time",
    key: "time",
    alternateMatches: ["time", "event time"],
    fieldType: {
      type: "input",
    },
    example: "6:00 PM",
    validations: [
      {
        rule: "required",
        errorMessage: "Event Time is required.",
        level: "error",
      },
    ],
  },
  {
    label: "Lead Source",
    key: "leadSource",
    alternateMatches: ["source", "from"],
    fieldType: {
      type: "input",
    },
    example: "Google",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Auditorium",
    key: "auditorium",
    alternateMatches: ["auditorium", "room"],
    fieldType: {
      type: "input",
    },
    example: "Auditorium B",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Seats",
    key: "seats",
    alternateMatches: ["number of seats", "chairs"],
    fieldType: {
      type: "input",
    },
    example: "50",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Ticket Price",
    key: "ticketPrice",
    alternateMatches: ["price", "per ticket"],
    fieldType: {
      type: "input",
    },
    example: "15",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Box Office",
    key: "dollarValue",
    alternateMatches: ["revenue", "money"],
    fieldType: {
      type: "input",
    },
    example: "5000",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Rentrak ID",
    key: "rtkTheaterId",
    alternateMatches: ["theater ID", "theater", "rentrak", "rentrak ID"],
    fieldType: {
      type: "input",
    },
    example: "9989",
    validations: [
      {
        rule: "required",
        errorMessage: "Rentrak ID is required",
        level: "error",
      },
    ],
  },
];
