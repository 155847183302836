import {
  Dropdown,
  Header,
  Label,
  Input,
  Button,
  Progress,
  Icon,
  Popup,
} from "semantic-ui-react";
import CustomEditor from "../Campaigns/Editor/Editor";
import { useEffect, useState, useContext } from "react";
import {
  AGENTS,
  SEND_EMAIL,
  GET_UPLOAD_LINK,
  GET_DOWNLOAD_LINK,
  MAIL_LOG,
} from "../graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import Alert from "../Alert/Alert";
import { AuthContext } from "../AuthContext";
import PageModal from "../PageModal";
import CopyToClipboard from "react-copy-to-clipboard";
import "./style.css";
import MailLog from "./MailLog";
const Mail = () => {
  const { loginContext } = useContext(AuthContext);
  const [emailText, setEmailText] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailAttachment, setAttachment] = useState("");
  const [attachmentName, setAttachmentName] = useState("");
  const [agentsList, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [attachmentToUpload, setAttachmentToUpload] = useState("");
  const [timestamp, setTime] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLargeFile, setLargeFile] = useState(false);
  const [isLargeModal, setLargeModal] = useState(false);
  const [largeLink, setLargeLink] = useState("");
  const [copied, setCopied] = useState(false);
  const [from, setFrom] = useState("tower");
  const [isMailLog, setMailLog] = useState(false);
  const [mailLogList, setMailLogList] = useState([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [recordsDisplayed, setRecordsDisplayed] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [additionalEmails, setAdditionalEmails] = useState("");
  useEffect(() => {
    setNumPages(Math.ceil(mailLogList.length / 50));
    setRecordsDisplayed(mailLogList.length);
  }, [mailLogList]);

  // const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "FLAC"];

  useEffect(() => {
    agents();
    mailLog();
  }, []);

  const handleChangeEmailBody = (e) => {
    setEmailText(e.target.value);
  };

  const [agents] = useLazyQuery(AGENTS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleAgents_Result(result),
    onError: (error) => handleAgents_Error(error),
  });

  const handleAgents_Result = (result) => {
    console.log(result);
    if (result.agents && result.agents.length) {
      const activeOnly = result.agents.filter((x) => x.active);
      let newArray = activeOnly.map((item) =>
        Object.assign({}, item, {
          text: `${item.fullName} (${item.role}) (${item.email})`,
          value: item.agentId,
        })
      );

      setAgents(newArray);
    }
  };

  const handleAgents_Error = (error) => {
    console.log(error);
  };

  const [mailLog] = useLazyQuery(MAIL_LOG, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleMailLog_Result(result),
    onError: (error) => handleMailLog_Error(error),
  });

  const handleMailLog_Result = (result) => {
    console.log(result);
    if (result.mailLog) {
      setMailLogList(result.mailLog);
    }
  };

  const handleMailLog_Error = (error) => {
    console.log(error);
  };

  const handleSend = () => {
    if (!emailSubject) return;
    if (!emailText) return;
    if (selectedAgents.length === 0) return;
    setSending(true);
    const recipientArray = [];
    for (const s of selectedAgents) {
      const agent = agentsList.filter((x) => x.agentId === s);
      recipientArray.push(agent[0].email);
    }
    if (additionalEmails) {
      const arrayOfEmails = additionalEmails.split(",");
      for (const a of arrayOfEmails) {
        recipientArray.push(a);
      }
    }
    console.log("recipientArray", recipientArray);
    let fromMe = false;
    if (from === "me") {
      fromMe = true;
    }
    const recipientList = recipientArray.join(",");
    sendEmail({
      variables: {
        emailText,
        emailSubject,
        recipientList,
        emailAttachment,
        attachmentName,
        fromMe,
      },
    });
  };

  const [sendEmail] = useMutation(SEND_EMAIL, {
    onCompleted: (result) => handleSendEmail_Result(result),
    onError: (error) => handleSendEmail_Error(error),
  });

  const handleSendEmail_Result = (result) => {
    console.log(result);
    if (result.sendEmail.status === "succeeded") {
      setTimeout(() => {
        console.log("inside success reaulst");
        setSending(false);
        setSuccessMessage(result.sendEmail.message);
        resetStateTimer();
      }, 1000);
    }
    if (result.sendEmail.status === "failed") {
      console.log("inside failed reaulst");
      setSending(false);
      setErrorMessage(result.sendEmail.message);
      resetStateTimer();
    }
  };

  const handleSendEmail_Error = (error) => {
    console.log(error);
    setSending(false);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  const resetStateTimer = () => {
    setTimeout(() => {
      setEmailSubject("");
      setEmailText("");
      setSelectedAgents([]);
      setSuccessMessage("");
      setErrorMessage("");
      setAttachment("");
      setAttachmentName("");
      setAttachmentToUpload("");
      setUploaded(false);
      setUploadProgress(0);
      setLargeFile(false);
      setAdditionalEmails("");
      mailLog();
      setTime("");
    }, 3000);
  };

  const handleChangeSelectedAgents = (e, data) => {
    console.log(e, data);
    setSelectedAgents(data.value);
  };

  const handleBulkAddress = (filter) => {
    const selected = [...selectedAgents];
    let newArray = [];
    if (filter === "studios") {
      for (const s of agentsList) {
        if (s.role === "Studio") {
          newArray.push(s.agentId);
        }
      }
    }
    if (filter === "circuits") {
      for (const s of agentsList) {
        if (s.role === "Exhibitor") {
          newArray.push(s.agentId);
        }
      }
    }
    const mergedArray = [...new Set([...selected, ...newArray])];
    setSelectedAgents(mergedArray);
  };

  const getRecipientsText = () => {
    if (selectedAgents.length === 1) {
      return "1 Recipient";
    }
    if (selectedAgents.length > 1) {
      return `${selectedAgents.length} Recipients`;
    }
  };

  const handleChangeSubject = (e) => {
    setEmailSubject(e.target.value);
  };

  const getSendDisabled = () => {
    if (!emailText) return true;
    if (!emailSubject) return true;
    if (sending) return true;
    if (selectedAgents.length === 0) return true;
    return false;
  };

  const handleChangeAttachment = (file) => {
    if (file.size > 10485760) {
      setLargeFile(true);
      setLargeModal(true);
      setAttachmentToUpload(file);
      return;
    }
    setAttachmentToUpload(file);
    setUploading(true);
    handleAttach(file);
    console.log(file);
  };

  const getSize = (attachment) => {
    const mb = attachment.size / (1024 * 1024);
    const firstFourChars = mb.toString().substring(0, 4);
    return `${firstFourChars} MB`;
  };

  const handleAttach = (file) => {
    const time = Math.floor(Date.now() / 1000);
    setTime(time);
    getUploadLink({
      variables: {
        file: `mail/${loginContext.agentId}/${time}/${file.name}`,
      },
    });
  };

  const [getUploadLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLink_Result(result),
    onError: (error) => handleGetUploadLink_Error(error),
  });

  const handleGetUploadLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log(result.getUploadLink.link);
      handleUploadAttachment(result.getUploadLink.link);
    }
  };

  const handleGetUploadLink_Error = (error) => {
    console.log(error);
    setUploading(false);
  };

  const handleUploadAttachment = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": attachmentToUpload.type,
      },
      data: attachmentToUpload,
      onUploadProgress: (progressEvent) => handleUploadProgress(progressEvent),
    });
    console.log(response);
    if (response && response.status === 200) {
      // now generate download link
      getDownloadLink({
        variables: {
          file: `mail/${loginContext.agentId}/${timestamp}/${attachmentToUpload.name}`,
        },
      });
    }
  };

  const handleUploadProgress = (progressEvent) => {
    console.log(progressEvent.progress * 100);
    const value = progressEvent.progress * 100;
    const str = value.toString().slice(0, 3);
    setUploadProgress(parseInt(str));
  };

  const [getDownloadLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLink_Result(result),
    onError: (error) => handleGetDownloadLink_Error(error),
  });

  const handleGetDownloadLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      console.log(result.getDownloadLink.link);
      if (isLargeFile) {
        setUploading(false);
        setUploaded(true);
        setLargeLink(result.getDownloadLink.link);
        return;
      }
      setAttachment(result.getDownloadLink.link);
      setAttachmentName(attachmentToUpload.name);
      setUploading(false);
      setUploaded(true);
    }
  };

  const handleGetDownloadLink_Error = (error) => {
    console.log(error);
    setUploading(false);
  };

  const clearError = () => {
    console.log("clear");
  };

  const handleUploadAnyway = () => {
    handleAttach(attachmentToUpload);
    setUploading(true);
    setLargeModal(false);
  };

  const handleCopyLink = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const handleChangeFrom = (e, data) => {
    setFrom(data.value);
  };

  const handleChangePage = (direction) => {
    if (direction === "up") {
      setPage(page + 1);
    }
    if (direction === "down") {
      setPage(page - 1);
    }
    const scrollingContainer = document.getElementById("scrollingContainer");
    if (scrollingContainer) {
      scrollingContainer.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const getPages = () => {
    let arrayOfPages = [];
    let count = 1;
    while (count <= numPages) {
      const obj = {
        text: count,
        value: count,
      };
      arrayOfPages.push(obj);
      count++;
    }
    // console.log(arrayOfPages);
    return arrayOfPages;
  };

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleMailLog = () => {
    mailLog();
    setMailLog(true);
  };

  const handleFilterMail = () => {
    setFiltered(!filtered);
  };

  const handleChangeAdditionalEmail = (e) => {
    const value = e.target.value;
    setAdditionalEmails(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {successMessage && <Alert successMessage={successMessage} />}
      {errorMessage && <Alert errorMessage={errorMessage} failed />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Header
            style={{
              textAlign: "center",
              margin: "0px",
            }}
            content="Mail"
            subheader="Send email to any user of The Tower."
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Header as="h4" style={{ margin: "0px" }} content="From:" />
          <Dropdown
            onChange={handleChangeFrom}
            fluid
            selection
            value={from}
            options={[
              {
                text: "The Tower (thetower@paperairmedia.com)",
                value: "tower",
              },
              {
                text: `${loginContext.fullName} (${loginContext.email})`,
                value: "me",
              },
            ]}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div
            style={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Header as="h4" style={{ margin: "0px" }} content="To:" />
            <Label
              style={{ marginLeft: "10px" }}
              icon="plus"
              color="blue"
              content="Add All Studios"
              className="custom-button"
              onClick={() => handleBulkAddress("studios")}
            />
            <Label
              style={{ marginLeft: "10px" }}
              icon="plus"
              color="blue"
              content="Add All Exhibitors"
              className="custom-button"
              onClick={() => handleBulkAddress("circuits")}
            />
            <Label
              style={{ marginLeft: "10px" }}
              icon="minus"
              color="grey"
              content="Clear All"
              className="custom-button"
              onClick={() => setSelectedAgents([])}
            />
            <span style={{ marginLeft: "10px" }}>{getRecipientsText()}</span>
          </div>
          <Dropdown
            id="mailSelect"
            search
            selection
            multiple
            fluid
            placeholder="Search..."
            value={selectedAgents}
            onChange={handleChangeSelectedAgents}
            options={agentsList}
          />
        </div>
        <div style={{ marginBottom: "5px" }}>
          <Header as="h4" content="Additional Recipients:" />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Input
            fluid
            placeholder="Enter Comma-Separated Email Addresses..."
            onChange={handleChangeAdditionalEmail}
            value={additionalEmails}
          />
        </div>
        <div style={{ marginBottom: "5px" }}>
          <Header as="h4" content="Email Subject:" />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Input
            placeholder="Enter Email Subject..."
            fluid
            value={emailSubject}
            onChange={handleChangeSubject}
          />
        </div>
        <div style={{ marginBottom: "5px" }}>
          <Header as="h4" content="Email Attachment:" />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <FileUploader
            handleChange={handleChangeAttachment}
            name="file"
            // types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  cursor: "pointer",
                  borderStyle: "dashed",
                  flex: 1,
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!attachmentToUpload && (
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drop a file here
                        <Header.Subheader>Or click to browse</Header.Subheader>
                        <Header.Subheader>
                          Max attachment size 10 MB
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                  )}
                  {attachmentToUpload && (
                    <Header>
                      <Icon name="attach" />
                      <Header.Content>
                        {attachmentToUpload.name}
                        <Header.Subheader>
                          {getSize(attachmentToUpload)}
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                  )}
                </div>
              </div>
            }
          />
          {(uploading || uploaded) && (
            <div>
              <div style={{ marginTop: "20px" }}>
                <Progress
                  percent={uploadProgress}
                  indicating={uploading}
                  success={uploaded}
                  progress
                >
                  {uploading
                    ? "Uploading Attachment..."
                    : uploaded
                    ? "Upload Complete!"
                    : ""}
                </Progress>
              </div>
              {isLargeFile && uploaded && (
                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CopyToClipboard text={largeLink}>
                    <Button
                      disabled={copied}
                      onClick={handleCopyLink}
                      icon="copy"
                      size="large"
                      color="green"
                      content={copied ? "Copied!" : "Copy Link"}
                    />
                  </CopyToClipboard>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ marginBottom: "5px" }}>
          <Header as="h4" content="Email Body:" />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <CustomEditor
            emailText={emailText}
            handleChangeEmailBody={handleChangeEmailBody}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="large"
            icon="bars"
            content="Mail Log"
            primary
            onClick={handleMailLog}
          />
          <Button
            disabled={getSendDisabled()}
            loading={sending}
            size="large"
            icon="send"
            content="Send"
            positive
            onClick={handleSend}
          />
        </div>
      </div>
      <PageModal
        open={isLargeModal}
        setOpen={setLargeModal}
        heading="Attachment Too Large"
        size="small"
        clearError={clearError}
        actions={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              content="Close"
              onClick={() => setLargeModal(false)}
            />
            <Button
              loading={uploading}
              disabled={uploading}
              size="large"
              primary
              content="Upload Anyway"
              onClick={handleUploadAnyway}
            />
          </div>
        }
      >
        <Header content="File is over 10 MB" />
        <p>
          Only files under 10 MB can be included as an attachment. Would like to
          upload the file anyway and send a link to download?
        </p>
      </PageModal>
      <PageModal
        open={isMailLog}
        setOpen={setMailLog}
        heading="Mail Log"
        size="large"
        clearError={clearError}
        actions={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Button
                size="large"
                disabled={page === 1}
                icon="left arrow"
                onClick={() => handleChangePage("down")}
              />
              <Dropdown
                style={{ marginRight: "5px" }}
                selection
                compact
                scrolling
                options={getPages()}
                onChange={(e, data) => setPage(data.value)}
                value={page}
              />
              <Button
                size="large"
                disabled={page === numPages}
                icon="right arrow"
                onClick={() => handleChangePage("up")}
              />
              <span>
                {page === 1 ? 1 : ((page - 1) * 50 + 1).toLocaleString()} to{" "}
                {page * 50 + 1 > recordsDisplayed
                  ? recordsDisplayed.toLocaleString()
                  : (page * 50 + 1).toLocaleString()}{" "}
              </span>
              <span>
                <b>({recordsDisplayed.toLocaleString()} total)</b>
              </span>
            </div>
            <Button
              size="large"
              content="Close"
              onClick={() => setMailLog(false)}
            />
          </div>
        }
      >
        <MailLog
          mailLogList={mailLogList}
          page={page}
          paginate={paginate}
          handleFilterMail={handleFilterMail}
          filtered={filtered}
          loginContext={loginContext}
        />
      </PageModal>
    </div>
  );
};
export default Mail;
