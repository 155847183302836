import { useState } from "react";
import { Header, Form, Message, Divider, Button } from "semantic-ui-react";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
        }}
      >
        <Header style={{ textAlign: "center" }} content="Forgot Password" />
        <Form
          size="large"
          style={{ textAlign: "left" }}
          error={props.errorMessage ? true : false}
        >
          <Form.Input
            label="Email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleChangeEmail}
          />
          <Form.Button
            loading={props.loading}
            disabled={props.loading}
            size="large"
            primary
            fluid
            content="Send Reset Email"
            onClick={() => props.handleSendPasswordReset(email)}
          />
          {props.errorMessage ? (
            <Message error header="Error" content={props.errorMessage} />
          ) : null}
          {props.resetSuccess ? (
            <Message
              positive
              header="Success!"
              content="Email sent successfully. Please check your inbox for password reset link."
            />
          ) : null}
        </Form>
      </div>
    </div>
  );
};
export default ForgotPassword;
