import { useEffect } from "react";
import { Modal, Button } from "semantic-ui-react";
const GatewayModal = (props) => {
  const {
    handleScroll,
    campaign,
    isGatewayEnabled,
    setIsGateway,
    setIsGatewayDecline,
    isGatewayDecline,
  } = props;
  useEffect(() => {
    handleScroll();
  }, []);
  return (
    <Modal open size="small" style={{ background: "rgba(255,255,255,0.6)" }}>
      <Modal.Content
        id="gatewayNode"
        scrolling
        onScroll={handleScroll}
        style={{ background: "rgba(255,255,255,0.6)" }}
      >
        {/* <div>
    <GatewayText />
  </div> */}
        <div dangerouslySetInnerHTML={{ __html: campaign.gateway }} />
      </Modal.Content>

      <Modal.Actions>
        <Button
          style={{
            backgroundColor: campaign.labelColor,
            color: "#FFFFFF",
          }}
          disabled={!isGatewayEnabled}
          content="I Accept"
          icon="check"
          onClick={() => setIsGateway(false)}
        />
        <Button
          disabled={!isGatewayEnabled}
          content="I Don't Accept"
          icon="close"
          onClick={() => setIsGatewayDecline(true)}
        />
      </Modal.Actions>
      <Modal open={isGatewayDecline} size="tiny">
        <Modal.Content>
          <div dangerouslySetInnerHTML={{ __html: campaign.gatewayDecline }} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Go Back"
            onClick={() => setIsGatewayDecline(false)}
          />
        </Modal.Actions>
      </Modal>
    </Modal>
  );
};
export default GatewayModal;
