import { useEffect, useState, useRef } from "react";
import {
  Header,
  Table,
  Segment,
  Icon,
  Message,
  Button,
  Form,
} from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SIGN_UP } from "../graphQueries";
import Background from "../Background";
import { states } from "../states";

const SignUp = () => {
  const [userObject, setUserObject] = useState({});
  const [errorFields, setErrorFields] = useState({
    fullName: false,
    email: false,
    phone: false,
    companyName: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    handleResizeEvent();
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  const handleResizeEvent = () => {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleChangeField = (e, data) => {
    setErrorMessage("");
    console.log(e, data);
    const key = data.id;
    const value = data.value;
    const updateObj = { ...userObject };
    Object.assign(updateObj, { [key]: value });
    setUserObject(updateObj);
    const errors = { ...errorFields };
    Object.assign(errors, { [key]: false });
    setErrorFields(errors);
  };

  const handleSubmit = () => {
    const obj = { ...userObject };
    Object.assign(obj, { role: "Exhibitor" });
    console.log(obj);

    const errors = { ...errorFields };
    if (!obj.fullName) Object.assign(errors, { fullName: true });
    if (!obj.email) Object.assign(errors, { email: true });
    if (!obj.phone) Object.assign(errors, { phone: true });
    if (!obj.address) Object.assign(errors, { address: true });
    if (!obj.city) Object.assign(errors, { city: true });
    if (!obj.state) Object.assign(errors, { state: true });
    if (!obj.zipCode) Object.assign(errors, { zipCode: true });
    if (!obj.companyName) Object.assign(errors, { companyName: true });
    const isEmailValid = obj.email ? looksLikeMail(obj.email) : false;
    if (!isEmailValid) {
      Object.assign(errors, { email: true });
      setErrorMessage("Please enter a valid email address.");
      setErrorFields(errors);
      return;
    }
    console.log("isEmailValid", isEmailValid);
    setErrorFields(errors);
    if (
      obj.fullName &&
      obj.email &&
      obj.phone &&
      obj.address &&
      obj.city &&
      obj.state &&
      obj.zipCode &&
      obj.companyName
    ) {
      setLoading(true);
      // all good, proceed...
      setTimeout(() => {
        signUp({ variables: obj });
      }, 500);
    } else {
      setErrorMessage("Please fill out all required fields.");
    }
  };

  const [signUp] = useMutation(SIGN_UP, {
    onCompleted: (result) => handleSignUp_Result(result),
    onError: (error) => handleSignUp_Error(error),
  });

  const handleSignUp_Result = (result) => {
    setLoading(false);
    console.log(result);
    if (result.signUp && result.signUp.status === "succeeded") {
      setSuccess(true);
    }
  };

  const handleSignUp_Error = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
    console.log(error);
  };

  const looksLikeMail = (str) => {
    const lastAtPos = str.lastIndexOf("@");
    const lastDotPos = str.lastIndexOf(".");
    return (
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      str.indexOf("@@") === -1 &&
      lastDotPos > 2 &&
      str.length - lastDotPos > 2
    );
  };

  return (
    <Background>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {success && (
          <div
            style={{
              backgroundColor: "#f3f3f3",
              padding: "20px",
              borderRadius: "20px",
              maxWidth: "450px",
              width: "100%",
            }}
          >
            <Header
              content="Request Received!"
              subheader="Thank you for signing up! A Paper Airplane account representative will process your request shortly, and you will receive an email with instructions to activate your account as soon as it is ready."
            />
          </div>
        )}
        {!success && (
          <div
            style={{
              backgroundColor: "#f3f3f3",
              padding: "20px",
              borderRadius: isMobile ? "" : "20px",
              maxWidth: isMobile ? "100%" : "450px",
              width: "100%",
              height: isMobile ? "calc(100% - 130px)" : "90%",
              position: isMobile ? "absolute" : "",
              top: isMobile ? "130px" : "",
              zIndex: 1,
              overflowY: "auto",
              maxHeight: "1000px",
            }}
          >
            <div
              style={{ width: "100%", padding: "10px", textAlign: "center" }}
            >
              <Header as="h2">
                Exhibitor Sign Up
                <Header.Subheader style={{ textAlign: "left" }}>
                  Fill out the form below to request access to <b>The Tower</b>.
                  A Paper Airplane representative will reach out to you shortly.
                </Header.Subheader>
              </Header>
            </div>
            <Form size="large">
              <Form.Input
                required
                error={errorFields.fullName}
                value={userObject.fullName || ""}
                id="fullName"
                label="Full Name"
                placeholder="Full Name"
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.email}
                value={userObject.email || ""}
                id="email"
                label="Email"
                placeholder="Email"
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.phone}
                value={userObject.phone || ""}
                id="phone"
                label="Phone"
                placeholder="Phone"
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.companyName}
                value={userObject.companyName || ""}
                id="companyName"
                label="Company / Circuit Name"
                placeholder="Company / Circuit Name"
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.address}
                value={userObject.address || ""}
                id="address"
                label="Street Address"
                placeholder="Street Address"
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.city}
                value={userObject.city || ""}
                id="city"
                label="City"
                placeholder="City"
                onChange={handleChangeField}
              />
              {/* <Form.Input
                required
                error={errorFields.state}
                value={userObject.state || ""}
                id="state"
                label="State"
                placeholder="State"
                onChange={handleChangeField}
              /> */}
              <Form.Select
                required
                error={errorFields.state}
                search
                id="state"
                label="State"
                placeholder="State"
                value={userObject.state || ""}
                options={states}
                onChange={handleChangeField}
              />
              <Form.Input
                required
                error={errorFields.zipCode}
                value={userObject.zipCode || ""}
                id="zipCode"
                label="Zip / Postal Code"
                placeholder="Zip / Postal Code"
                onChange={handleChangeField}
              />
              <p>
                As soon as your account is approved, you will receive an email
                with instructions to activate your account and login.
              </p>
              <Button
                loading={loading}
                disabled={loading}
                size="large"
                primary
                content="Submit"
                fluid
                onClick={handleSubmit}
              />
              {errorMessage && <Message visible error content={errorMessage} />}
            </Form>
          </div>
        )}
      </div>
    </Background>
  );
};

export default SignUp;
