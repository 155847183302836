import { useEffect, useState, useRef } from "react";
import {
  Header,
  Table,
  Segment,
  Icon,
  Input,
  Button,
  Popup,
} from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CIRCUITS,
  ADD_CIRCUIT,
  UPDATE_CIRCUIT,
  THEATERS,
  UPDATE_THEATER,
  DELETE_CIRCUIT,
} from "../graphQueries";
import AddEditCircuit from "./AddEditCircuit";
import PageModal from "../PageModal";
import Alert from "../Alert/Alert";
const Circuits = (props) => {
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({ column: "", order: "ascending" });
  const [filteredList, setFiltered] = useState([]);
  const [circuitsList, setCircuitsList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCircuit, setSelectedCircuit] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [theatersList, setTheatersList] = useState([]);
  const [selectedTheaters, setSelectedTheaters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [addedTheaters, setAddTheaters] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const clearTimerRef = useRef();

  useEffect(() => {
    console.log(circuitsList);
  }, [circuitsList]);

  useEffect(() => {
    console.log(filteredList);
  }, [filteredList]);

  useEffect(() => {
    console.log(selectedTheaters);
  }, [selectedTheaters]);

  useEffect(() => {
    circuits();
  }, []);

  // circuits query
  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });
  const handleCircuits_Result = (result) => {
    if (result.circuits && result.circuits.length) {
      const circuitList = [...result.circuits];
      const sorted = circuitList.sort((a, b) =>
        (a.circuit || "").localeCompare(b.circuit || "")
      );
      setCircuitsList(sorted);
      theaters();
      setInit(false);
    }
  };
  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  // theaters query
  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });

  const handleTheaters_Result = (result) => {
    if (result.theaters) {
      console.log(result.theaters);
      const array = [...result.theaters];
      // let array = result.theaters.map((item) =>
      //   Object.assign({}, item, {
      //     text: `${item.name} (${item.city}, ${item.state})`,
      //     value: item.theaterId,
      //   })
      // );
      const sort = array.sort((a, b) => a.name.localeCompare(b.name));
      setTheatersList(sort);
    }
  };

  const handleTheaters_Error = (error) => {
    console.log(error);
  };

  // searchTheaters query

  const [searchTheaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleSearchTheaters_Result(result),
    onError: (error) => handleSearchTheaters_Error(error),
  });

  const handleSearchTheaters_Result = (result) => {
    if (result.theaters) {
      setSearchResults(result.theaters);
    }
  };

  const handleSearchTheaters_Error = (error) => {
    console.log(error);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...circuitsList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }

    if (column === "circuit" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.circuit || "").localeCompare(b.circuit || ""));
    }
    if (column === "circuit" && newOrder === "descending") {
      sortList.sort((a, b) => (b.circuit || "").localeCompare(a.circuit || ""));
    }

    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setCircuitsList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const handleFilter = (e) => {
    setSearching(true);
    const searchTerm = e.target.value.toUpperCase();
    const filteredArray = circuitsList.filter((x) =>
      `${x.circuit}`.toUpperCase().includes(searchTerm)
    );
    clearTimeout(clearTimerRef.current);
    clearTimerRef.current = setTimeout(() => {
      setSearching(false);
      if (searchTerm === "") {
        setFiltered([]);
      } else {
        setFiltered(filteredArray);
      }
    }, 1000);
  };

  const handleAddCircuit = () => {
    setModalOpen(true);
    setEditMode(false);
    setSelectedCircuit({});
    setAddTheaters([]);
  };

  const handleEditCircuit = () => {
    setModalOpen(true);
    setEditMode(true);
    setAddTheaters([]);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleChangeStringField = (e, data) => {
    console.log(data);
    let key = data.id;
    let value = data.value;
    const circuit = { ...selectedCircuit };
    Object.assign(circuit, { [key]: value });
    setSelectedCircuit(circuit);
  };

  const handleSaveNewCircuit = () => {
    if (!selectedCircuit.circuit) return;
    setLoading(true);
    setDisabled(true);
    addCircuit({ variables: { circuit: selectedCircuit.circuit } });
  };

  const [addCircuit] = useMutation(ADD_CIRCUIT, {
    onCompleted: (result) => handleAddCircuit_Result(result),
    onError: (error) => handleAddCircuit_Error(error),
  });

  const handleAddCircuit_Result = (result) => {
    console.log(result);
    if (result.addCircuit && result.addCircuit.status === "succeeded") {
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Circuit added successfully.");
      circuits();
      theaters();
      resetStateTimer();
    } else {
      setErrorMessage("Failed to add circuit.");
      resetStateTimer();
    }
  };

  const handleAddCircuit_Error = (error) => {
    console.log(error);
    setLoading(false);
    setDisabled(false);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  const handleUpdateTheaters = () => {
    if (addedTheaters.length > 0) {
      for (const theater of addedTheaters) {
        updateTheater({
          variables: {
            theaterId: theater.theaterId,
            circuitId: selectedCircuit.circuitId,
            circuit: selectedCircuit.circuit,
          },
        });
      }
    }
  };

  const [updateTheater] = useMutation(UPDATE_THEATER, {
    onCompleted: (result) => handleUpdateTheater_Result(result),
    onError: (error) => handleUpdateTheater_Error(error),
  });
  const handleUpdateTheater_Result = (result) => {
    console.log(result);
  };
  const handleUpdateTheater_Error = (error) => {
    console.log(error);
  };

  const resetStateTimer = () => {
    setModalOpen(false);
    setEditMode(false);
    setSelectedCircuit({});
    setSelectedTheaters([]);
    setLoading(false);
    setDisabled(false);
    setTimeout(() => {
      setErrorMessage("");
      setSuccess(false);
      setSuccessMessage("");
    }, 3000);
  };

  const handleUpdateCircuit = () => {
    if (!selectedCircuit) return;
    setLoading(true);
    setDisabled(true);
    updateCircuit({
      variables: {
        circuitId: selectedCircuit.circuitId,
        circuit: selectedCircuit.circuit,
      },
    });
    handleUpdateTheaters();
  };

  const [updateCircuit] = useMutation(UPDATE_CIRCUIT, {
    onCompleted: (result) => handleUpdateCircuit_Result(result),
    onError: (error) => handleUpdateCircuit_Error(error),
  });

  const handleUpdateCircuit_Result = (result) => {
    console.log(result);
    if (result.updateCircuit && result.updateCircuit.status === "succeeded") {
      console.log("update success");
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Circuit updated successfully.");
      circuits();
      theaters();
      resetStateTimer();
    } else {
      setLoading(false);
      setErrorMessage("Failed to update.");
      resetStateTimer();
    }
  };

  const handleUpdateCircuit_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    setDisabled(false);
  };

  const handleSelectCircuit = (item) => {
    console.log(item);
    setSelectedCircuit(item);
    // find theaters
    const foundTheaters = theatersList.filter(
      (x) => x.circuitId === item.circuitId
    );
    setSelectedTheaters(foundTheaters);
  };

  const handleTheaterSearchValue = (e) => {
    setSearchValue(e.target.value);
    setSearchResults([]);
  };

  const handleSelectTheater = (theater) => {
    const array = [...addedTheaters];
    array.push(theater);
    setAddTheaters(array);
    setSearchResults([]);
    setSearchValue("");
  };

  const handleSearch = () => {
    searchTheaters({ variables: { name: searchValue } });
  };

  const handleRemoveTheater = (item) => {
    const array = [...addedTheaters];
    const newArray = array.filter((x) => x.theaterId !== item.theaterId);
    setAddTheaters(newArray);
  };

  const handleDeleteCircuit = () => {
    setLoading(true);
    deleteCircuit({ variables: { circuitId: selectedCircuit.circuitId } });
  };

  const [deleteCircuit] = useMutation(DELETE_CIRCUIT, {
    onCompleted: (result) => handleDeleteCircuit_Result(result),
    onError: (error) => handleDeleteCircuit_Error(error),
  });

  const handleDeleteCircuit_Result = (result) => {
    if (result.deleteCircuit) {
      if (result.deleteCircuit.status === "succeeded") {
        // success handlgin
        setLoading(false);
        setSuccess(true);
        setSuccessMessage("Circuit deleted successfully.");
        circuits();
        theaters();
        resetStateTimer();
      }
      if (result.deleteCircuit.status === "failed") {
        // failed handling
        setLoading(false);
        setErrorMessage(result.deleteCircuit.message);
        circuits();
        theaters();
        resetStateTimer();
      }
    }
  };

  const handleDeleteCircuit_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const handleRefresh = () => {
    circuits();
    setInit(true);
  };

  const CircuitsList = () => {
    let circuits = circuitsList;
    if (filteredList && filteredList.length > 0) {
      circuits = filteredList;
    }
    return circuits.map((item, index) => {
      return (
        <Table.Row
          active={item.circuitId === selectedCircuit.circuitId}
          key={index}
          onClick={() => handleSelectCircuit(item)}
        >
          <Table.Cell>{item.circuitId}</Table.Cell>
          <Table.Cell>{item.circuit}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      {errorMessage && <Alert failed successMessage={errorMessage} />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Button
            icon="refresh"
            style={{ opacity: 0, pointerEvents: "none" }}
          />
          <Header
            style={{
              textAlign: "center",
              margin: "0px",
            }}
            content="Circuits"
          />
          <Button
            title="Refresh Circuits List"
            disabled={initializing}
            loading={initializing}
            icon="refresh"
            // style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={handleRefresh}
          />
        </div>
        <Input
          style={{ marginBottom: "20px" }}
          placeholder="Search..."
          icon={
            searching ? (
              <Icon loading name="circle notch" />
            ) : (
              <Icon name="search" />
            )
          }
          iconPosition="left"
          onChange={handleFilter}
        />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Circuits..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              fixed
              sortable
              selectable
              style={{
                borderTop: "0px",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "id" ? "aliceblue" : "",
                    }}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "circuit" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "circuit" ? sort.order : null}
                    onClick={() => handleSort("circuit")}
                  >
                    Name
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <CircuitsList />
              </Table.Body>
            </Table>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={editMode ? "Edit Circuit" : "Add Circuit"}
          noScroll
          actions={
            <div>
              <Button
                size="large"
                positive
                icon="check"
                loading={loading}
                disabled={disabled}
                content="Save"
                onClick={editMode ? handleUpdateCircuit : handleSaveNewCircuit}
              />
            </div>
          }
        >
          <AddEditCircuit
            errorMessage={errorMessage}
            selectedCircuit={selectedCircuit}
            handleChangeStringField={handleChangeStringField}
            theatersList={theatersList}
            selectedTheaters={selectedTheaters}
            handleTheaterSearchValue={handleTheaterSearchValue}
            searchValue={searchValue}
            searchResults={searchResults}
            handleSelectTheater={handleSelectTheater}
            handleSearch={handleSearch}
            addedTheaters={addedTheaters}
            handleRemoveTheater={handleRemoveTheater}
            editMode={editMode}
          />
        </PageModal>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Popup
            open={confirmDelete}
            content={
              <div>
                <p>
                  Are you sure you want to delete {selectedCircuit.circuit}?
                </p>
                <Button
                  positive
                  content="Yes"
                  onClick={() => {
                    handleDeleteCircuit();
                    setConfirmDelete(false);
                  }}
                />
                <Button
                  negative
                  content="No"
                  onClick={() => setConfirmDelete(false)}
                />
              </div>
            }
            trigger={
              <Button
                icon="ban"
                size="large"
                style={{ marginTop: "20px" }}
                disabled={Object.keys(selectedCircuit).length === 0}
                negative
                content="Delete"
                onClick={() => setConfirmDelete(true)}
              />
            }
          />
          <Button
            icon="edit"
            size="large"
            style={{ marginTop: "20px" }}
            disabled={Object.keys(selectedCircuit).length === 0}
            primary
            content="Edit"
            onClick={handleEditCircuit}
          />
          <Button
            icon="plus"
            size="large"
            style={{ marginTop: "20px" }}
            positive
            content="Add"
            onClick={handleAddCircuit}
          />
        </div>
      </div>
    </div>
  );
};
export default Circuits;
