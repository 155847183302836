import { useState, useEffect } from "react";
import {
  Header,
  Form,
  Message,
  Divider,
  Checkbox,
  Button,
  Icon,
  Input,
} from "semantic-ui-react";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberMe = () => {
    if (rememberMe) {
      localStorage.setItem("towerEmail", email);
    } else {
      localStorage.removeItem("towerEmail");
    }
  };

  useEffect(() => {
    const storedLogin = localStorage.getItem("towerEmail");
    if (storedLogin) {
      setEmail(storedLogin);
      setRememberMe(true);
    }
  }, []);

  const handleGoToSignUp = () => {
    window.location = "/signup";
  };

  const handleViewPassword = () => {
    setViewPassword(!viewPassword);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
        }}
      >
        <Header
          as="h2"
          style={{ textAlign: "center" }}
          content="The Tower"
          subheader="Group Sales Dashboard"
        />
        <Form
          size="large"
          style={{ textAlign: "left" }}
          error={props.errorMessage ? true : false}
        >
          <Form.Input
            label="Email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleChangeEmail}
          />
          <Form.Field>
            <label>Password</label>
            <Input
              icon={
                <Icon
                  title="Show/Hide Password"
                  className="cursor-pointer"
                  name={viewPassword ? "eye" : "eye slash"}
                  style={{
                    marginLeft: "5px",
                    pointerEvents: "all",
                    cursor: "pointer",
                  }}
                  onClick={handleViewPassword}
                />
              }
              fluid
              type={viewPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handleChangePassword}
            />
          </Form.Field>
          <Form.Button
            loading={props.loading}
            disabled={props.loading}
            size="large"
            primary
            fluid
            content="Login"
            onClick={() => {
              handleRememberMe();
              props.handleLogin(email, password);
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              style={{ margin: "0px" }}
              label="Remember Me"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <Header
              as="h4"
              style={{ color: "#4682CC", margin: "0px", cursor: "pointer" }}
              onClick={props.handleForgotPassword}
            >
              Forgot Password?
            </Header>
          </div>
          {props.errorMessage ? (
            <Message error header="Error" content={props.errorMessage} />
          ) : null}
        </Form>
        <Divider />
        <p style={{ textAlign: "center" }}>Need an account?</p>
        <Button
          size="large"
          content="Sign Up"
          fluid
          secondary
          onClick={handleGoToSignUp}
        />
      </div>
    </div>
  );
};
export default Login;
