import {
  Button,
  Input,
  List,
  Header,
  Segment,
  Icon,
  Loader,
  Dimmer,
  Popup,
  DimmerDimmable,
  Form,
  TextArea,
} from "semantic-ui-react";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import { useEffect, useState, useContext } from "react";
import Placeholding from "../Placeholder/Placeholder";
const Messages = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [internal, setInternal] = useState(true);
  const {
    messagesList,
    handleAddMessage,
    newMessage,
    handleChangeNewMessage,
    handleDeleteMessage,
  } = props;

  useEffect(() => {
    console.log("scroll to bottom");
    const element = document.getElementById("messagesContainer");
    element.scrollTop = element.scrollHeight;
    setTimeout(() => {
      if (!loading) return;
      setLoading(false);
    }, 700);
  }, [messagesList]);

  const MessageList = () => {
    const array = [...messagesList];
    const filtered = array.filter((x) => x.internal === internal);
    if (filtered.length === 0) {
      return (
        <Segment placeholder>
          <Header icon>
            <Icon name="conversation" />
            No {internal ? "Private" : "Public"} Notes
          </Header>
        </Segment>
      );
    }
    return filtered.map((item, index) => {
      return (
        <div>
          <List.Item
            key={index}
            style={{
              backgroundColor:
                item.agentId === loginContext.agentId ? "#4283CA" : "#E3E3E3",
              marginBottom: "20px",
              padding: "10px",
              borderRadius: "20px",
              borderBottomRightRadius:
                item.agentId === loginContext.agentId ? "0px" : "20px",
              borderBottomLeftRadius:
                item.agentId === loginContext.agentId ? "20px" : "0px",
              width: "70%",
              display: "flex",
              marginLeft: item.agentId === loginContext.agentId ? "30%" : "",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <p>
                <span
                  style={{
                    fontSize: "0.9em",
                    color:
                      item.agentId === loginContext.agentId
                        ? "#FFFFFF"
                        : "#000000",
                  }}
                >
                  {item.agentName} - {moment(item.timestamp).format("LLL")}
                </span>
              </p>

              <p
                style={{
                  fontStyle: item.deleted ? "italic" : "",
                  fontSize: "1.2em",
                  color:
                    item.agentId === loginContext.agentId
                      ? "#FFFFFF"
                      : "#000000",
                }}
              >
                {item.message}
              </p>
            </div>
            {/* {loginContext.agentId === item.agentId && (
            <div>
              <Button icon="edit" onClick={handleEditMessage} />
            </div>
          )} */}

            {loginContext.agentId === item.agentId && !item.deleted && (
              <div>
                <Popup
                  on="click"
                  trigger={
                    <Icon
                      className="custom-button"
                      name="delete"
                      style={{ color: "white" }}
                    />
                  }
                  content={
                    <div>
                      <p>Really delete message?</p>
                      <Button
                        content="Delete"
                        negative
                        onClick={() => handleDeleteMessage(item.messageId)}
                      />
                    </div>
                  }
                />
              </div>
            )}
          </List.Item>
        </div>
      );
    });
  };
  return (
    <div>
      <div
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button.Group size="large">
            <Button
              content="Private"
              positive={internal}
              onClick={() => setInternal(true)}
              style={{ pointerEvents: internal ? "none" : "" }}
            />
            <Button.Or />
            <Button
              content="Public"
              positive={!internal}
              onClick={() => setInternal(false)}
              style={{ pointerEvents: !internal ? "none" : "" }}
            />
          </Button.Group>
          <p>
            Showing {internal ? "Private" : "Public"} Notes{" "}
            <Popup
              trigger={<Icon name="info circle" />}
              content="Private Notes are only visible to you and your team. Public Notes are visible to all users who interact with this lead (e.g. Studios, Exhibitors, etc.)."
            />
          </p>{" "}
        </div>
      </div>
      <div
        id="messagesContainer"
        style={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "500px",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #C3C3C3",
          scrollBehavior: "smooth",
          backgroundColor: "#FFFFFF",
        }}
      >
        {loading && (
          <Segment placeholder>
            <Placeholding />
          </Segment>
        )}
        {messagesList.length && !loading ? (
          <List>
            <MessageList />
          </List>
        ) : (
          !loading && (
            <Segment placeholder>
              <Header icon>
                <Icon name="conversation" />
                No {internal ? "Private" : "Public"} Notes
              </Header>
            </Segment>
          )
        )}
      </div>
      {!loading && (
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Form size="large">
            <Form.TextArea
              style={{ width: "100%", flexGrow: 1 }}
              fluid
              size="large"
              placeholder={`Enter a ${internal ? "Private" : "Public"} Note...`}
              value={newMessage}
              onChange={handleChangeNewMessage}
            />
            <Form.Button
              onClick={() => handleAddMessage(newMessage, internal)}
              content={internal ? "Send Private Note" : "Send Public Note"}
              positive
              fluid
              size="large"
            />
          </Form>
        </div>
      )}
    </div>
  );
};
export default Messages;
