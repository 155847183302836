import { Modal, TransitionablePortal, Message } from "semantic-ui-react";

const PageModal = (props) => {
  const {
    open,
    setOpen,
    heading,
    clearError,
    fullscreen,
    size,
    noScroll,
    styles,
  } = props;

  return (
    <div>
      <style>{`
            .ui.dimmer {
              transition: background-color 0.25s ease;
              background-color: transparent !important;
            }
  
            .modal-fade-in .ui.dimmer {
              background-color: rgba(0,0,0,0.8) !important;
            }
          `}</style>

      <TransitionablePortal
        open={open}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "scale", duration: 250 }}
      >
        <Modal
          closeOnDimmerClick={false}
          closeOnDocumentClick={false}
          size={size}
          style={{ width: fullscreen ? "90%" : "" }}
          id="pageModal"
          closeIcon
          open={true}
          onClose={(event) => {
            document.body.classList.remove("modal-fade-in");
            setOpen(false);
            clearError();
          }}
        >
          <Modal.Header
            style={{ backgroundColor: "#0E6EB8", color: "#FFFFFF" }}
          >
            {heading}
          </Modal.Header>
          <Modal.Content
            style={{ ...styles, backgroundColor: "#f1f1f1" }}
            scrolling={!noScroll}
          >
            {props.children}
          </Modal.Content>
          <Modal.Actions>{props.actions}</Modal.Actions>
        </Modal>
      </TransitionablePortal>
    </div>
  );
};

export default PageModal;
