import { Checkbox, Table } from "semantic-ui-react";
import moment from "moment";
const MailLog = (props) => {
  const {
    mailLogList,
    paginate,
    page,
    handleFilterMail,
    filtered,
    loginContext,
  } = props;

  const MailItems = () => {
    let list = [...mailLogList];
    if (filtered) {
      list = mailLogList.filter((x) => x.from.includes(loginContext.email));
    }
    const paged = paginate(list, 50, page);
    return paged.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{moment(item.createdAt).format("LLL")}</Table.Cell>
          <Table.Cell>{item.from}</Table.Cell>
          <Table.Cell>{item.to}</Table.Cell>
          <Table.Cell>{item.subject}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div>
      <Checkbox
        label="All Mail / My Sent"
        toggle
        checked={filtered}
        onChange={handleFilterMail}
      />
      <Table fixed sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorted="descending">Sent</Table.HeaderCell>
            <Table.HeaderCell>From</Table.HeaderCell>
            <Table.HeaderCell>To</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <MailItems />
        </Table.Body>
      </Table>
    </div>
  );
};
export default MailLog;
