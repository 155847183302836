import { useState } from "react";
import { Icon } from "semantic-ui-react";
import {
  //   BtnBold,
  //   BtnItalic,
  //   BtnLink,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from "react-simple-wysiwyg";

const CustomEditor = (props) => {
  const { emailText, handleChangeEmailBody } = props;

  const BtnBold = createButton("Bold", <Icon name="bold" />, "bold");
  const BtnItalic = createButton("Italic", <Icon name="italic" />, "italic");
  const BtnBulletList = createButton(
    "Bullet list",
    <Icon name="unordered list" />,
    "insertUnorderedList"
  );
  const BtnNumberedList = createButton(
    "Numbered list",
    <Icon name="ordered list" />,
    "insertOrderedList"
  );
  const BtnAlignCenter = createButton(
    "Align center",
    <Icon name="align center" />,
    "justifyCenter"
  );
  const BtnAlignLeft = createButton(
    "Align left",
    <Icon name="align left" />,
    "justifyLeft"
  );
  const BtnAlignRight = createButton(
    "Align right",
    <Icon name="align right" />,
    "justifyRight"
  );
  const BtnLink = createButton(
    "Link",
    <Icon name="linkify" />,
    ({ $selection }) => {
      if ($selection?.nodeName === "A") {
        document.execCommand("unlink");
      } else {
        // eslint-disable-next-line no-alert
        document.execCommand(
          "createLink",
          false,
          prompt("URL", "") || undefined
        );
      }
    }
  );

  return (
    <EditorProvider>
      <Editor
        value={emailText}
        onChange={handleChangeEmailBody}
        containerProps={{
          style: {
            resize: "vertical",
            backgroundColor: "#FFFFFF",
            height: "250px",
            overflowY: "auto",
          },
        }}
      >
        <Toolbar>
          <BtnBold />
          <BtnItalic />
          <BtnLink />
          <BtnAlignLeft />
          <BtnAlignCenter />
          <BtnAlignRight />
          <BtnBulletList />
          <BtnNumberedList />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};

export default CustomEditor;
