import { Modal } from "semantic-ui-react";

const ExpiredCampaign = (props) => {
  const defaultText =
    "Sorry, the Group Sales campaign for this title has ended.";
  return (
    <Modal open size="tiny">
      <Modal.Header>Campaign Expired</Modal.Header>
      <Modal.Content>
        <div
          dangerouslySetInnerHTML={{ __html: props.customText || defaultText }}
        />
      </Modal.Content>
    </Modal>
  );
};
export default ExpiredCampaign;
