import { useEffect, useState, useContext } from "react";
import {
  Dropdown,
  Grid,
  Header,
  Form,
  Table,
  Segment,
  Icon,
  Button,
} from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { QUOTES, UPDATE_CUSTOMER } from "../graphQueries";
import PageModal from "../PageModal";
import QuoteForm from "./QuoteForm";
import Alert from "../Alert/Alert";
import moment from "moment";

const Quotes = (props) => {
  const [quotesList, setQuotesList] = useState([]);
  const [initializing, setInit] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [sort, setSort] = useState({ column: "", order: "ascending" });
  const [selectedQuote, setSelectedQuote] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  useEffect(() => {
    quotes();
  }, []);
  useEffect(() => {
    setInit(false);
  }, [quotesList]);
  // quotes query
  const [quotes] = useLazyQuery(QUOTES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleQuotes_Result(result),
    onError: (error) => handleQuotes_Error(error),
  });
  const handleQuotes_Result = (result) => {
    console.log(result);
    if (result.quotes && result.quotes.length) {
      const quoteList = [...result.quotes];
      const sortList = quoteList.sort((a, b) =>
        (a.created || "").localeCompare(b.created || "")
      );
      setQuotesList(sortList);
    } else {
      setQuotesList([]);
    }
  };
  const handleQuotes_Error = (error) => {
    console.log(error);
  };

  //update customer mutation
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (result) => handleUpdateCustomer_Result(result),
    onError: (error) => handleUpdateCustomer_Error(error),
  });

  const handleUpdateCustomer_Result = (result) => {
    console.log(result);
    if (result.updateCustomer && result.updateCustomer.status === "succeeded") {
      setSuccess(true);
      setAlertMessage("Quote submitted successfully!");
      setModalOpen(false);
      quotes();
      props.handleFetchQuotes();
      setTimeout(() => {
        setSelectedQuote({});
        setSuccess(false);
        setAlertMessage("");
      }, 3000);
    } else {
      setError(true);
      setAlertMessage("Failed to submit quote.");
      setTimeout(() => {
        setError(false);
        setAlertMessage("");
      }, 3000);
    }
  };

  const handleUpdateCustomer_Error = (error) => {
    console.log(error);
    setError(true);
  };

  const handleUpdateCustomer = () => {
    updateCustomer({
      variables: {
        customerId: selectedQuote.customerId,
        quote: selectedQuote.quote,
        status: "Quote Received",
      },
    });
  };

  const handleSelectQuote = (item) => {
    setSelectedQuote(item);
    setModalOpen(true);
  };

  const handleChangeQuoteDetails = (e) => {
    const customer = { ...selectedQuote };
    Object.assign(customer, { quote: e.target.value });
    setSelectedQuote(customer);
  };

  const clearError = () => {
    setError(false);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...quotesList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }

    if (column === "quote" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.quote || "").localeCompare(b.quote || ""));
    }
    if (column === "quote" && newOrder === "descending") {
      sortList.sort((a, b) => (b.quote || "").localeCompare(a.quote || ""));
    }

    if (filteredList && filteredList.length > 0) {
      setFilteredList(sortList);
    } else {
      setQuotesList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const QuotesList = () => {
    let quotes = quotesList;
    if (filteredList && filteredList.length > 0) {
      quotes = filteredList;
    }
    return quotes.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>
            {item.titleName} - {moment(item.date.slice(0, 19)).format("LLL")} -{" "}
            {item.theaterName} - {item.theaterZip}
          </Table.Cell>
          <Table.Cell>
            <Button
              primary
              content="Enter Quote"
              onClick={() => handleSelectQuote(item)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      <div
        style={{
          maxWidth: "1000px",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {success && <Alert successMessage={alertMessage} />}
        {error && <Alert failed={true} successMessage={alertMessage} />}
        <Header style={{ textAlign: "center" }} content="Quote Requests" />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Quote Requests..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && quotesList.length > 0 && (
            <Table
              fixed
              selectable
              style={{
                borderTop: "0px",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "id" ? "aliceblue" : "",
                    }}
                  >
                    Event Details
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "quote" ? "aliceblue" : "",
                    }}
                  >
                    Enter a Quote
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <QuotesList />
              </Table.Body>
            </Table>
          )}
          {!initializing && quotesList.length === 0 && (
            <Segment
              placeholder
              style={{ alignItems: "center", backgroundColor: "#F1F1F1" }}
            >
              <Header content="No Pending Quote Requests" />
              <Icon size="small" name="info" circular />
            </Segment>
          )}
          <PageModal
            open={modalOpen}
            setOpen={setModalOpen}
            clearError={clearError}
            heading="Enter Quote"
            size="large"
            actions={
              <Button
                primary
                content="Submit Quote"
                onClick={handleUpdateCustomer}
              />
            }
          >
            <QuoteForm
              details={selectedQuote}
              handleChangeQuoteDetails={handleChangeQuoteDetails}
            />
          </PageModal>
        </div>
      </div>
    </div>
  );
};
export default Quotes;
