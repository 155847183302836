import {
  Checkbox,
  Header,
  Table,
  Dropdown,
  Form,
  Segment,
  Loader,
  Popup,
  Icon,
  Label,
  Button,
} from "semantic-ui-react";
import USAMap from "react-usa-map";
import "./style.css";
import { useEffect, useState, useContext } from "react";
import {
  CUSTOMERS,
  CUSTOMER_REPORTS,
  THEATERS,
  TITLES,
  STUDIOS,
} from "../graphQueries";
import { useLazyQuery, useCon } from "@apollo/client";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AuthContext } from "../AuthContext";
import { exportData } from "../Customers/exportData";

const Home = () => {
  const { loginContext } = useContext(AuthContext);
  const [selectedState, setSelectedState] = useState({});
  const [contentFilter, setContentFilter] = useState("events");
  const [customersList, setCustomers] = useState([]);
  const [theatersList, setTheaters] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [titlesList, setTitlesList] = useState([]);
  const [studiosList, setStudiosList] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(moment("2023-01-01").startOf("day"))
  );
  const [endDate, setEndDate] = useState(new Date(moment().endOf("day")));
  const [loadingMap, setLoadingMap] = useState(true);
  const [selectedTitleId, setSelectedTitleId] = useState("all");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [eventsList, setEventsList] = useState([]);
  const [titlePerformanceList, setTitlePerformanceList] = useState([]);
  const [theaterPerformanceList, setTheaterPerformanceList] = useState([]);
  const [circuitPerformanceList, setCircuitPerformanceList] = useState([]);
  const [isByTheater, setByTheater] = useState(false);
  const [leadsFilter, setLeadsFilter] = useState("Booked");
  const [topMargin, setTopMargin] = useState("140px");
  const [archived, setArchived] = useState(false);
  const [sort, setSort] = useState({
    column: "releaseDate",
    order: "descending",
  });
  const [statesList, setStatesList] = useState([
    {
      state: "NJ",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "New Jersey",
    },
    {
      state: "NY",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "New York",
    },
    {
      state: "CA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "California",
    },
    {
      state: "DE",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Delaware",
    },
    {
      state: "MO",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Missouri",
    },
    {
      state: "MS",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Mississippi",
    },
    {
      state: "MA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Massachusetts",
    },
    {
      state: "MI",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Michigan",
    },
    {
      state: "MN",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Minnesota",
    },
    {
      state: "ME",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Maine",
    },
    {
      state: "MT",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Montana",
    },
    {
      state: "CT",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Connecticuit",
    },
    {
      state: "RI",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Rhode Island",
    },
    {
      state: "MD",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Maryland",
    },
    {
      state: "VT",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Vermont",
    },
    {
      state: "PA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Pennsylvania",
    },
    {
      state: "OH",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Ohio",
    },
    {
      state: "IL",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Illinois",
    },
    {
      state: "IN",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Indiana",
    },
    {
      state: "KY",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Kentucky",
    },
    {
      state: "KS",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Kansas",
    },
    {
      state: "TN",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Tennessee",
    },
    {
      state: "VA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Virginia",
    },
    {
      state: "WV",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "West Virginia",
    },
    {
      state: "NC",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "North Carolina",
    },
    {
      state: "SC",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "South Carolina",
    },
    {
      state: "GA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Georgia",
    },
    {
      state: "FL",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Florida",
    },
    {
      state: "AL",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Alabama",
    },
    {
      state: "AR",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Arkansas",
    },
    {
      state: "LA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Louisiana",
    },
    {
      state: "TX",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Texas",
    },
    {
      state: "OK",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Oklahoma",
    },
    {
      state: "ID",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Idaho",
    },
    {
      state: "NM",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "New Mexico",
    },
    {
      state: "NV",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Nevada",
    },
    {
      state: "AZ",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Arizona",
    },
    {
      state: "CO",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Colorado",
    },
    {
      state: "UT",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Utah",
    },
    {
      state: "WY",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Wyoming",
    },
    {
      state: "WI",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Wisconsin",
    },
    {
      state: "ND",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "North Dakota",
    },
    {
      state: "SD",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "South Dakota",
    },
    {
      state: "WA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Washington",
    },
    {
      state: "OR",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Oregon",
    },
    {
      state: "NE",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Nebraska",
    },
    {
      state: "IA",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Iowa",
    },
    {
      state: "HI",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Hawaii",
    },
    {
      state: "AK",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "Alaska",
    },
    {
      state: "NH",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "New Hampshire",
    },
    {
      state: "DC",
      color: "#f6f6f6",
      value: 0,
      boxOffice: 0,
      name: "District of Columbia",
    },
  ]);

  const isStudio = loginContext.role === "Studio";
  const isAdmin = loginContext.role === "Admin";

  useEffect(() => {
    customers({ variables: { archived } });
    theaters();
    titles();
    // studios();
  }, [archived]);

  useEffect(() => {
    if (customersList.length && theatersList.length && titlesList.length) {
      handleBuild("events");
      buildTitlePerformance();
      buildTheaterPerformance();
    }
  }, [customersList, theatersList, titlesList, isByTheater]);

  useEffect(() => {
    if (customersList.length && theatersList.length && titlesList.length) {
      handleBuild(contentFilter);
    }
  }, [
    startDate,
    endDate,
    selectedTitleId,
    contentFilter,
    leadsFilter,
    isByTheater,
  ]);

  useEffect(() => {
    handleResizeEvent();
    window.addEventListener("resize", handleResizeEvent);
    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  const handleResizeEvent = () => {
    const element = document.getElementById("controlHeader");
    if (element) {
      setTopMargin(element.offsetHeight + 20 + "px");
    }
  };

  const ranges = [
    {
      text: "Today",
      value: "Today",
      range: moment().startOf("day"),
    },
    {
      text: "This Week",
      value: "This Week",
      range: moment().startOf("week"),
    },
    {
      text: "This Month",
      value: "This Month",
      range: moment().startOf("month"),
    },
    {
      text: "This Year",
      value: "This Year",
      range: moment().startOf("year"),
    },
    {
      text: "All Time",
      value: "All Time",
      range: moment("2023-01-01"),
    },
  ];

  const leadOptions = [
    {
      text: "All",
      value: "all",
    },
    {
      text: "Booked",
      value: "Booked",
    },
  ];

  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });
  const handleTitles_Result = (result) => {
    // console.log(result);
    if (result.titles) {
      let filteredTitles = [...result.titles];
      if (!archived) {
        filteredTitles = filteredTitles.filter((x) => !x.archived);
      }
      // console.log(filteredTitles);
      setTitlesList(filteredTitles);
      let opts = [{ text: "All Titles", value: "all" }];
      for (const title of filteredTitles) {
        const obj = {
          text: title.name,
          value: title.titleId,
        };
        opts.push(obj);
      }
      setTitleOptions(opts);
    }
  };
  const handleTitles_Error = (error) => {
    console.log(error);
  };

  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });

  const handleStudios_Result = (result) => {
    if (result && result.studios) {
      setStudiosList(result.studios);
    }
  };

  const handleStudios_Error = (error) => {
    console.log(error);
  };

  const [customers] = useLazyQuery(CUSTOMER_REPORTS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCustomers_Result(result),
    onError: (error) => handleCustomers_Error(error),
  });

  const handleCustomers_Result = (result) => {
    if (result && result.customerReports) {
      // console.log(result.customerReports);
      setCustomers(result.customerReports);
    }
  };

  const handleCustomers_Error = (error) => {
    console.log(error);
  };

  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });

  const handleTheaters_Result = (result) => {
    if (result && result.theaters) {
      let theaters = result.theaters.map((item) =>
        Object.assign({}, item, {
          leads: 0,
        })
      );
      setTheaters(theaters);
    }
  };

  const handleTheaters_Error = (error) => {
    console.log(error);
  };

  const handleChangeRange = (e, data) => {
    if (data.value === "Today") {
      setStartDate(new Date(moment().startOf("day")));
      setEndDate(new Date(moment().endOf("day")));
    }
    if (data.value === "This Week") {
      setStartDate(new Date(moment().startOf("week")));
      setEndDate(new Date(moment().endOf("day")));
    }
    if (data.value === "This Month") {
      setStartDate(new Date(moment().startOf("month")));
      setEndDate(new Date(moment().endOf("day")));
    }
    if (data.value === "This Year") {
      setStartDate(new Date(moment().startOf("year")));
      setEndDate(new Date(moment().endOf("day")));
    }
    if (data.value === "All Time") {
      setStartDate(new Date(moment("2023-01-01")));
      setEndDate(new Date(moment().endOf("day")));
    }
  };

  const handleChangeTitle = (e, data) => {
    // console.log(data);
    setSelectedTitleId(data.value);
  };

  const handleChangeLeads = (e, data) => {
    // console.log(data);
    setLeadsFilter(data.value);
  };

  const handleBuild = (filter) => {
    // console.log("building");
    // first reset the arrays before rebuilding
    const statesArray = [...statesList];
    for (const n of statesArray) {
      n.value = 0;
      n.boxOffice = 0;
      n.color = "#f6f6f6";
    }
    const theaters = [...theatersList];
    for (const t of theaters) {
      t.boxOffice = 0;
      t.leads = 0;
    }

    // filter customer list based on date range
    let customers = [...customersList];
    let filtered = customers.filter((x) =>
      moment(x.created).isBetween(startDate, endDate)
    );
    if (leadsFilter === "Booked") {
      filtered = filtered.filter((x) => x.status === "Booked");
    }
    if (selectedTitleId !== "all") {
      filtered = filtered.filter((x) => x.titleId === selectedTitleId);
    }
    // console.log(startDate, endDate, filtered);
    let events = [];
    for (const c of filtered) {
      // look up theater
      const theater = theaters.filter((x) => x.theaterId === c.theaterId);
      let existingAmount = theater[0].boxOffice
        ? parseInt(theater[0].boxOffice)
        : 0;
      let newAmount = c.dollarValue ? parseInt(c.dollarValue) : 0;
      Object.assign(theater[0], {
        leads: theater[0].leads + 1,
        boxOffice: existingAmount + newAmount,
      });
      // need to make de-duped list of events: Title, Day, number of events on day, box office on day.

      const eventObj = {
        title: getTitleName(c.titleId),
        start: moment(c.date).format("YYYY-MM-DD"),
        id: c.customerId,
      };
      events.push(eventObj);
      const state = theater[0].state;
      for (const a of statesArray) {
        if (a.state === state) {
          a.value++;
          a.boxOffice = a.boxOffice + newAmount;
        }
      }
    }
    // console.log("events", events);
    // for (const t of titlesList) {
    //   const obj = {
    //     title: t.name,
    //     start: moment(t.releaseDate).format("YYYY-MM-DD"),
    //     backgroundColor: "pink",
    //     borderColor: "pink",
    //     textColor: "#000000",
    //   };
    //   events.push(obj);
    // }
    for (const f of statesArray) {
      if (filter === "events") {
        if (f.value === 0) {
          f.color = "#f6f6f6";
        }
        if (f.value > 0 && f.value < 5) {
          f.color = "#98ffb0";
        }
        if (f.value > 4 && f.value < 10) {
          f.color = "#71fa91";
        }
        if (f.value > 9 && f.value < 20) {
          f.color = "#23f254";
        }
        if (f.value > 19 && f.value < 50) {
          f.color = "#00be2d";
        }
        if (f.value > 49 && f.value < 100) {
          f.color = "#008720";
        }
        if (f.value > 99 && f.value < 200) {
          f.color = "#006718";
        }
        if (f.value > 199 && f.value < 300) {
          f.color = "#003b0e";
        }
        if (f.value > 299) {
          f.color = "#003b0e";
        }
      }
      if (filter === "boxOffice") {
        if (f.boxOffice === 0) {
          f.color = "#f6f6f6";
        }
        if (f.boxOffice > 0 && f.boxOffice < 5000) {
          f.color = "#98ffb0";
        }
        if (f.boxOffice > 4999 && f.boxOffice < 10000) {
          f.color = "#71fa91";
        }
        if (f.boxOffice > 9999 && f.boxOffice < 15000) {
          f.color = "#23f254";
        }
        if (f.boxOffice > 14999 && f.boxOffice < 20000) {
          f.color = "#00be2d";
        }
        if (f.boxOffice > 19999 && f.boxOffice < 25000) {
          f.color = "#008720";
        }
        if (f.boxOffice > 24999 && f.boxOffice < 35000) {
          f.color = "#006718";
        }
        if (f.boxOffice > 34999 && f.boxOffice < 50000) {
          f.color = "#003b0e";
        }
        if (f.boxOffice > 49999) {
          f.color = "#003b0e";
        }
      }
    }
    let cleanedEvents = [];
    for (const e of events) {
      const duplicateEvents = events.filter(
        (x) => x.title === e.title && x.start === e.start
      );
      if (duplicateEvents.length > 1) {
        Object.assign(e, {
          eventCount: duplicateEvents.length,
        });
      }
      cleanedEvents.push(duplicateEvents[0]);
    }
    // console.log("cleanedEvents", cleanedEvents);
    setStatesList(statesArray);
    setEventsList(cleanedEvents);
    buildTitlePerformance(filter);
    setLoadingMap(false);
  };

  const buildTitlePerformance = (filter) => {
    let performanceList = [];
    // first filter title list based on selected title
    let filtedTitles = [...titlesList];
    if (selectedTitleId !== "all") {
      filtedTitles = filtedTitles.filter((x) => x.titleId === selectedTitleId);
    }
    for (const title of filtedTitles) {
      const foundLeadsArray = customersList.filter(
        (x) => x.titleId === title.titleId
      );
      performanceList.push(foundLeadsArray);
    }
    if (performanceList.length === 0) return;
    // now form the final array for rendering
    let renderArray = [];
    for (const array of performanceList) {
      if (array.length) {
        const obj = {
          name: getTitleName(array[0].titleId, titlesList),
          totalLeads: getTotalLeads(array),
          newLeads: getNewLeads(array),
          bookedLeads: getBookedLeads(array),
          boxOffice: getDollarValue(array),
          inProgress: getInProgressLeads(array),
          coldLeads: getColdLeads(array),
          percentageBooked: getPercentageBooked(array),
          titleId: array[0].titleId,
          releaseDate: getReleaseDate(array[0].titleId),
          studio: getStudio(array[0].studioId),
        };
        renderArray.push(obj);
      }
    }
    let array1 = [...renderArray];
    // let array2 = [...renderArray];
    const sorted = array1.sort((a, b) =>
      (b.releaseDate || "").localeCompare(a.releaseDate || "")
    );
    // const sortedBox = array2.sort(
    //   (a, b) => parseInt(b.boxOffice) - parseInt(a.boxOffice)
    // );
    // setTitlePerformanceList(filter === "events" ? sorted : sortedBox);
    setTitlePerformanceList(sorted);
  };

  const buildTheaterPerformance = () => {
    let theatersArray = [];
    let circuitsArray = [];
    let resultArray = [];
    const bookedCustomers = customersList.filter((x) => x.status === "Booked");
    for (const b of bookedCustomers) {
      theatersArray = [...new Set([...theatersArray, b.theaterId])];
      circuitsArray = [...new Set([...circuitsArray, b.circuitId])];
    }
    if (isByTheater) {
      for (const t of theatersArray) {
        const obj = {
          theaterId: t,
          name: getTheaterName(t),
          circuit: getCircuitName(t),
          bookedEvents:
            bookedCustomers.filter((x) => x.theaterId === t).length || 0,
        };
        resultArray.push(obj);
      }
      const sorted = resultArray.sort(
        (a, b) => b.bookedEvents - a.bookedEvents
      );
      setTheaterPerformanceList(sorted);
    }
    if (!isByTheater) {
      for (const c of circuitsArray) {
        const obj = {
          circuitId: c,
          circuit: getCircuitById(c),
          bookedEvents:
            bookedCustomers.filter((x) => x.circuitId === c).length || 0,
        };
        resultArray.push(obj);
      }
      const sorted = resultArray.sort(
        (a, b) => b.bookedEvents - a.bookedEvents
      );
      setCircuitPerformanceList(sorted);
    }
  };

  const getStudio = (studioId) => {
    const studio = studiosList.filter((x) => x.studioId === studioId);
    if (studio.length) {
      return studio[0].name;
    }
  };

  const getReleaseDate = (titleId) => {
    const releaseDate = titlesList.filter((x) => x.titleId === titleId);
    if (releaseDate.length) {
      return releaseDate[0].releaseDate;
    }
  };

  const getPercentageBooked = (array) => {
    let totalLeads = 0;
    let bookedLeads = 0;
    for (const a of array) {
      if (
        moment(a.created).isBetween(startDate, endDate) &&
        a.status !== "Duplicate"
      ) {
        totalLeads++;
      }
    }
    for (const a of array) {
      if (
        a.status === "Booked" &&
        moment(a.created).isBetween(startDate, endDate)
      ) {
        bookedLeads++;
      }
    }
    const percentage = (bookedLeads * 100) / totalLeads;
    return percentage;
  };

  const getDollarValue = (array) => {
    let value = 0;
    for (const a of array) {
      if (a.dollarValue && moment(a.created).isBetween(startDate, endDate)) {
        value = value + parseInt(a.dollarValue);
      }
    }
    return value;
  };

  const getTotalLeads = (array) => {
    let value = 0;
    for (const a of array) {
      if (moment(a.created).isBetween(startDate, endDate)) {
        value++;
      }
    }
    return value;
  };

  const getBookedLeads = (array) => {
    let value = 0;
    for (const a of array) {
      if (
        a.status === "Booked" &&
        moment(a.created).isBetween(startDate, endDate)
      ) {
        value++;
      }
    }
    return value;
  };

  const getInProgressLeads = (array) => {
    let value = 0;
    for (const a of array) {
      if (
        (a.status === "Contacted" || a.status === "Approved") &&
        moment(a.created).isBetween(startDate, endDate)
      ) {
        value++;
      }
    }
    return value;
  };

  const getColdLeads = (array) => {
    let value = 0;
    for (const a of array) {
      if (
        a.status === "Cold" &&
        moment(a.created).isBetween(startDate, endDate)
      ) {
        value++;
      }
    }
    return value;
  };

  const getNewLeads = (array) => {
    let value = 0;
    for (const a of array) {
      if (
        a.status === "New" &&
        moment(a.created).isBetween(startDate, endDate)
      ) {
        value++;
      }
    }
    return value;
  };

  const handleEventClick = (e) => {
    // console.log(e);
    const customerId = e.event._def.publicId;
    // console.log(customerId);
    const customer = customersList.filter((x) => x.customerId === customerId);
    if (customer.length) {
      // console.log(customer[0]);
      setSelectedEvent(customer[0]);
    }
  };

  const handleDateClick = (e) => {
    // console.log(e);
  };

  const getTitleName = (titleId) => {
    const titles = titlesList.filter((x) => x.titleId === titleId);
    if (titles.length) {
      return titles[0].name;
    } else {
      return "All Titles";
    }
  };

  const getTheaterName = (theaterId) => {
    const theaters = theatersList.filter((x) => x.theaterId === theaterId);
    if (theaters.length) {
      return theaters[0].name;
    }
  };

  const handleClick = (e) => {
    const state = e.target.dataset.name;
    // console.log(e.target.dataset.name);
    const array = statesList.filter((x) => x.state === state);
    setSelectedState(array[0]);
  };
  const getFill = (state) => {
    const array = statesList.filter((x) => x.state === state);
    if (array.length) {
      return array[0].color;
    }
  };
  const getTheatersListByState = (state) => {
    let array = [];
    const theaters = theatersList.filter(
      (x) => x.state === state && x.leads > 0
    );
    let sorted = [];
    if (contentFilter === "events") {
      sorted = theaters.sort((a, b) => b.leads - a.leads);
    }
    if (contentFilter === "boxOffice") {
      sorted = theaters.sort((a, b) => b.boxOffice - a.boxOffice);
    }
    return sorted;
  };

  const TheatersList = (props) => {
    const { list } = props;
    return list.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item.circuit}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>
            {contentFilter === "events"
              ? item.leads.toLocaleString()
              : contentFilter === "boxOffice"
              ? `$${item.boxOffice.toLocaleString()}`
              : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const getMapHeight = () => {
    const element = document.getElementsByClassName("us-state-map");
    if (element) {
      return `${element.clientHeight}px`;
    }
  };

  const handleChangeContenFilter = (filter) => {
    // console.log("handleChangeContenFilter", filter);
    setContentFilter(filter);
  };

  const getStateBoxOffice = () => {
    if (selectedState) {
      const amount = selectedState.boxOffice || 0;
      return amount.toLocaleString();
    } else {
      return 0;
    }
  };

  const EventDetail = () => {
    return (
      <div>
        <Table.Row>
          <Table.Cell>First Name:</Table.Cell>
          <Table.Cell>{selectedEvent.firstName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Last Name:</Table.Cell>
          <Table.Cell>{selectedEvent.lastName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Group Name:</Table.Cell>
          <Table.Cell>{selectedEvent.company}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Industry:</Table.Cell>
          <Table.Cell>{selectedEvent.industry}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Event Date / Time:</Table.Cell>
          <Table.Cell>{moment(selectedEvent.date).format("LLL")}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Title:</Table.Cell>
          <Table.Cell>{getTitleName(selectedEvent.titleId)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Theater:</Table.Cell>
          <Table.Cell>{getTheaterName(selectedEvent.theaterId)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Status:</Table.Cell>
          <Table.Cell>{selectedEvent.status}</Table.Cell>
        </Table.Row>
      </div>
    );
  };

  const TopTitles = () => {
    return titlePerformanceList.map((item, index) => {
      return (
        <Table.Row key={index}>
          {/* {!isStudio && <Table.Cell>{item.studio}</Table.Cell>} */}
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{moment(item.releaseDate).format("LL")}</Table.Cell>
          <Table.Cell>{item.totalLeads.toLocaleString()}</Table.Cell>
          <Table.Cell>{item.newLeads.toLocaleString()}</Table.Cell>
          <Table.Cell>{item.inProgress.toLocaleString()}</Table.Cell>
          <Table.Cell>{item.coldLeads.toLocaleString()}</Table.Cell>
          <Table.Cell>{item.bookedLeads.toLocaleString()}</Table.Cell>
          <Table.Cell>${item.boxOffice.toLocaleString()}</Table.Cell>
          <Table.Cell>{Math.round(item.percentageBooked)}%</Table.Cell>
        </Table.Row>
      );
    });
  };

  const TopTheaters = () => {
    if (isByTheater) {
      return theaterPerformanceList.map((item, index) => {
        return (
          <Table.Row key={index}>
            <Table.Cell>{item.circuit}</Table.Cell>
            <Table.Cell>{item.name}</Table.Cell>
            <Table.Cell>{item.bookedEvents}</Table.Cell>
          </Table.Row>
        );
      });
    }
    if (!isByTheater) {
      return circuitPerformanceList.map((item, index) => {
        return (
          <Table.Row key={index}>
            <Table.Cell>{item.circuit}</Table.Cell>
            <Table.Cell>{item.bookedEvents}</Table.Cell>
          </Table.Row>
        );
      });
    }
  };

  const getTitlePerformanceMetrics = (metric) => {
    if (metric === "percentageBooked") {
      let totalLeads = 0;
      let bookedLeads = 0;
      for (const a of titlePerformanceList) {
        if (moment(a.created).isBetween(startDate, endDate)) {
          if (selectedTitleId === "all") {
            totalLeads = totalLeads + a.totalLeads;
          } else {
            if (selectedTitleId === a.titleId) {
              totalLeads = totalLeads + a.totalLeads;
            }
          }
        }
      }
      for (const a of titlePerformanceList) {
        if (moment(a.created).isBetween(startDate, endDate)) {
          if (selectedTitleId === "all") {
            bookedLeads = bookedLeads + a.bookedLeads;
          } else {
            if (selectedTitleId === a.titleId) {
              bookedLeads = bookedLeads + a.bookedLeads;
            }
          }
        }
      }
      // console.log(totalLeads, bookedLeads);
      const percentage = (bookedLeads * 100) / totalLeads;
      return Math.round(percentage) || 0;
    }
    let total = 0;
    for (const t of titlePerformanceList) {
      if (moment(t.created).isBetween(startDate, endDate)) {
        if (selectedTitleId === "all") {
          total = total + t[metric];
        } else {
          if (t.titleId === selectedTitleId) {
            total = total + t[metric];
          }
        }
      }
    }
    return total.toLocaleString();
  };

  const handleChangeActiveFilter = (e, data) => {
    if (data.value === "active") {
      setArchived(false);
    } else {
      setArchived(true);
    }
  };

  const handleExport = () => {
    if (titlePerformanceList.length) {
      let newArray = [];
      for (const t of titlePerformanceList) {
        let newObj = {
          Title: t.name,
          "Release Date": moment(t.releaseDate).format("LL"),
          "Total Leads": t.totalLeads.toLocaleString(),
          "New Leads": t.newLeads.toLocaleString(),
          "In Progress": t.inProgress.toLocaleString(),
          "Cold Leads": t.coldLeads.toLocaleString(),
          "Booked Events": t.bookedLeads.toLocaleString(),
          "Box Office": `$${t.boxOffice.toLocaleString()}`,
          "Conversion Rate": `${Math.round(t.percentageBooked)}%`,
        };
        newArray.push(newObj);
      }
      exportData(
        newArray,
        `Title Performance Report ${moment().format("YYYY-MM-DD h-mm-ss a")}`,
        "xlsx"
      );
    }
  };

  const getResultsMessage = () => {
    let part1 = archived ? "All Campaigns" : "Active Campaigns";
    let part2 =
      selectedTitleId !== "all" ? getTitleName(selectedTitleId) : "All Titles";
    if (selectedTitleId !== "all") {
      return getTitleName(selectedTitleId);
    }
    return `${part2} from ${part1}`;
  };

  const getCircuitName = (theaterId) => {
    const theater = theatersList.filter((x) => x.theaterId === theaterId);
    return theater[0].circuit;
  };

  const getCircuitById = (circuitId) => {
    const customer = customersList.filter((x) => x.circuitId === circuitId);
    const theater = theatersList.filter(
      (x) => x.theaterId === customer[0].theaterId
    );
    return theater[0].circuit;
  };

  const statesCustomConfig = () => {
    return {
      NJ: {
        fill: getFill("NJ"),
      },
      NY: {
        fill: getFill("NY"),
      },
      MO: {
        fill: getFill("MO"),
      },
      MA: {
        fill: getFill("MA"),
      },
      MS: {
        fill: getFill("MS"),
      },
      MT: {
        fill: getFill("MT"),
      },
      FL: {
        fill: getFill("FL"),
      },
      GA: {
        fill: getFill("GA"),
      },
      TN: {
        fill: getFill("TN"),
      },
      WA: {
        fill: getFill("WA"),
      },
      OR: {
        fill: getFill("OR"),
      },
      ND: {
        fill: getFill("ND"),
      },
      SD: {
        fill: getFill("SD"),
      },
      WV: {
        fill: getFill("WV"),
      },
      VA: {
        fill: getFill("VA"),
      },
      KY: {
        fill: getFill("KY"),
      },
      TX: {
        fill: getFill("TX"),
      },
      ME: {
        fill: getFill("ME"),
      },
      DE: {
        fill: getFill("DE"),
      },
      RI: {
        fill: getFill("RI"),
      },
      PA: {
        fill: getFill("PA"),
      },
      OH: {
        fill: getFill("OH"),
      },
      ID: {
        fill: getFill("ID"),
      },
      IA: {
        fill: getFill("IA"),
      },
      CO: {
        fill: getFill("CO"),
      },
      CA: {
        fill: getFill("CA"),
      },
      NM: {
        fill: getFill("NM"),
      },
      NV: {
        fill: getFill("NV"),
      },
      UT: {
        fill: getFill("UT"),
      },
      AL: {
        fill: getFill("AL"),
      },
      LA: {
        fill: getFill("LA"),
      },
      MD: {
        fill: getFill("MD"),
      },
      NC: {
        fill: getFill("NC"),
      },
      SC: {
        fill: getFill("SC"),
      },
      CT: {
        fill: getFill("CT"),
      },
      VT: {
        fill: getFill("VT"),
      },
      NH: {
        fill: getFill("NH"),
      },
      DC: {
        fill: getFill("DC"),
      },
      OK: {
        fill: getFill("OK"),
      },
      AR: {
        fill: getFill("AR"),
      },
      IL: {
        fill: getFill("IL"),
      },
      IN: {
        fill: getFill("IN"),
      },
      MI: {
        fill: getFill("MI"),
      },
      WI: {
        fill: getFill("WI"),
      },
      MN: {
        fill: getFill("MN"),
      },
      NE: {
        fill: getFill("NE"),
      },
      KS: {
        fill: getFill("KS"),
      },
      WY: {
        fill: getFill("WY"),
      },
      AZ: {
        fill: getFill("AZ"),
      },
      AK: {
        fill: getFill("AK"),
      },
      HI: {
        fill: getFill("HI"),
      },
    };
  };

  const handleSort = (column) => {
    // console.log(column);
    let newOrder = sort.order;
    if (column === sort.column) {
      newOrder = sort.order === "ascending" ? "descending" : "ascending";
    }

    let sortList = [...titlePerformanceList];
    //
    if (column === "totalLeads" && newOrder === "ascending") {
      sortList.sort((a, b) => a.totalLeads - b.totalLeads);
    }
    if (column === "totalLeads" && newOrder === "descending") {
      sortList.sort((a, b) => b.totalLeads - a.totalLeads);
    }
    //
    if (column === "releaseDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.releaseDate || "").localeCompare(b.releaseDate || "")
      );
    }
    if (column === "releaseDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.releaseDate || "").localeCompare(a.releaseDate || "")
      );
    }
    //
    if (column === "bookedLeads" && newOrder === "ascending") {
      sortList.sort((a, b) => a.bookedLeads - b.bookedLeads);
    }
    if (column === "bookedLeads" && newOrder === "descending") {
      sortList.sort((a, b) => b.bookedLeads - a.bookedLeads);
    }
    //
    if (column === "coldLeads" && newOrder === "ascending") {
      sortList.sort((a, b) => a.coldLeads - b.coldLeads);
    }
    if (column === "coldLeads" && newOrder === "descending") {
      sortList.sort((a, b) => b.coldLeads - a.coldLeads);
    }
    //
    if (column === "newLeads" && newOrder === "ascending") {
      sortList.sort((a, b) => a.newLeads - b.newLeads);
    }
    if (column === "newLeads" && newOrder === "descending") {
      sortList.sort((a, b) => b.newLeads - a.newLeads);
    }
    //
    if (column === "inProgress" && newOrder === "ascending") {
      sortList.sort((a, b) => a.inProgress - b.inProgress);
    }
    if (column === "inProgress" && newOrder === "descending") {
      sortList.sort((a, b) => b.inProgress - a.inProgress);
    }
    //
    if (column === "boxOffice" && newOrder === "ascending") {
      sortList.sort((a, b) => a.boxOffice - b.boxOffice);
    }
    if (column === "boxOffice" && newOrder === "descending") {
      sortList.sort((a, b) => b.boxOffice - a.boxOffice);
    }
    //
    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) => (b.name || "").localeCompare(a.name || ""));
    }
    //
    if (column === "studio" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.studio || "").localeCompare(b.studio || ""));
    }
    if (column === "studio" && newOrder === "descending") {
      sortList.sort((a, b) => (b.studio || "").localeCompare(a.studio || ""));
    }
    //
    if (column === "percentageBooked" && newOrder === "ascending") {
      sortList.sort(
        (a, b) => (a.percentageBooked || "") - (b.percentageBooked || "")
      );
    }
    if (column === "percentageBooked" && newOrder === "descending") {
      sortList.sort(
        (a, b) => (b.percentageBooked || "") - (a.percentageBooked || "")
      );
    }
    setSort({ column, order: newOrder });
    setTitlePerformanceList(sortList);
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        top: "50px",
        backgroundColor: loginContext.backgroundColor || "#CCCCCC",
        zIndex: 1,
      }}
    >
      <div
        id="controlHeader"
        style={{
          width: "calc(100% - 20px)",
          margin: "10px",
          marginTop: "10px",
          backgroundColor: "#F7F7F7",
          padding: "10px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "fixed",
          zIndex: 2,
          boxShadow: "0px -12px 14px 20px rgba(0,0,0,0.55)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            flexGrow: 1,
          }}
        >
          <Header
            as="h2"
            content="Group Sales Performance"
            subheader="Event and Box Office Report"
            style={{ margin: "10px" }}
          />
          <Form style={{ display: "flex", flexWrap: "wrap", flexGrow: 1 }}>
            {/* <Form.Field style={{ marginRight: "10px" }}>
              <label>Events:</label>
              <Dropdown
                selection
                options={leadOptions}
                onChange={handleChangeLeads}
                defaultValue="Booked"
              />
            </Form.Field> */}
            <Form.Field style={{ marginRight: "10px", flexGrow: 1 }}>
              <label>Campaigns:</label>
              <Dropdown
                selection
                options={[
                  { text: "Active Campaigns", value: "active" },
                  { text: "All Campaigns", value: "all" },
                ]}
                onChange={handleChangeActiveFilter}
                defaultValue="active"
              />
            </Form.Field>
            <Form.Field style={{ marginRight: "10px", flexGrow: 1 }}>
              <label>Title:</label>
              <Dropdown
                selection
                options={titleOptions}
                onChange={handleChangeTitle}
                defaultValue="all"
              />
            </Form.Field>
            <Form.Field style={{ marginRight: "10px", flexGrow: 1 }}>
              <label>Date Range:</label>
              <Dropdown
                selection
                options={ranges}
                onChange={handleChangeRange}
                defaultValue="All Time"
              />
            </Form.Field>
            <Form.Field style={{ marginRight: "10px", flexGrow: 1 }}>
              <label>Start Date:</label>
              <ReactDatePicker
                startDate={startDate}
                endDate={endDate}
                selectsStart
                selected={startDate || ""}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Field>
            <Form.Field style={{ marginRight: "10px", flexGrow: 1 }}>
              <label>End Date:</label>
              <ReactDatePicker
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                selectsEnd
                selected={endDate || ""}
                onChange={(date) => setEndDate(date)}
              />
            </Form.Field>
          </Form>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #D1D1D1",
              borderRadius: "5px",
              justifyContent: "space-between",
              width: "200px",
              backgroundColor: "#F7F7F7",
              marginTop: "10px",
              height: "35px",
            }}
          >
            <div
              onClick={() => handleChangeContenFilter("events")}
              style={{
                backgroundColor: contentFilter === "events" ? "#4283CA" : "",
                color: contentFilter === "events" ? "#FFFFFF" : "",
                fontWeight: contentFilter === "events" ? "800" : "",
                boxShadow:
                  contentFilter === "events"
                    ? "0px 0px 0px 3px rgba(66,131,202,1)"
                    : "",
                padding: "8px",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100px",
              }}
            >
              Events
            </div>

            <div
              onClick={() => handleChangeContenFilter("boxOffice")}
              style={{
                backgroundColor: contentFilter === "boxOffice" ? "#4283CA" : "",
                color: contentFilter === "boxOffice" ? "#FFFFFF" : "",
                fontWeight: contentFilter === "boxOffice" ? "800" : "",
                boxShadow:
                  contentFilter === "boxOffice"
                    ? "0px 0px 0px 3px rgba(66,131,202,1)"
                    : "",
                padding: "8px",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                width: "100px",
              }}
            >
              Box Office
            </div>
          </div> */}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#888888",
          borderRadius: "10px",
          width: "calc(100% - 20px)",
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          marginTop: topMargin,
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("totalLeads")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="grey">
              Total Leads
            </Label>
          </div>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("newLeads")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="green">
              New Leads
            </Label>
          </div>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("inProgress")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="blue">
              In Progress
            </Label>
          </div>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("coldLeads")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="black">
              Cold Leads
            </Label>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("bookedLeads")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="purple">
              Booked Events
            </Label>
          </div>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              ${getTitlePerformanceMetrics("boxOffice")}
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="purple">
              Box Office
            </Label>
          </div>
          <div
            style={{
              // width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
              flexGrow: 1,
            }}
          >
            <Header as="h1">
              {getTitlePerformanceMetrics("percentageBooked")}%
              <Header.Subheader
                style={{
                  maxWidth: "120px",
                  fontSize: "12px",
                  fontStyle: "italic",
                }}
              >
                {getTitleName(selectedTitleId)}
              </Header.Subheader>
            </Header>
            <Label size="large" color="purple">
              Conversion Rate
            </Label>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#888888",
          borderRadius: "10px",
          width: "calc(100% - 20px)",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          // marginTop: topMargin,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", zIndex: 1 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#F7F7F7",
              padding: "10px",
              borderRadius: "10px",
              margin: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Header as="h2" style={{ paddingTop: "5px" }}>
                Title Performance
                <span style={{ fontWeight: 600 }}>
                  <Popup
                    trigger={
                      <Icon
                        name="info circle"
                        style={{ fontSize: ".7em", marginLeft: "5px" }}
                      />
                    }
                    content="Use the above Date Filters to change the data shown in the Title Performance Chart."
                  />
                </span>
                <Header.Subheader>
                  <b>Date Filter: </b>
                  {`${moment(startDate).format("LL")} to ${moment(
                    endDate
                  ).format("LL")}`}
                </Header.Subheader>
                <Header.Subheader>
                  <b>Showing Results for:</b> {getResultsMessage()}
                </Header.Subheader>
              </Header>
              <Button
                size="large"
                secondary
                icon="download"
                content="Export"
                onClick={handleExport}
              />
            </div>
            <Table celled size="large" sortable striped>
              <Table.Header>
                <Table.Row>
                  {/* {!isStudio && (
                    <Table.HeaderCell
                      sorted={sort.column === "studio" ? sort.order : null}
                      onClick={() => handleSort("studio")}
                    >
                      Studio
                    </Table.HeaderCell>
                  )} */}
                  <Table.HeaderCell
                    sorted={sort.column === "name" ? sort.order : null}
                    onClick={() => handleSort("name")}
                  >
                    Title
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "releaseDate" ? sort.order : null}
                    onClick={() => handleSort("releaseDate")}
                  >
                    Release Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "totalLeads" ? sort.order : null}
                    onClick={() => handleSort("totalLeads")}
                  >
                    Total Leads
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "newLeads" ? sort.order : null}
                    onClick={() => handleSort("newLeads")}
                  >
                    New Leads
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "inProgress" ? sort.order : null}
                    onClick={() => handleSort("inProgress")}
                  >
                    In Progress
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "coldLeads" ? sort.order : null}
                    onClick={() => handleSort("coldLeads")}
                  >
                    Cold Leads
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "bookedLeads" ? sort.order : null}
                    onClick={() => handleSort("bookedLeads")}
                  >
                    Booked Events
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column === "boxOffice" ? sort.order : null}
                    onClick={() => handleSort("boxOffice")}
                  >
                    Box Office
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      sort.column === "percentageBooked" ? sort.order : null
                    }
                    onClick={() => handleSort("percentageBooked")}
                  >
                    Conversion Rate
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{!loadingMap && <TopTitles />}</Table.Body>
            </Table>

            {loadingMap && (
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader
                  inline="centered"
                  active
                  content="Loading Performance Data..."
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {isAdmin && (
        <div
          style={{
            backgroundColor: "#888888",
            borderRadius: "10px",
            width: "calc(100% - 20px)",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            // marginTop: topMargin,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", zIndex: 1 }}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#F7F7F7",
                padding: "10px",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Header as="h2" style={{ paddingTop: "5px" }}>
                  Theater Performance
                  <span style={{ fontWeight: 600 }}>
                    <Popup
                      trigger={
                        <Icon
                          name="info circle"
                          style={{ fontSize: ".7em", marginLeft: "5px" }}
                        />
                      }
                      content="Use the above Date Filters to change the data shown in the Title Performance Chart."
                    />
                  </span>
                  <Header.Subheader>
                    <b>Date Filter: </b>
                    {`${moment(startDate).format("LL")} to ${moment(
                      endDate
                    ).format("LL")}`}
                  </Header.Subheader>
                  <Header.Subheader>
                    <b>Showing Results for:</b> {getResultsMessage()}
                  </Header.Subheader>
                </Header>

                <Button.Group>
                  <Button
                    secondary={isByTheater}
                    onClick={() => setByTheater(!isByTheater)}
                  >
                    By Theater
                  </Button>
                  <Button.Or />
                  <Button
                    secondary={!isByTheater}
                    onClick={() => setByTheater(!isByTheater)}
                  >
                    By Circuit
                  </Button>
                </Button.Group>
              </div>
              <Table celled size="large" sortable striped fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Circuit</Table.HeaderCell>
                    {isByTheater && (
                      <Table.HeaderCell>Theater</Table.HeaderCell>
                    )}
                    <Table.HeaderCell>Events</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{!loadingMap && <TopTheaters />}</Table.Body>
              </Table>

              {loadingMap && (
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Loader
                    inline="centered"
                    active
                    content="Loading Performance Data..."
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: "#888888",
          borderRadius: "10px",
          width: "calc(100% - 20px)",
          margin: "10px",
          display: "flex",
          flexDirection: "column",
          // marginTop: "105px",
        }}
      >
        {loadingMap && (
          <div
            style={{
              width: "100%",
              height: "calc(100vh - 190px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader
              inverted
              inline="centered"
              active
              content="Loading Map..."
            />
          </div>
        )}
        {!loadingMap && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "600px",
            }}
          >
            <USAMap
              title="Select a State"
              width={window.innerWidth - 300}
              defaultFill="#f6f6f6"
              onClick={handleClick}
              customize={statesCustomConfig()}
            />

            <div
              style={{
                width: "380px",
                minWidth: "380px",
                margin: "10px",
                backgroundColor: "#F7F7F7",
                overflow: "auto",
                height: getMapHeight(),
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Header>
                {selectedState.name || "No State Selected"}
                <Header.Subheader>
                  {selectedState.name && contentFilter === "events"
                    ? `${selectedState.value} events`
                    : selectedState.name && contentFilter === "boxOffice"
                    ? `$${getStateBoxOffice()} Box Office`
                    : ""}
                </Header.Subheader>
              </Header>
              <Table celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Circuit</Table.HeaderCell>
                    <Table.HeaderCell>Theater</Table.HeaderCell>
                    <Table.HeaderCell>
                      {contentFilter === "events"
                        ? "Events"
                        : contentFilter === "boxOffice"
                        ? "Box Office"
                        : ""}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {selectedState.name && (
                  <Table.Body>
                    <TheatersList
                      list={getTheatersListByState(selectedState.state)}
                    />
                  </Table.Body>
                )}
              </Table>
              {!selectedState.name && (
                <Segment placeholder style={{ height: "calc(100% - 100px)" }}>
                  <Header style={{ textAlign: "center" }}>
                    Select a State to see detailed Theater and Event Data
                  </Header>
                </Segment>
              )}
            </div>
          </div>
        )}
      </div>
      {!loadingMap && (
        <div
          style={{
            backgroundColor: "#888888",
            borderRadius: "10px",
            width: "calc(100% - 20px)",
            margin: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", zIndex: 1 }}>
            <div
              id="calendarContainer"
              style={{
                width: "100%",
                backgroundColor: "#F7F7F7",
                padding: "10px",
                borderRadius: "10px",
                margin: "10px",
              }}
            >
              <FullCalendar
                headerToolbar={{
                  start: "title",
                  center: "dayGridMonth,dayGridWeek,dayGridDay",
                  end: "today prev,next",
                }}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={eventsList}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
              />
            </div>

            <div
              style={{
                width: "380px",
                minWidth: "380px",
                margin: "10px",
                backgroundColor: "#F7F7F7",
                overflow: "auto",
                // height: getMapHeight(),
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <Header>
                {moment(selectedEvent.date).format("LLL") ||
                  "No Event Selected"}
              </Header>
              {selectedEvent.date && (
                <Table>
                  <EventDetail />
                </Table>
              )}
              {!selectedEvent.date && (
                <Segment placeholder style={{ height: "calc(100% - 90px)" }}>
                  <Header style={{ textAlign: "center" }}>
                    Select an event to see detail
                  </Header>
                </Segment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;
