import { Header, Icon } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import { useLazyQuery } from "@apollo/client";
import { CAMPAIGNS } from "../graphQueries";
const ComingSoon = () => {
  const [controlsVisible, setControls] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [url, setUrl] = useState("");
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const playerRef = useRef();

  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const campaignId = urlParams.get("campaignId");
    if (campaignId) {
      campaigns({ variables: { campaignId } });
    }
  }, []);

  useEffect(() => {
    console.log("played", played);
  }, [played]);

  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const handleCampaigns_Result = (result) => {
    console.log(result);
    if (result.campaigns && result.campaigns.length) {
      setUrl(result.campaigns[0].trailerUrl);
    }
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleMouseMove = () => {
    setControls(!controlsVisible);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000000",
        flexDirection: "column",
      }}
    >
      <Header inverted as="h1" style={{ fontSize: "3em" }}>
        TICKETS COMING SOON!
      </Header>
      <div
        style={{ width: "640px", height: "360px" }}
        onMouseEnter={handleMouseMove}
        onMouseLeave={handleMouseMove}
      >
        <div
          style={{
            opacity: !playing || controlsVisible ? 1 : 0,
            transition: "opacity 1s",
            backgroundColor:
              !playing || controlsVisible ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)",
            width: "640px",
            height: "360px",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            className="control-button"
            style={{ color: "#FFFFFF", zIndex: 1 }}
            size="huge"
            name={playing ? "pause" : "play"}
            onClick={() => setPlaying(!playing)}
          />
          <Icon
            className="control-button"
            style={{
              color: "#FFFFFF",
              zIndex: 1,
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
            size="large"
            name={muted ? "volume off" : "volume up"}
            onClick={() => setMuted(!muted)}
          />
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "95%",
              zIndex: 1,
            }}
          >
            <input
              style={{ width: "100%", zIndex: 1 }}
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
            />
          </div>
        </div>
        <ReactPlayer
          ref={playerRef}
          playing={playing}
          muted={muted}
          url={url}
          onEnded={() => setPlaying(false)}
          onProgress={handleProgress}
        />
      </div>
    </div>
  );
};
export default ComingSoon;
