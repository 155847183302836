import { useEffect, useState, useRef } from "react";
import { Header, Table, Segment, Icon, Input, Button } from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import {
  STUDIOS,
  ADD_STUDIO,
  UPDATE_STUDIO,
  GET_UPLOAD_LINK,
  GET_DOWNLOAD_LINK,
} from "../graphQueries";
import AddEditStudio from "./AddEditStudio";
import PageModal from "../PageModal";
import Alert from "../Alert/Alert";
const Studios = (props) => {
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({ column: "", order: "ascending" });
  const [filteredList, setFiltered] = useState([]);
  const [studiosList, setStudiosList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [newStudioName, setNewStudioName] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");

  const clearTimerRef = useRef();

  useEffect(() => {
    console.log(studiosList);
  }, [studiosList]);

  useEffect(() => {
    console.log(filteredList);
  }, [filteredList]);

  useEffect(() => {
    studios();
  }, []);

  // studios query
  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });
  const handleStudios_Result = (result) => {
    if (result.studios && result.studios.length) {
      const studioList = result.studios;
      setStudiosList(studioList);
      setInit(false);
    }
  };
  const handleStudios_Error = (error) => {
    console.log(error);
  };

  const [getUploadLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLink_Result(result),
    onError: (error) => handleGetUploadLink_Error(error),
  });
  const handleGetUploadLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log("handleGetUploadLink_Result", result.getUploadLink.link);
      handleUploadLogo(result.getUploadLink.link);
    }
  };
  const handleGetUploadLink_Error = (error) => {
    console.log(error);
  };
  const handleUploadLogo = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": fileToUpload.type,
      },
      data: fileToUpload,
    });
    console.log(response);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...studiosList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }

    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) => (b.name || "").localeCompare(a.name || ""));
    }

    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setStudiosList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const handleFilter = (e) => {
    setSearching(true);
    const searchTerm = e.target.value.toUpperCase();
    const filteredArray = studiosList.filter((x) =>
      `${x.name}`.toUpperCase().includes(searchTerm)
    );
    clearTimeout(clearTimerRef.current);
    clearTimerRef.current = setTimeout(() => {
      setSearching(false);
      if (searchTerm === "") {
        setFiltered([]);
      } else {
        setFiltered(filteredArray);
      }
    }, 1000);
  };

  const handleAddStudio = () => {
    setModalOpen(true);
    setEditMode(false);
    setNewStudioName("");
    setSelectedStudio({});
    setFileToUpload(null);
    setLogoPreview("");
  };

  const handleEditStudio = () => {
    setModalOpen(true);
    setLogoPreview("");
    setNewStudioName(selectedStudio.name);
    setEditMode(true);
    setFileToUpload(null);
    if (selectedStudio.logoPath) {
      //need to download existing logo for preview
      getDownloadLink({ variables: { file: selectedStudio.logoPath } });
    }
  };

  const [getDownloadLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLink_Result(result),
    onError: (error) => handleGetDownloadLink_Error(error),
  });

  const handleGetDownloadLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setLogoPreview(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadLink_Error = (error) => {
    console.log(error);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleChangeStudioName = (e) => {
    setNewStudioName(e.target.value);
  };

  const handleSaveNewStudio = () => {
    if (!newStudioName) return;
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      if (editMode) {
        console.log("updateStudio() here");
      } else {
        addStudio({ variables: { name: newStudioName } });
      }
    }, 500);
  };

  const [addStudio] = useMutation(ADD_STUDIO, {
    onCompleted: (result) => handleAddStudio_Result(result),
    onError: (error) => handleAddStudio_Error(error),
  });

  const handleAddStudio_Result = (result) => {
    console.log(result);
    if (result.addStudio && result.addStudio.status === "succeeded") {
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Studio added successfully.");
      studios();
      resetStateTimer();
      if (fileToUpload !== null) {
        getUploadLink({
          variables: {
            file: `studios/${result.addStudio.studioId}/${fileToUpload.name}`,
          },
        });
        updateStudio({
          variables: {
            studioId: result.addStudio.studioId,
            logoPath: `studios/${result.addStudio.studioId}/${fileToUpload.name}`,
          },
        });
      }
    } else {
      setErrorMessage("Failed to add studio.");
      resetStateTimer();
    }
  };

  const handleAddStudio_Error = (error) => {
    console.log(error);
    setLoading(false);
    setDisabled(false);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  const resetStateTimer = () => {
    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
      setErrorMessage("");
      setSuccess(false);
      setSuccessMessage("");
      setNewStudioName("");
      setModalOpen(false);
      setEditMode(false);
      setSelectedStudio({});
      setFileToUpload(null);
      setLogoPreview("");
    }, 2000);
  };

  const handleUpdateStudio = () => {
    if (!selectedStudio) return;
    const obj = { ...selectedStudio };
    if (newStudioName) {
      Object.assign(obj, { name: newStudioName });
    }
    let uploadFile = false;
    if (fileToUpload !== null) {
      Object.assign(obj, {
        logoPath: `studios/${obj.studioId}/${fileToUpload.name}`,
      });
      uploadFile = true;
    }
    if (uploadFile) {
      console.log("uploadFile", `studios/${obj.studioId}/${fileToUpload.name}`);
      getUploadLink({
        variables: {
          file: `studios/${obj.studioId}/${fileToUpload.name}`,
        },
      });
    }
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      updateStudio({
        variables: obj,
      });
    }, 500);
  };

  const [updateStudio] = useMutation(UPDATE_STUDIO, {
    onCompleted: (result) => handleUpdateStudio_Result(result),
    onError: (error) => handleUpdateStudio_Error(error),
  });

  const handleUpdateStudio_Result = (result) => {
    console.log(result);
    if (result.updateStudio && result.updateStudio.status === "succeeded") {
      console.log("update success");
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Studio updated successfully.");
      studios();
      resetStateTimer();
    } else {
      setLoading(false);
      setErrorMessage("Failed to update.");
      resetStateTimer();
    }
  };

  const handleUpdateStudio_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    setDisabled(false);
  };

  const handleChangeLogo = (file) => {
    console.log(file);
    setFileToUpload(file);
  };

  const StudiosList = () => {
    let studios = studiosList;
    if (filteredList && filteredList.length > 0) {
      studios = filteredList;
    }
    return studios.map((item, index) => {
      return (
        <Table.Row
          active={item.studioId === selectedStudio.studioId}
          key={index}
          onClick={() => setSelectedStudio(item)}
        >
          <Table.Cell>{item.studioId}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header style={{ textAlign: "center" }} content="Studios" />
        <Input
          style={{ marginBottom: "20px" }}
          placeholder="Search..."
          icon={
            searching ? (
              <Icon loading name="circle notch" />
            ) : (
              <Icon name="search" />
            )
          }
          iconPosition="left"
          onChange={handleFilter}
        />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Studios..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              fixed
              sortable
              selectable
              style={{
                borderTop: "0px",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "id" ? "aliceblue" : "",
                    }}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "name" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "name" ? sort.order : null}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <StudiosList />
              </Table.Body>
            </Table>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={editMode ? "Edit Studio" : "Add Studio"}
          actions={
            <div>
              <Button
                size="large"
                positive
                icon="check"
                loading={loading}
                disabled={disabled}
                content="Save"
                onClick={editMode ? handleUpdateStudio : handleSaveNewStudio}
              />
            </div>
          }
        >
          <AddEditStudio
            errorMessage={errorMessage}
            newStudioName={newStudioName}
            handleChangeStudioName={handleChangeStudioName}
            fileToUpload={fileToUpload}
            handleChangeLogo={handleChangeLogo}
            logoPreview={logoPreview}
          />
        </PageModal>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            icon="edit"
            size="large"
            style={{ marginTop: "20px" }}
            disabled={Object.keys(selectedStudio).length === 0}
            primary
            content="Edit"
            onClick={handleEditStudio}
          />
          <Button
            icon="plus"
            size="large"
            style={{ marginTop: "20px" }}
            positive
            content="Add"
            onClick={handleAddStudio}
          />
        </div>
      </div>
    </div>
  );
};
export default Studios;
