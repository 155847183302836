import { useState } from "react";
import { Image } from "semantic-ui-react";
import moment from "moment";
const Background = (props) => {
  const [backgroundImage] = useState("url(/images/background.jpg)");
  const [logo] = useState("/images/logo-blue.png");

  const handleGoHome = () => {
    window.location = "/";
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundImage: props.backgroundImage || backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <Image
          style={{ width: "200px", cursor: "pointer" }}
          src={props.logo || logo}
          onClick={handleGoHome}
        />
      </div>
      {props.children}
      <div style={{ position: "absolute", bottom: "2px", left: "5px" }}>
        <span style={{ color: "#FFFFFF", fontSize: "10px" }}>
          &copy; {moment().format("YYYY")} PaperAirMedia. All Rights Reserved.
        </span>
      </div>
    </div>
  );
};
export default Background;
