import { useEffect, useState } from "react";
import { Header, Icon, Segment, Table } from "semantic-ui-react";
import { useLazyQuery } from "@apollo/client";
import { CHANGES } from "../graphQueries";
import moment from "moment";

const ChangeLog = () => {
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({ column: "", order: "ascending" });
  const [changesList, setChangesList] = useState([]);
  const [selectedChange, setSelectedChange] = useState({});

  useEffect(() => {
    changes();
  }, []);

  useEffect(() => {
    setInit(false);
  }, [changesList]);

  const [changes] = useLazyQuery(CHANGES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleChanges_Result(result),
    onError: (error) => handleChanges_Error(error),
  });

  const handleChanges_Result = (result) => {
    console.log(result);
    if (result && result.changes) {
      setChangesList(result.changes);
    }
  };

  const handleChanges_Error = (error) => {
    console.log(error);
  };

  const handleSort = () => {};
  const handleSelectChange = (obj) => {
    setSelectedChange(obj);
  };

  const getChangeContent = (string, newOrOld) => {
    if (string === null) {
      return "-";
    }
    const obj = JSON.parse(string);
    console.log(obj);
    let returnArray = [];
    for (const key in obj) {
      const array = obj[key];
      console.log(key, array[newOrOld]);
      returnArray.push({ [key]: array[newOrOld] });
    }
    console.log(returnArray);
    return returnArray.map((item, index) => {
      return <p key={index}>{JSON.stringify(item)}</p>;
    });
  };

  const ActivityList = () => {
    return changesList.map((item, index) => {
      return (
        <Table.Row
          active={item.changeId === selectedChange.changeId}
          key={index}
          onClick={() => handleSelectChange(item)}
        >
          <Table.Cell>{moment(item.timestamp).format("LLL")}</Table.Cell>
          <Table.Cell>
            {item.customerFirstName} {item.customerLastName}{" "}
            {item.customerEmail} {item.customerId}
          </Table.Cell>
          <Table.Cell>{item.agentName}</Table.Cell>
          <Table.Cell>{item.apiCommonName}</Table.Cell>
          <Table.Cell>{getChangeContent(item.content, "oldObj")}</Table.Cell>
          <Table.Cell>{getChangeContent(item.content, "newObj")}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Header
            style={{
              textAlign: "center",
              margin: "0px",
            }}
            content="Activity Log"
          />
        </div>
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Activity..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              fixed
              sortable
              selectable
              style={{
                borderTop: "0px",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "id" ? "aliceblue" : "",
                    }}
                  >
                    Time Stamp
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "agentId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "agentId" ? sort.order : null}
                    onClick={() => handleSort("agentId")}
                  >
                    Customer
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "agentId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "agentId" ? sort.order : null}
                    onClick={() => handleSort("agentId")}
                  >
                    User
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "apiName" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "apiName" ? sort.order : null}
                    onClick={() => handleSort("apiName")}
                  >
                    Action
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "originalValue" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "originalValue" ? sort.order : null}
                    onClick={() => handleSort("originalValue")}
                  >
                    Original Value
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "newValue" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "newValue" ? sort.order : null}
                    onClick={() => handleSort("newValue")}
                  >
                    New Value
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <ActivityList />
              </Table.Body>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};
export default ChangeLog;
