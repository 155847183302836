import { useEffect, useState, useRef, useContext } from "react";
import { Header, Table, Segment, Icon, Input, Button } from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  TITLES,
  CAMPAIGNS,
  STUDIOS,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_UPLOAD_LINK,
  GET_DOWNLOAD_LINK,
} from "../graphQueries";
import AddEditCampaign from "./AddEditCampaign";
import PageModal from "../PageModal";
import Alert from "../Alert/Alert";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import ViewPolicy from "./ViewPolicy";
import { clear } from "@testing-library/user-event/dist/clear";

const Campaigns = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({
    column: "createdDate",
    order: "descending",
  });
  const [filteredList, setFiltered] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [titleList, setTitlesList] = useState([]);
  const isAdmin = loginContext.role.includes("Admin");
  const isAdminOrStudio = !loginContext.role.includes("Exhibitor");
  const isExhibitor = loginContext.role.includes("Exhibitor");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [logoToUpload, setLogoToUpload] = useState(null);
  const [posterPreview, setPosterPreview] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [studiosList, setStudiosList] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [isViewPolicy, setViewPolicy] = useState(false);

  const clearTimerRef = useRef();

  const readOnly = loginContext.readOnly;

  useEffect(() => {
    console.log(campaignsList);
  }, [campaignsList]);

  useEffect(() => {
    console.log(filteredList);
  }, [filteredList]);

  useEffect(() => {
    campaigns();
    titles();
  }, []);

  useEffect(() => {
    if (!loginContext.studioId) {
      studios();
    }
  }, []);

  useEffect(() => {
    console.log(selectedCampaign);
  }, [selectedCampaign]);

  // campaigns query
  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });
  const handleCampaigns_Result = (result) => {
    if (result.campaigns && result.campaigns) {
      const campaignList = [...result.campaigns];
      setCampaignsList(campaignList);
      setInit(false);
    }
  };
  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  // titles query
  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });
  const handleTitles_Result = (result) => {
    if (result.titles && result.titles.length) {
      let newArray = result.titles.map((item) =>
        Object.assign({}, item, { text: item.name, value: item.titleId })
      );

      setTitlesList(newArray);
    }
  };
  const handleTitles_Error = (error) => {
    console.log(error);
  };

  // studios query
  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });
  const handleStudios_Result = (result) => {
    if (result.studios && result.studios.length) {
      let newArray = result.studios.map((item) =>
        Object.assign({}, item, { text: item.name, value: item.studioId })
      );

      setStudiosList(newArray);
    }
  };
  const handleStudios_Error = (error) => {
    console.log(error);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...campaignsList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }

    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) => (b.name || "").localeCompare(a.name || ""));
    }

    if (column === "rating" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.rating || "").localeCompare(b.rating || ""));
    }
    if (column === "rating" && newOrder === "descending") {
      sortList.sort((a, b) => (b.rating || "").localeCompare(a.rating || ""));
    }

    if (column === "titleId" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.titleId || "").localeCompare(b.titleId || ""));
    }
    if (column === "titleId" && newOrder === "descending") {
      sortList.sort((a, b) => (b.titleId || "").localeCompare(a.titleId || ""));
    }

    if (column === "releaseDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.releaseDate || "").localeCompare(b.releaseDate || "")
      );
    }
    if (column === "releaseDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.releaseDate || "").localeCompare(a.releaseDate || "")
      );
    }

    if (column === "startDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.startDate || "").localeCompare(b.startDate || "")
      );
    }
    if (column === "startDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.startDate || "").localeCompare(a.startDate || "")
      );
    }

    if (column === "createdDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.createdDate || "").localeCompare(b.createdDate || "")
      );
    }
    if (column === "createdDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.createdDate || "").localeCompare(a.createdDate || "")
      );
    }

    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setCampaignsList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const handleFilter = (e) => {
    setSearching(true);
    const searchTerm = e.target.value.toUpperCase();
    const filteredArray = campaignsList.filter((x) =>
      `${x.name}`.toUpperCase().includes(searchTerm)
    );
    clearTimeout(clearTimerRef.current);
    clearTimerRef.current = setTimeout(() => {
      setSearching(false);
      if (searchTerm === "") {
        setFiltered([]);
      } else {
        setFiltered(filteredArray);
      }
    }, 1000);
  };

  const handleAddCampaign = () => {
    setModalOpen(true);
    setEditMode(false);
    setSelectedCampaign({
      layout: "layout1",
      limitedTheaters: true,
      defaultRadius: "8047",
      preventNearSelection: true,
      nearSelectionDays: "7",
    });
  };

  const handleEditCampaign = () => {
    setModalOpen(true);
    setEditMode(true);
    if (selectedCampaign.artworkPath) {
      getDownloadPosterLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.artworkPath}`,
        },
      });
    }
    if (selectedCampaign.logoPath) {
      getDownloadLogoLink({
        variables: {
          file: `campaigns/${selectedCampaign.campaignId}/${selectedCampaign.logoPath}`,
        },
      });
    }
  };

  const clearError = () => {
    setErrorMessage("");
    setPosterPreview("");
    setLogoPreview("");
  };

  // const handleChangeSelectedCampaign = (e, data) => {
  //   // new handling here for all field changes
  //   console.log(e, data);
  //   const key = data.id;
  //   const value = data.value;
  //   const obj = { ...selectedCampaign };
  //   if (data === "releaseDate" || data === "startDate") {
  //     Object.assign(obj, { [data]: e });
  //   }
  //   if (key === "name" || key === "rating" || key === "titleId") {
  //     Object.assign(obj, { [key]: value });
  //   }
  //   setSelectedCampaign(obj);
  // };

  const handleChangeTitle = (e, data) => {
    const obj = { ...selectedCampaign };
    if (data.id === "titleId") {
      Object.assign(obj, {
        titleId: data.value,
      });
      setSelectedCampaign(obj);
    }
  };

  const [getUploadPosterLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLink_Result(result),
    onError: (error) => handleGetUploadLink_Error(error),
  });

  const handleGetUploadLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log(result.getUploadLink.link);
      handleUploadPoster(result.getUploadLink.link);
    }
  };

  const handleGetUploadLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const [getUploadLogoLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLogoLink_Result(result),
    onError: (error) => handleGetUploadLogoLink_Error(error),
  });

  const handleGetUploadLogoLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log(result.getUploadLink.link);
      handleUploadLogo(result.getUploadLink.link);
    }
  };

  const handleGetUploadLogoLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const handleUploadPoster = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": fileToUpload.type,
      },
      data: fileToUpload,
    });
    console.log(response);
    if (response && response.status === 200) {
      setFileToUpload(null);
    }
  };

  const handleUploadLogo = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": logoToUpload.type,
      },
      data: logoToUpload,
    });
    console.log(response);
    if (response && response.status === 200) {
      setLogoToUpload(null);
    }
  };

  const [getDownloadPosterLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadPosterLink_Result(result),
    onError: (error) => handleGetDownloadPosterLink_Error(error),
  });

  const handleGetDownloadPosterLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setPosterPreview(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadPosterLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const [getDownloadLogoLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLogoLink_Result(result),
    onError: (error) => handleGetDownloadLogoLink_Error(error),
  });

  const handleGetDownloadLogoLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setLogoPreview(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadLogoLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const handleSaveNewCampaign = () => {
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      if (editMode) {
        console.log("updateCampaign() here");
      } else {
        // prepare titleId string
        let titles = selectedCampaign.titleId.toString();
        Object.assign(selectedCampaign, { titleId: titles });
        addCampaign({
          variables: selectedCampaign,
        });
      }
    }, 500);
  };

  const [addCampaign] = useMutation(ADD_CAMPAIGN, {
    onCompleted: (result) => handleAddCampaign_Result(result),
    onError: (error) => handleAddCampaign_Error(error),
  });

  const handleAddCampaign_Result = (result) => {
    console.log(result);
    if (result.addCampaign && result.addCampaign.status === "succeeded") {
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Campaign added successfully.");
      campaigns();
      resetStateTimer();
      if (fileToUpload !== null) {
        getUploadPosterLink({
          variables: {
            file: `campaigns/${result.addCampaign.campaignId}/${fileToUpload.name}`,
          },
        });
      }
      if (logoToUpload !== null) {
        getUploadLogoLink({
          variables: {
            file: `campaigns/${result.addCampaign.campaignId}/${logoToUpload.name}`,
          },
        });
      }
    } else {
      setErrorMessage("Failed to add campaign.");
      resetStateTimer();
    }
  };

  const handleAddCampaign_Error = (error) => {
    console.log(error);
    setLoading(false);
    setDisabled(false);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  const resetStateTimer = () => {
    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
      setErrorMessage("");
      setSuccess(false);
      setSuccessMessage("");
      setModalOpen(false);
      setEditMode(false);
      setSelectedCampaign({});
    }, 2000);
  };

  const handleUpdateCampaign = () => {
    if (!selectedCampaign) return;
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      let titles = selectedCampaign.titleId.toString();
      Object.assign(selectedCampaign, { titleId: titles });
      updateCampaign({
        variables: selectedCampaign,
      });
    }, 500);
  };

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    onCompleted: (result) => handleUpdateCampaign_Result(result),
    onError: (error) => handleUpdateCampaign_Error(error),
  });

  const handleUpdateCampaign_Result = (result) => {
    console.log(result);
    if (result.updateCampaign && result.updateCampaign.status === "succeeded") {
      console.log("update success");
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Campaign updated successfully.");
      campaigns();
      resetStateTimer();
      if (fileToUpload !== null) {
        getUploadPosterLink({
          variables: {
            file: `campaigns/${result.updateCampaign.campaignId}/${fileToUpload.name}`,
          },
        });
      }
      if (logoToUpload !== null) {
        getUploadLogoLink({
          variables: {
            file: `campaigns/${result.updateCampaign.campaignId}/${logoToUpload.name}`,
          },
        });
      }
    } else {
      setLoading(false);
      setErrorMessage("Failed to update.");
      resetStateTimer();
    }
  };

  const handleUpdateCampaign_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    setDisabled(false);
  };

  const handleChangePosterArt = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    if (selectedCampaign.layout === "layout1") {
      image = await resizeImagePoster(file, fileExt);
    }
    if (selectedCampaign.layout === "layout2") {
      image = await resizeImageBackground(file, fileExt);
    }
    if (selectedCampaign.layout === "layout3") {
      image = await resizeImageBackground(file, fileExt);
    }
    console.log(image);
    setFileToUpload(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, { artworkPath: image.name });
    setSelectedCampaign(obj);
  };

  const handleChangeLogo = async (file) => {
    const fileExt = file.type.split("/")[1];
    let image;
    image = await resizeImageLogo(file, fileExt);
    console.log(image);
    setLogoToUpload(image);
    const obj = { ...selectedCampaign };
    Object.assign(obj, { logoPath: image.name });
    setSelectedCampaign(obj);
  };

  const resizeImageLogo = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        300,
        300
      );
    });

  const resizeImagePoster = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1500,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        500,
        750
      );
    });

  const resizeImageBackground = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        1280,
        720
      );
    });

  const handleChangeLayout = (layout) => {
    const obj = { ...selectedCampaign };
    Object.assign(obj, { layout: layout });
    setSelectedCampaign(obj);
  };

  const handleChangeLabelColor = (color) => {
    const obj = { ...selectedCampaign };
    Object.assign(obj, { labelColor: color.hex });
    setSelectedCampaign(obj);
  };

  const handleChangeFieldString = (e, data) => {
    console.log("handleChangeFieldString", data);
    let key = data.id;
    let value = data.value;
    if (
      data.id === "limitedTheaters" ||
      data.id === "disableEmail" ||
      data.id === "marketsOnly" ||
      data.id === "enableComments" ||
      data.id === "preventNearSelection"
    ) {
      value = data.checked;
    }
    if (data.id === "markets") {
      value = data.value.replace(/(\r\n|\n|\r)/gm, "");
    }
    if (data.id === "nearSelectionDays") {
      value = data.value.replace(/[^0-9]/g, "");
    }
    const obj = { ...selectedCampaign };
    Object.assign(obj, { [key]: value });
    setSelectedCampaign(obj);
  };

  const handleChangeEmailBody = (e) => {
    const obj = { ...selectedCampaign };
    Object.assign(obj, { emailText: e.target.value });
    setSelectedCampaign(obj);
  };

  const handleChangeStudio = (e, data) => {
    const obj = { ...selectedCampaign };
    let key = data.id;
    let value = data.value;
    Object.assign(obj, { [key]: value });
    setSelectedCampaign(obj);
    // update title list
    const filterTitles = titleList.filter((x) => x.studioId === data.value);
    setTitleOptions(filterTitles);
  };

  const handleSelectCampaign = (obj) => {
    // convert string to array
    let newObj = { ...obj };
    const titleArray = newObj.titleId.split(",");
    Object.assign(newObj, { titleId: titleArray });
    setSelectedCampaign(newObj);
    const title = titleList.filter((x) => x.studioId === obj.studioId);
    setTitleOptions(title);
  };

  // render -->

  const getTitle = (titleId) => {
    if (titleList.length === 0) return;
    const multiple = titleId.split(",");
    let titles = [];
    for (const m of multiple) {
      const title = titleList.filter((x) => x.titleId === m);
      titles.push(title[0]);
    }
    let namesArray = [];
    for (const t of titles) {
      // console.log(t);
      namesArray.push(t.name);
    }
    return namesArray.toString();
  };

  const CampaignsList = () => {
    let campaigns = campaignsList;
    if (filteredList && filteredList.length > 0) {
      campaigns = filteredList;
    }
    return campaigns.map((item, index) => {
      return (
        <Table.Row
          warning={moment() > moment(item.endDate)}
          active={item.campaignId === selectedCampaign.campaignId}
          key={index}
          onClick={() => handleSelectCampaign(item)}
        >
          {isAdminOrStudio && <Table.Cell>{item.campaignId}</Table.Cell>}
          {isAdminOrStudio && (
            <Table.Cell>{moment(item.createdDate).format("LL")}</Table.Cell>
          )}
          <Table.Cell>{getTitle(item.titleId)}</Table.Cell>
          {isExhibitor && (
            <Table.Cell>
              {item.startDate ? moment(item.startDate).format("LL") : "Not Set"}
            </Table.Cell>
          )}
          {isExhibitor && (
            <Table.Cell>
              {item.endDate ? moment(item.endDate).format("LL") : "Not Set"}
            </Table.Cell>
          )}
          {isExhibitor && (
            <Table.Cell>
              {item.groupPolicy && (
                <Button
                  primary
                  content="View Policy"
                  onClick={() => setViewPolicy(true)}
                />
              )}
            </Table.Cell>
          )}
          {isAdminOrStudio && (
            <Table.Cell>
              <a
                href={
                  window.location.href + "intake/?campaignId=" + item.campaignId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </Table.Cell>
          )}
          {isAdminOrStudio && (
            <Table.Cell>
              <a
                href={
                  window.location.href + "intake/?studioId=" + item.studioId
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header style={{ textAlign: "center" }} content="Campaigns" />
        <Input
          style={{ marginBottom: "20px" }}
          placeholder="Search..."
          icon={
            searching ? (
              <Icon loading name="circle notch" />
            ) : (
              <Icon name="search" />
            )
          }
          iconPosition="left"
          onChange={handleFilter}
        />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Campaigns..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              fixed
              sortable
              selectable
              style={{
                borderTop: "0px",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  {isAdminOrStudio && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "campaignId" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "campaignId" ? sort.order : null}
                      onClick={() => handleSort("campaignId")}
                    >
                      ID
                    </Table.HeaderCell>
                  )}
                  {isAdminOrStudio && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "createdDate" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "createdDate" ? sort.order : null}
                      onClick={() => handleSort("createdDate")}
                    >
                      Created
                    </Table.HeaderCell>
                  )}

                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "titleId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "titleId" ? sort.order : null}
                    onClick={() => handleSort("titleId")}
                  >
                    Title
                  </Table.HeaderCell>

                  {isExhibitor && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "startDate" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "startDate" ? sort.order : null}
                      onClick={() => handleSort("startDate")}
                    >
                      Sales Start Date
                    </Table.HeaderCell>
                  )}
                  {isExhibitor && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "endDate" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "endDate" ? sort.order : null}
                      onClick={() => handleSort("endDate")}
                    >
                      Sales End Date
                    </Table.HeaderCell>
                  )}
                  {isExhibitor && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "groupPolicy" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "groupPolicy" ? sort.order : null}
                      onClick={() => handleSort("groupPolicy")}
                    >
                      Group Sales Policy
                    </Table.HeaderCell>
                  )}
                  {isAdminOrStudio && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                      }}
                    >
                      Intake URL
                    </Table.HeaderCell>
                  )}
                  {isAdminOrStudio && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                      }}
                    >
                      Studio Page
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <CampaignsList />
              </Table.Body>
            </Table>
          )}
          {!initializing && !campaignsList.length && (
            <Segment
              placeholder
              style={{ alignItems: "center", backgroundColor: "#F1F1F1" }}
            >
              <Header content="No Campaigns to Display" />
              <Icon size="small" name="info" circular />
            </Segment>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={editMode ? "Edit Campaign" : "Add Campaign"}
          actions={
            <div>
              {!readOnly && (
                <Button
                  size="large"
                  positive
                  icon="check"
                  loading={loading}
                  disabled={disabled}
                  content="Save"
                  onClick={
                    editMode ? handleUpdateCampaign : handleSaveNewCampaign
                  }
                />
              )}
            </div>
          }
        >
          <AddEditCampaign
            titleOptions={titleOptions}
            studiosList={studiosList}
            errorMessage={errorMessage}
            selectedCampaign={selectedCampaign}
            handleChangePosterArt={handleChangePosterArt}
            fileToUpload={fileToUpload}
            posterPreview={posterPreview}
            editMode={editMode}
            handleChangeLayout={handleChangeLayout}
            handleChangeLabelColor={handleChangeLabelColor}
            handleChangeFieldString={handleChangeFieldString}
            handleChangeTitle={handleChangeTitle}
            handleChangeStudio={handleChangeStudio}
            loginContext={loginContext}
            logoPreview={logoPreview}
            logoToUpload={logoToUpload}
            handleChangeLogo={handleChangeLogo}
            handleChangeEmailBody={handleChangeEmailBody}
          />
        </PageModal>
        {isExhibitor && (
          <PageModal
            open={isViewPolicy}
            setOpen={setViewPolicy}
            clearError={clearError}
            heading="Group Sales Policy"
          >
            <ViewPolicy selectedCampaign={selectedCampaign} />
          </PageModal>
        )}
        {isAdminOrStudio && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {!readOnly && (
              <Button
                icon="edit"
                size="large"
                disabled={Object.keys(selectedCampaign).length === 0}
                style={{ marginTop: "20px" }}
                primary
                content="Edit"
                onClick={handleEditCampaign}
              />
            )}
            {!readOnly && (
              <Button
                icon="plus"
                size="large"
                style={{ marginTop: "20px" }}
                positive
                content="Add"
                onClick={handleAddCampaign}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Campaigns;
