import { Modal } from "semantic-ui-react";

const PrematureCampaign = (props) => {
  const defaultText =
    "Sorry, the Group Sales campaign for this title has not started yet.";
  return (
    <Modal open size="tiny">
      <Modal.Header>Campaign Not Yet Active</Modal.Header>
      <Modal.Content>
        <div
          dangerouslySetInnerHTML={{ __html: props.customText || defaultText }}
        />
      </Modal.Content>
    </Modal>
  );
};
export default PrematureCampaign;
