export const statuses = [
  {
    text: "Booked",
    value: "Booked",
  },
  {
    text: "Cancelled",
    value: "Cancelled",
  },
  {
    text: "Cold",
    value: "Cold",
  },
  {
    text: "Contacted",
    value: "Contacted",
  },
  {
    text: "Duplicate",
    value: "Duplicate",
  },
  {
    text: "Event Approved By Studio",
    value: "Completed",
  },
  {
    text: "Flagged",
    value: "Flagged",
  },
  // {
  //   text: "In Progress",
  //   value: "In Progress",
  // },
  {
    text: "Insufficient Contact Details",
    value: "Insufficient Contact Details",
  },
  {
    text: "New",
    value: "New",
  },
  {
    text: "Pending Studio Approval",
    value: "Pending Studio Approval",
  },
  {
    text: "Quote Approved, Ready For Payment",
    value: "Quote Approved, Ready For Payment",
  },
  {
    text: "Quote Received",
    value: "Quote Received",
  },
  {
    text: "Request For Quote",
    value: "Request For Quote",
  },
  {
    text: "Sent to Exhibitor",
    value: "Approved",
  },
];

export const exhibitorStatuses = [
  {
    text: "Booked",
    value: "Booked",
  },
  {
    text: "Cancelled",
    value: "Cancelled",
  },
  {
    text: "Cold",
    value: "Cold",
  },
  {
    text: "Contacted",
    value: "Contacted",
  },
  {
    text: "Duplicate",
    value: "Duplicate",
  },
  {
    text: "Event Approved By Studio",
    value: "Completed",
  },
  // {
  //   text: "In Progress",
  //   value: "In Progress",
  // },
  {
    text: "Insufficient Contact Details",
    value: "Insufficient Contact Details",
  },
  {
    text: "Lead Approved By Studio",
    value: "Approved",
  },
  {
    text: "Pending Studio Approval",
    value: "Pending Studio Approval",
  },
  {
    text: "Quote Approved, Ready For Payment",
    value: "Quote Approved, Ready For Payment",
  },
  {
    text: "Waiting For Studio Approval",
    value: "New",
  },
];
