import { useState, useContext, useEffect } from "react";
import {
  Icon,
  Button,
  Image,
  Header,
  Form,
  Input,
  Label,
  Divider,
  Message,
} from "semantic-ui-react";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import PageModal from "../PageModal";
import { AuthContext } from "../AuthContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import Resizer from "react-image-file-resizer";
import { SketchPicker } from "react-color";
import Alert from "../Alert/Alert";
import {
  GET_UPLOAD_LINK,
  GET_DOWNLOAD_LINK,
  UPDATE_AGENT,
} from "../graphQueries";
const Settings = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [updateObj, setUpdateObj] = useState({
    fullName: loginContext.fullName,
    email: loginContext.email,
    themeColor: loginContext.themeColor,
    backgroundColor: loginContext.backgroundColor,
    enableNotifications: loginContext.enableNotifications,
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { handleSettings, isSettings, handleCreateLoginContext, setLogo } =
    props;
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

  useEffect(() => {
    if (loginContext.logoPath) {
      getDownloadLink({ variables: { file: loginContext.logoPath } });
    }
  }, [loginContext]);

  const [getDownloadLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLink_Result(result),
    onError: (error) => handleGetDownloadLink_Error(error),
  });

  const handleGetDownloadLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setLogoPreview(result.getDownloadLink.link);
      setLogo(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const [updateAgent] = useMutation(UPDATE_AGENT, {
    onCompleted: (result) => handleUpdateAgent_Result(result),
    onError: (error) => handleUpdateAgent_Error(error),
  });

  const handleUpdateAgent_Result = (result) => {
    console.log(result);
    setLoading(false);
    if (result && result.updateAgent.status === "succeeded") {
      if (result.updateAgent.token) {
        localStorage.setItem("token", result.updateAgent.token);
        handleCreateLoginContext(result.updateAgent.token);
      }
      if (fileToUpload !== null) {
        getDownloadLink({
          variables: { file: `${loginContext.agentId}/${fileToUpload.name}` },
        });
      }
      setSuccessMessage("Settings updated successfully.");
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    }
  };

  const handleUpdateAgent_Error = (error) => {
    console.log(error);
    setLoading(false);
  };

  const [getUploadLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLink_Result(result),
    onError: (error) => handleGetUploadLink_Error(error),
  });

  const handleGetUploadLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log(result.getUploadLink.link);
      handleUploadLogo(result.getUploadLink.link);
    }
  };

  const handleGetUploadLink_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleChangeLogo = async (file) => {
    const fileExt = file.type.split("/")[1];
    const image = await resizeImage(file, fileExt);
    console.log(image);
    setFileToUpload(image);
  };

  const handleUploadLogo = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": fileToUpload.type,
      },
      data: fileToUpload,
    });
    console.log(response);
    if (response && response.status === 200) {
      // upload successful, now update DB with file path.
      console.log(`${loginContext.agentId}/${fileToUpload.name}`);
      updateAgent({
        variables: {
          agentId: loginContext.agentId,
          logoPath: `${loginContext.agentId}/${fileToUpload.name}`,
        },
      });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setErrorMessage("");
    if (fileToUpload !== null) {
      getUploadLink({
        variables: { file: `${loginContext.agentId}/${fileToUpload.name}` },
      });
    }
    let updateObject = {};
    if (updateObj.fullName !== loginContext.fullName) {
      Object.assign(updateObject, { fullName: updateObj.fullName });
    }
    if (updateObj.email !== loginContext.email) {
      Object.assign(updateObject, { email: updateObj.email });
    }
    if (updateObj.enableNotifications !== loginContext.enableNotifications) {
      Object.assign(updateObject, {
        enableNotifications: updateObj.enableNotifications,
      });
    }
    if (updateObj.themeColor !== loginContext.themeColor) {
      Object.assign(updateObject, { themeColor: updateObj.themeColor });
    }
    if (updateObj.backgroundColor !== loginContext.backgroundColor) {
      Object.assign(updateObject, {
        backgroundColor: updateObj.backgroundColor,
      });
    }
    if (Object.keys(updateObject).length === 0) {
      return;
    }
    Object.assign(updateObject, { agentId: loginContext.agentId });
    updateAgent({
      variables: updateObject,
    });
  };

  const resizeImage = (file, ext) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        ext,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        150,
        150
      );
    });

  const handleChange = (e, data) => {
    const obj = { ...updateObj };
    if (data.id === "enableNotifications") {
      data.value = !updateObj.enableNotifications;
    }
    Object.assign(obj, { [data.id]: data.value });
    setUpdateObj(obj);
  };

  const handleChangeThemeColor = (color) => {
    const obj = { ...updateObj };
    Object.assign(obj, { themeColor: color.hex });
    setUpdateObj(obj);
  };

  const handleChangeBackgroundColor = (color) => {
    const obj = { ...updateObj };
    Object.assign(obj, { backgroundColor: color.hex });
    setUpdateObj(obj);
  };

  return (
    <div>
      {successMessage && <Alert successMessage={successMessage} />}
      <PageModal
        open={isSettings}
        setOpen={handleSettings}
        heading="Settings"
        clearError={clearError}
        actions={
          <div>
            <Button
              loading={loading}
              disabled={loading}
              primary
              size="large"
              icon="check"
              content="Update"
              onClick={handleUpdate}
            />
          </div>
        }
      >
        <Form size="large">
          <Form.Input
            id="fullName"
            label="Name"
            value={updateObj.fullName}
            onChange={handleChange}
          />
          <Form.Input
            disabled
            id="email"
            label="Email"
            value={updateObj.email}
          />
          <Form.Checkbox
            toggle
            id="enableNotifications"
            checked={updateObj.enableNotifications}
            label="Enable Notifications"
            onChange={handleChange}
          />
          <Form.Group widths="equal">
            <Form.Field>
              <label>Theme Color</label>
              <Input
                value={updateObj.themeColor || ""}
                label={
                  <Label
                    style={{
                      backgroundColor: updateObj.themeColor,
                      width: "50px",
                    }}
                  />
                }
                labelPosition="right"
              />
              <SketchPicker
                color={updateObj.themeColor || ""}
                onChangeComplete={handleChangeThemeColor}
              />
            </Form.Field>
            <Form.Field>
              <label>Background Color</label>
              <Input
                value={updateObj.backgroundColor || ""}
                label={
                  <Label
                    style={{
                      backgroundColor: updateObj.backgroundColor,
                      width: "50px",
                    }}
                  />
                }
                labelPosition="right"
              />
              <SketchPicker
                color={updateObj.backgroundColor || ""}
                onChangeComplete={handleChangeBackgroundColor}
              />
            </Form.Field>
          </Form.Group>
          <Divider />
          <Form.Field>
            <label>Logo</label>
            <div style={{ display: "flex" }}>
              <Image
                size="small"
                src={
                  fileToUpload
                    ? URL.createObjectURL(fileToUpload)
                    : logoPreview
                    ? logoPreview
                    : "/images/placeholder.png"
                }
              />
              <FileUploader
                handleChange={handleChangeLogo}
                name="file"
                types={fileTypes}
                hoverTitle=" "
                children={
                  <div
                    style={{
                      cursor: "pointer",
                      borderStyle: "dashed",
                      flex: 1,
                      height: "100%",
                      width: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      borderColor: "#888888",
                    }}
                  >
                    <Header>
                      <Icon name="upload" />
                      <Header.Content>
                        Drop a file here
                        <Header.Subheader>Or click to browse</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </div>
                }
              />
            </div>
          </Form.Field>
          {errorMessage && <Message error content={errorMessage} />}
        </Form>
      </PageModal>
    </div>
  );
};
export default Settings;
