import { Form, Header, List, Divider } from "semantic-ui-react";
import moment from "moment";
const QuoteForm = (props) => {
  const { details, handleChangeQuoteDetails } = props;
  console.log(details);
  return (
    <Form size="large">
      <Header as="h3">Event Details</Header>
      <List bulleted>
        <List.Item>
          <b>Event Date / Time: </b>
          {moment(details.date.slice(0, 19)).format("LLL")}
        </List.Item>
        <List.Item>
          <b>Total Group Size: </b>
          {details.groupSize}
        </List.Item>
        <List.Item>
          <b>Seats Requested: </b>
          {details.seats || "Not Provided"}
        </List.Item>
        <List.Item>
          <b>Studio: </b>
          {details.studioName}
        </List.Item>
        <List.Item>
          <b>Title: </b>
          {details.titleName}
        </List.Item>
        <List.Item>
          <b>Theater Name: </b>
          {details.theaterName}
        </List.Item>
        <List.Item>
          <b>Theater Zip: </b>
          {details.theaterZip}
        </List.Item>
      </List>
      <Divider />
      <Form.TextArea
        value={details.quote}
        onChange={handleChangeQuoteDetails}
        label="Quote"
        placeholder="Enter your Quote amount and any other details you would like the studio to know about this event."
      />
    </Form>
  );
};
export default QuoteForm;
