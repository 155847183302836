import { useState } from "react";
import { Form, Message, Header, Image, Icon } from "semantic-ui-react";
import { FileUploader } from "react-drag-drop-files";

const AddEditStudio = (props) => {
  const {
    newStudioName,
    handleChangeStudioName,
    errorMessage,
    fileToUpload,
    logoPreview,
    handleChangeLogo,
  } = props;
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  return (
    <Form size="large">
      <Form.Input
        label="Studio Name"
        placeholder="Studio Name"
        value={newStudioName}
        onChange={handleChangeStudioName}
      />
      <Form.Field>
        <label>Logo</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Image
            size="small"
            src={
              fileToUpload !== null
                ? URL.createObjectURL(fileToUpload)
                : logoPreview
                ? logoPreview
                : "/images/placeholder.png"
            }
          />
          <FileUploader
            handleChange={handleChangeLogo}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  borderStyle: "dashed",
                  flex: 1,
                  height: "100%",
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Header>
                  <Icon name="upload" />
                  <Header.Content>
                    Drop a file here
                    <Header.Subheader>Or click to browse</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            }
          />
        </div>
      </Form.Field>
      {errorMessage && (
        <Form.Field>
          <Message visible error header="Error" content={errorMessage} />
        </Form.Field>
      )}
    </Form>
  );
};
export default AddEditStudio;
