import { useState } from "react";
import { Form, Message, Header, Segment } from "semantic-ui-react";

const AddEditTheater = (props) => {
  const {
    selectedTheater,
    handleChangeFieldString,
    circuitsList,
    campaignsList,
    handleChangeCampaigns,
  } = props;
  return (
    <Form size="large">
      <Form.Select
        required
        id="circuitId"
        label="Circuit"
        placeholder="Select Circuit"
        search
        options={circuitsList}
        value={selectedTheater.circuitId || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="name"
        label="Theater Name"
        placeholder="Theater Name"
        value={selectedTheater.name || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="rtkTheaterId"
        label="Rentrak ID"
        placeholder="Rentrak ID"
        value={selectedTheater.rtkTheaterId || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="alternateId"
        label="Alternate ID"
        placeholder="Alternate ID"
        value={selectedTheater.alternateId || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="city"
        label="City"
        placeholder="City"
        value={selectedTheater.city || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="state"
        label="State"
        placeholder="State"
        value={selectedTheater.state || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="zipCode"
        label="Zip Code"
        placeholder="Zip Code"
        value={selectedTheater.zipCode || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Select
        label="Campaigns"
        placeholder="Select Campaigns"
        multiple
        id="campaigns"
        options={campaignsList}
        value={selectedTheater.campaigns}
        onChange={handleChangeCampaigns}
      />
    </Form>
  );
};
export default AddEditTheater;
