import axios from "axios";
axios.defaults.timeout = 30000;

export const location = () => {
  return axios
    .get(
      "https://pro.ip-api.com/json/?fields=country,countryCode&key=UOFNAGQu7N4KnB9"
    )
    .then((response) => response.data)
    .catch((error) => error);
};
