import { Modal } from "semantic-ui-react";

const RegionLocked = (props) => {
  return (
    <Modal open size="tiny">
      <Modal.Header>Not Available</Modal.Header>
      <Modal.Content>
        <div>This content is not available.</div>
      </Modal.Content>
    </Modal>
  );
};
export default RegionLocked;
