import { Header } from "semantic-ui-react";
import { useEffect } from "react";
import CustomEditor from "../Campaigns/Editor/Editor";
import moment from "moment";

const Ping = (props) => {
  const {
    selectedCustomer,
    pingMessage,
    handleSetPingMessage,
    setPingMessage,
    calculateBusinessDays,
  } = props;

  useEffect(() => {
    setPingMessage(
      `The following lead is overdue for an update. Please contact the lead if they have not yet been contacted. If they have been contacted, please update their status in The Tower.<br /><ul><li><b>Name: </b>${
        selectedCustomer.firstName
      } ${selectedCustomer.lastName}</li><li><b>Group: </b>${
        selectedCustomer.company
      }</li><li><b>Event Date: </b> ${moment(selectedCustomer.date).format(
        "LLL"
      )}</li></ul>`
    );
  }, [selectedCustomer]);
  const daysAgo = calculateBusinessDays(selectedCustomer.approvalTime);
  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <p>
          The selected lead was sent to the Exhibitor <b>{daysAgo}</b> business
          days ago. Use the form below to send an email to the Exhibitor,
          reminding them to provide an updated status and to contact the lead. A
          sample message is provided. Feel free to revise this message as
          needed.
        </p>
        {selectedCustomer.pingCount > 0 && (
          <p>
            <b>Note: </b>this Exhibitor has been pinged{" "}
            <b>{selectedCustomer.pingCount}</b> times about this lead.
          </p>
        )}
      </div>
      <div>
        <CustomEditor
          emailText={pingMessage}
          handleChangeEmailBody={handleSetPingMessage}
        />
      </div>
    </div>
  );
};
export default Ping;
