import PageModal from "../PageModal";
import { Header, Label } from "semantic-ui-react";
const Help = (props) => {
  const clearError = () => {};
  return (
    <PageModal
      open={props.isHelp}
      setOpen={props.handleHelp}
      heading="Help"
      clearError={clearError}
    >
      <Header
        content="Status Legend"
        subheader="The status field on each lead/event is used to communicate back and forth between the Studio and the Exhibitor on the current state of the event."
      />
      <div style={{ marginBottom: "10px" }}>
        <Label color="purple" content="Booked" />
        <span>
          {" "}
          Indicates a completed sale, should have Box Office Gross value added.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="red" content="Cancelled" />
        <span> Indicates the event has cancelled.</span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="black" content="Cold" />
        <span>
          {" "}
          Indicates the lead has not responded to repeated attempts to contact.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="blue" content="Contacted" />
        <span>
          {" "}
          Indicates the lead has been successfully contacted and the process
          toward booking is moving forward.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="orange" content="Duplicate" />
        <span>
          {" "}
          Indicates the lead or event is a duplicate of another contact record.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="yellow" content="Event Approved by Studio" />
        <span>
          {" "}
          Indicates the event has been booked and the studio has approved the
          event.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="orange" content="Insufficient Contact Details" />
        <span>
          {" "}
          Indicates the lead or event is missing critical details needed for
          moving forward with booking.
        </span>
      </div>
      {!props.isExhibitor && (
        <div style={{ marginBottom: "10px" }}>
          <Label color="green" content="New" />
          <span>
            {" "}
            A new lead that as come into the Tower and is waiting for review.
            New leads are only visible to the Studio and not yet visible to the
            Exhibitor.
          </span>
        </div>
      )}
      <div style={{ marginBottom: "10px" }}>
        <Label color="blue" content="Pending Studio Approval" />
        <span>
          {" "}
          Indicates the Studio needs to approve this event before it can move
          forward.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Label color="teal" content="Quote Approved, Ready for Payment" />
        <span>
          {" "}
          Indicates the quote provided by the Exhibitor has been approved by the
          Studio, and payment can now be collected.
        </span>
      </div>
      {!props.isExhibitor && (
        <div style={{ marginBottom: "10px" }}>
          <Label color="blue" content="Request for Quote" />
          <span>
            {" "}
            Indicates the Studio is requesting a quote on the event from the
            Exhibitor. <b>NOTE:</b> Changing a lead to this status will trigger
            a quote request that is sent to the Exhibitor.
          </span>
        </div>
      )}
      {!props.isExhibitor && (
        <div style={{ marginBottom: "10px" }}>
          <Label color="grey" content="Sent to Exhibitor" />
          <span>
            {" "}
            Indicates the Studio has reviewed the new lead and sent it to the
            Exhibitor for handling. <b>NOTE:</b> Changing a lead from NEW to
            this status will make the lead visible to the Exhibitor.
          </span>
        </div>
      )}
      {props.isExhibitor && (
        <div style={{ marginBottom: "10px" }}>
          <Label color="green" content="Lead Approved by Studio" />
          <span>
            {" "}
            Indicates the Studio has reviewed the new lead and sent it to the
            Exhibitor for handling.
          </span>
        </div>
      )}
    </PageModal>
  );
};
export default Help;
