import { Message } from "semantic-ui-react";
const Alert = (props) => {
  return (
    <div
      className="animate__animated animate__fadeInUp"
      style={{
        position: "absolute",
        top: "calc(50% - 40px)",
        left: "calc(50% - 125px)",
        width: "250px",
        height: "auto",
        backgroundColor: "#FFFFFF",
        zIndex: "10000",
        borderRadius: "10px",
        boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.75)",
      }}
    >
      <Message
        style={{ height: "100%", overflowY: "auto" }}
        fluid
        warning={props.warning}
        positive={!props.failed || !props.warning}
        error={props.failed}
        header={props.failed ? "Failed" : props.warning ? "Warning" : "Success"}
        content={props.successMessage || props.errorMessage}
      />
    </div>
  );
};
export default Alert;
