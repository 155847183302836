import { useEffect, useState } from "react";
import { Header, Form, Message } from "semantic-ui-react";

const SetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [resetBackground, setResetBackground] = useState("");

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    handleValidatePassword();
  }, [password, confirmPassword]);

  useEffect(() => {
    setTimeout(() => {
      setResetBackground("rgba(0,0,0,0.7)");
    }, 100);
  }, []);

  const handleValidatePassword = () => {
    if (!password) {
      return;
    }
    if (password.length < 8) {
      setStatusMessage("Password must be at least 8 characters.");
      return;
    }
    if (!new RegExp(/^(?=.*[a-z])/).test(password)) {
      setStatusMessage("Password must include 1 lowercase character.");
      return;
    }
    if (!new RegExp(/^(?=.*[A-Z])/).test(password)) {
      setStatusMessage("Password must include 1 uppercase character.");
      return;
    }
    if (!new RegExp(/^(?=.*\d)/).test(password)) {
      setStatusMessage("Password must include 1 numeric character.");
      return;
    }
    if (password !== confirmPassword) {
      setStatusMessage("Passwords do not match.");
      return;
    }
    if (password === confirmPassword) {
      setSuccessMessage("Passwords Match!");
      setStatusMessage("");
      return;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        backgroundColor: resetBackground,
        transition: "background-color 1000ms linear",
      }}
    >
      <Header
        inverted
        as="h1"
        style={{ position: "absolute", top: "15%", textAlign: "center" }}
      >
        Welcome {props.userName}!
        <Header.Subheader>
          Please set your password before logging in.
        </Header.Subheader>
      </Header>

      <div
        style={{
          maxWidth: "500px",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
        }}
      >
        <Header style={{ textAlign: "center" }} content="Set Password" />
        <Form size="large" style={{ textAlign: "left" }}>
          <Form.Input
            label="Password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangePassword}
          />
          <Form.Input
            label="Confirm Password"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleChangeConfirmPassword}
          />
          <Form.Button
            loading={props.loading}
            disabled={props.loading}
            size="large"
            primary
            fluid
            content="Submit"
            onClick={() => props.handleActivateAgent(password)}
          />
          {statusMessage && <Message visible warning content={statusMessage} />}
          {successMessage && !props.errorMessage && (
            <Message visible positive content={successMessage} />
          )}
          {props.errorMessage && (
            <Message visible error content={props.errorMessage} />
          )}
        </Form>
      </div>
    </div>
  );
};
export default SetPassword;
