export const industries = [
  {
    text: "Agriculture / Environmental",
    value: "Agriculture / Environmental",
  },
  {
    text: "Animals",
    value: "Animals",
  },
  {
    text: "Auto / Aviation / Aerospace",
    value: "Auto / Aviation / Aerospace",
  },
  {
    text: "Children",
    value: "Children",
  },
  {
    text: "Commerce",
    value: "Commerce",
  },
  {
    text: "Consulting",
    value: "Consulting",
  },
  {
    text: "Creative Arts",
    value: "Creative Arts",
  },
  {
    text: "Construction",
    value: "Construction",
  },
  {
    text: "Education",
    value: "Education",
  },
  {
    text: "Electrical / Mechanical",
    value: "Electrical / Mechanical",
  },
  {
    text: "Energy / Utility",
    value: "Energy / Utility",
  },
  {
    text: "Engineering",
    value: "Engineering",
  },
  {
    text: "Event Planning",
    value: "Event Planning",
  },
  {
    text: "Film / Motion Picture",
    value: "Film / Motion Picture",
  },
  {
    text: "Finance",
    value: "Finance",
  },
  {
    text: "Food & Beverage",
    value: "Food & Beverage",
  },
  {
    text: "Fraternity",
    value: "Fraternity",
  },
  {
    text: "Gaming / Interactive",
    value: "Gaming / Interactive",
  },
  {
    text: "Government / Legal / Safety",
    value: "Government / Legal / Safety",
  },
  {
    text: "Health / Wellness",
    value: "Health / Wellness",
  },
  {
    text: "Hospitality / Entertainment",
    value: "Hospitality / Entertainment",
  },
  {
    text: "Individual - Not a Business",
    value: "Individual - Not a Business",
  },
  {
    text: "Insurance",
    value: "Insurance",
  },
  {
    text: "Media",
    value: "Media",
  },
  {
    text: "Not For Profit",
    value: "Not For Profit",
  },
  {
    text: "Real Estate",
    value: "Real Estate",
  },
  {
    text: "Religious",
    value: "Religious",
  },
  {
    text: "Sorority",
    value: "Sorority",
  },
  {
    text: "Sports / Fitness",
    value: "Sports / Fitness",
  },
  {
    text: "Technology",
    value: "Technology",
  },
  {
    text: "Telecommunications",
    value: "Telecommunications",
  },
  {
    text: "Transportation / Shipping",
    value: "Transportation / Shipping",
  },
];
