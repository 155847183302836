import { useContext } from "react";
import { Menu, Dropdown, Label, Icon, Button } from "semantic-ui-react";
import { AuthContext } from "../AuthContext";

const MenuItems = (props) => {
  const { loginContext } = useContext(AuthContext);
  const studio = loginContext.role === "Studio";
  const exhibitor = loginContext.role === "Exhibitor";
  const admin = loginContext.role === "Admin";
  const isMobile = props.windowWidth < 730;
  const isAgency = loginContext.role === "Agency";
  const isAdminOrStudio =
    loginContext.role.includes("Admin") || loginContext.role.includes("Studio");

  const getInitials = (string) => {
    if (!string) return;
    var desired = string.replace(/[^\w\s]/gi, "");
    var names = desired.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <Menu
      size="huge"
      inverted
      style={{
        backgroundColor: loginContext.themeColor || "#0E6EB8",
        position: "fixed",
        width: "100%",
        height: "50px",
        borderRadius: "0px",
        top: "0px",
        zIndex: 2,
      }}
    >
      {isMobile && (
        <Dropdown icon={null} trigger={<Menu.Item>Menu</Menu.Item>}>
          <Dropdown.Menu>
            <Dropdown.Item
              content="Home"
              onClick={() => props.handleChangeMenu("home")}
            />
            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Users"
                onClick={() => props.handleChangeMenu("users")}
              />
            )}
            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Mail"
                onClick={() => props.handleChangeMenu("mail")}
              />
            )}

            <Dropdown.Item
              content="Leads"
              onClick={() => props.handleChangeMenu("leads")}
            />

            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Theaters"
                onClick={() => props.handleChangeMenu("theaters")}
              />
            )}
            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Studios"
                onClick={() => props.handleChangeMenu("studios")}
              />
            )}
            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Circuits"
                onClick={() => props.handleChangeMenu("circuits")}
              />
            )}
            {isAdminOrStudio && (
              <Dropdown.Item
                content="Titles"
                onClick={() => props.handleChangeMenu("titles")}
              />
            )}

            <Dropdown.Item
              content="Campaigns"
              onClick={() => props.handleChangeMenu("campaigns")}
            />

            {!isAdminOrStudio && !isAgency && (
              <Dropdown.Item
                content="Quote Requests"
                onClick={() => props.handleChangeMenu("quotes")}
              />
            )}
            {loginContext.role.includes("Admin") && (
              <Dropdown.Item
                content="Activity"
                onClick={() => props.handleChangeMenu("activity")}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!isMobile && (
        <Menu.Item
          name="home"
          active={props.activeMenuItem === "home"}
          onClick={() => props.handleChangeMenu("home")}
        />
      )}
      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="users"
          active={props.activeMenuItem === "users"}
          onClick={() => props.handleChangeMenu("users")}
        />
      )} */}
      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="mail"
          active={props.activeMenuItem === "mail"}
          onClick={() => props.handleChangeMenu("mail")}
        />
      )} */}
      {!isMobile && (
        <Menu.Item
          active={props.activeMenuItem === "leads"}
          onClick={() => props.handleChangeMenu("leads")}
        >
          Leads{" "}
          {props.newCustomersSize ? (
            <Label circular color="red">
              {props.newCustomersSize.toLocaleString()}
            </Label>
          ) : null}
        </Menu.Item>
      )}

      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="studios"
          active={props.activeMenuItem === "studios"}
          onClick={() => props.handleChangeMenu("studios")}
        />
      )} */}
      {isAdminOrStudio && !isMobile && (
        <Menu.Item
          name="titles"
          active={props.activeMenuItem === "titles"}
          onClick={() => props.handleChangeMenu("titles")}
        />
      )}
      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="theaters"
          active={props.activeMenuItem === "theaters"}
          onClick={() => props.handleChangeMenu("theaters")}
        />
      )} */}
      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="circuits"
          active={props.activeMenuItem === "circuits"}
          onClick={() => props.handleChangeMenu("circuits")}
        />
      )} */}
      {!isMobile && (
        <Menu.Item
          name="campaigns"
          active={props.activeMenuItem === "campaigns"}
          onClick={() => props.handleChangeMenu("campaigns")}
        />
      )}
      {!isAdminOrStudio && !isAgency && !isMobile && (
        <Menu.Item
          active={props.activeMenuItem === "quotes"}
          onClick={() => props.handleChangeMenu("quotes")}
        >
          Quote Requests{" "}
          {props.newQuoteSize && (
            <Label circular color="red">
              {props.newQuoteSize.toLocaleString()}
            </Label>
          )}
        </Menu.Item>
      )}
      {/* {loginContext.role.includes("Admin") && !isMobile && (
        <Menu.Item
          name="activity"
          active={props.activeMenuItem === "activity"}
          onClick={() => props.handleChangeMenu("activity")}
        />
      )} */}
      {loginContext.role.includes("Admin") && !isMobile && (
        <Dropdown
          icon={null}
          trigger={
            <Menu.Item>
              Admin
              <Icon style={{ marginLeft: "5px" }} name="caret down" />
            </Menu.Item>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item
              active={props.activeMenuItem === "mail"}
              onClick={() => props.handleChangeMenu("mail")}
            >
              Mail
            </Dropdown.Item>
            <Dropdown.Item
              active={props.activeMenuItem === "activity"}
              onClick={() => props.handleChangeMenu("activity")}
            >
              Activity
            </Dropdown.Item>
            <Dropdown.Item
              active={props.activeMenuItem === "users"}
              onClick={() => props.handleChangeMenu("users")}
            >
              Users
            </Dropdown.Item>
            <Dropdown.Item
              active={props.activeMenuItem === "circuits"}
              onClick={() => props.handleChangeMenu("circuits")}
            >
              Circuits
            </Dropdown.Item>
            <Dropdown.Item
              active={props.activeMenuItem === "theaters"}
              onClick={() => props.handleChangeMenu("theaters")}
            >
              Theaters
            </Dropdown.Item>
            <Dropdown.Item
              active={props.activeMenuItem === "studios"}
              onClick={() => props.handleChangeMenu("studios")}
            >
              Studios
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}

      {/* <Dropdown icon={null} trigger={<Menu.Item>Intake</Menu.Item>}>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => props.handleIntake("64c09b9d97acc14adf5da303")}
          >
            Intake (Universal) <Icon name="external" color="grey" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.handleIntake("64c09bb997acc14adf5da309")}
          >
            Intake (Disney) <Icon name="external" color="grey" />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => props.handleIntake("64c09bb197acc14adf5da306")}
          >
            Intake (Paramount) <Icon name="external" color="grey" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}

      <Menu.Menu
        position="right"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          icon="help"
          style={{ height: "40px" }}
          onClick={props.handleHelp}
        />

        <Dropdown
          style={{ padding: "5px" }}
          icon={null}
          trigger={
            <Button
              icon
              style={{ height: "40px" }}
              content={getInitials(loginContext.fullName)}
            />
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item disabled>
              {loginContext.fullName} {"("}
              {loginContext.email}
              {")"}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.handleSettings()}>
              Settings
            </Dropdown.Item>
            <Dropdown.Item onClick={() => props.handleChangeMenu("logout")}>
              Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};
export default MenuItems;
