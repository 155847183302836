import { useState, useEffect, useContext } from "react";
import {
  Header,
  Table,
  Button,
  Label,
  Segment,
  Icon,
  Input,
  Checkbox,
  Dropdown,
} from "semantic-ui-react";
import {
  AGENTS,
  CREATE_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
  STUDIOS,
  CIRCUITS,
  TITLES,
  GET_UPLOAD_LINK,
  GET_DOWNLOAD_LINK,
  SEND_ACTIVATION_MANUALLY,
  CREATE_API_KEY,
  REVOKE_API_KEY,
} from "../graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AuthContext } from "../AuthContext";
import PageModal from "../PageModal";
import AddEditAgent from "./AddEditAgent";
import Alert from "../Alert/Alert";
import axios from "axios";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Users = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [agentsList, setAgentsList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [sort, setSort] = useState({ column: "name", order: "ascending" });
  const [errorMessage, setErrorMessage] = useState("");
  const [mode, setMode] = useState("");
  const [studioList, setStudioList] = useState([]);
  const [circuitList, setCircuitList] = useState([]);
  const [initializing, setInit] = useState(true);
  const [filteredList, setFiltered] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [logoPreview, setLogoPreview] = useState("");
  const [pending, setPending] = useState(false);
  const [titlesList, setTitlesList] = useState([]);
  const [titleOptions, setTitles] = useState([]);

  // component did mount/update effects

  useEffect(() => {
    console.log(selectedAgent);
  }, [selectedAgent]);

  useEffect(() => {
    console.log(filteredList);
  }, [filteredList]);

  useEffect(() => {
    // agents();
    // studios();
    circuits();
  }, []);

  // agents query
  const [agents] = useLazyQuery(AGENTS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleAgents_Result(result),
    onError: (error) => handleAgents_Error(error),
  });
  const handleAgents_Result = (result) => {
    console.log(result.agents);
    if (result.agents) {
      // const agentsToSort = [...result.agents];
      // create company field for easier sorting
      let agentsToSort = result.agents.map((item) =>
        Object.assign({}, item, {
          company: getCompanyName(item),
        })
      );

      for (const a of agentsToSort) {
        // split circuit string into array
        if (a.circuitId) {
          const circuits = a.circuitId.split(",");
          Object.assign(a, { circuitId: circuits });
        }
        // split title string into array
        if (a.titleId) {
          const titles = a.titleId.split(",");
          Object.assign(a, { titleId: titles });
        }
      }

      const sorted = agentsToSort.sort((a, b) =>
        (a.fullName || "").localeCompare(b.fullName || "")
      );
      setAgentsList(sorted);
      setInit(false);
      titles();
    }
  };
  const handleAgents_Error = (error) => {
    console.log(error);
  };

  const getCompanyName = (item) => {
    if (item.studioName) return item.studioName;
    if (item.circuitName) return item.circuitName;
    if (item.role === "Agency") return "Agency";
    if (item.role === "Admin") return "Paper Airplane";
    return "";
  };

  // studios query
  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });
  const handleStudios_Result = (result) => {
    if (result.studios && result.studios.length) {
      let studioList = [];
      for (const studio of result.studios) {
        const obj = {
          text: studio.name,
          value: studio.studioId,
        };
        studioList.push(obj);
      }
      console.log(studioList);
      setStudioList(studioList);
      agents();
    }
  };
  const handleStudios_Error = (error) => {
    console.log(error);
  };

  // circuits query
  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });
  const handleCircuits_Result = (result) => {
    if (result.circuits && result.circuits.length) {
      console.log(result.circuits);
      let circuitsList = [];
      for (const circuit of result.circuits) {
        const obj = {
          text: circuit.circuit,
          value: circuit.circuitId,
        };
        circuitsList.push(obj);
      }
      setCircuitList(circuitsList);
      studios();
    }
  };
  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  // titles query
  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });

  const handleTitles_Result = (result) => {
    console.log(result);
    if (result.titles) {
      setTitlesList(result.titles);
    }
  };

  const handleTitles_Error = (error) => {
    console.log(error);
  };

  // createAgent mutation
  const [createAgent] = useMutation(CREATE_AGENT, {
    onCompleted: (result) => handleCreateAgent_Result(result),
    onError: (error) => handleCreateAgent_Error(error),
  });
  const handleCreateAgent_Result = (result) => {
    console.log(result);
    if (result.createAgent && result.createAgent.status === "succeeded") {
      // handle uploading media now that we have agentId
      if (fileToUpload !== null) {
        getUploadLink({
          variables: {
            file: `${result.createAgent.agentId}/${fileToUpload.name}`,
          },
        });
        updateAgent({
          variables: {
            agentId: result.createAgent.agentId,
            logoPath: `${result.createAgent.agentId}/${fileToUpload.name}`,
          },
        });
      } else {
        setSuccess(true);
        setSuccessMessage("User added successfully!");
        resetStateTimer();
        agents();
      }
    }
  };
  const handleCreateAgent_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  // updateAgent mutation
  const [updateAgent] = useMutation(UPDATE_AGENT, {
    onCompleted: (result) => handleUpdateAgent_Result(result),
    onError: (error) => handleUpdateAgent_Error(error),
  });
  const handleUpdateAgent_Result = (result) => {
    console.log(result);
    if (result.updateAgent && result.updateAgent.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("User updated successfully!");
      if (result.updateAgent.token) {
        localStorage.setItem("token", result.updateAgent.token);
        props.handleCreateLoginContext(result.updateAgent.token);
      }
      agents();
      resetStateTimer();
    }
    if (result.updateAgent && result.updateAgent.status === "failed") {
      setErrorMessage(result.update.message);
      resetStateTimer();
    }
  };
  const handleUpdateAgent_Error = (error) => {
    console.log(error);
    resetStateTimer();
    setErrorMessage(error.message);
  };

  // deleteAgent mutation
  const [deleteAgent] = useMutation(DELETE_AGENT, {
    onCompleted: (result) => handleDeleteAgent_Result(result),
    onError: (error) => handleDeleteAgent_Error(error),
  });
  const handleDeleteAgent_Result = (result) => {
    console.log(result);
    if (result.deleteAgent && result.deleteAgent.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("User deleted successfully!");
      agents();
      resetStateTimer();
    }
    if (result.deleteAgent && result.deleteAgent.status === "failed") {
      setErrorMessage(result.deleteAgent.message);
      resetStateTimer();
    }
  };
  const handleDeleteAgent_Error = (error) => {
    console.log(error);
    resetStateTimer();
    setErrorMessage(error.message);
  };

  // create api key mutation
  const handleCreateKey = () => {
    if (selectedAgent.apikey) return;
    createAPIKey({ variables: { agentId: selectedAgent.agentId } });
  };

  const [createAPIKey] = useMutation(CREATE_API_KEY, {
    onCompleted: (result) => handleCreateKey_Result(result),
    onError: (error) => handleCreateKey_Error(error),
  });

  const handleCreateKey_Result = (result) => {
    console.log(result);
    if (result.createAPIKey.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("API Key generated successfully!");
      agents();
      resetStateTimer();
    }
  };

  const handleCreateKey_Error = (error) => {
    console.log(error);
  };

  // revoke api key mutation
  const handleRevokeKey = () => {
    if (!selectedAgent.apikey) return;
    revokeAPIKey({ variables: { agentId: selectedAgent.agentId } });
  };

  const [revokeAPIKey] = useMutation(REVOKE_API_KEY, {
    onCompleted: (result) => handleRevokeKey_Result(result),
    onError: (error) => handleRevokeKey_Error(error),
  });

  const handleRevokeKey_Result = (result) => {
    console.log(result);
    if (result.revokeAPIKey.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("API Key revoked successfully!");
      agents();
      resetStateTimer();
    }
  };

  const handleRevokeKey_Error = (error) => {
    console.log(error);
  };

  // send activation manually
  const [sendActivationManually] = useMutation(SEND_ACTIVATION_MANUALLY, {
    onCompleted: (result) => handleSendManually_Result(result),
    onError: (error) => handleSendManually_Error(error),
  });

  const handleSendManually_Result = (result) => {
    console.log(result);
    if (
      result.sendActivationManually &&
      result.sendActivationManually.status === "succeeded"
    ) {
      setSuccess(true);
      setSuccessMessage("Sent email successfully!");
      agents();
      resetStateTimer();
    }
  };

  const handleSendManually_Error = (error) => {
    console.log(error);
  };

  const resetStateTimer = () => {
    setModalOpen(false);
    setLogoPreview("");
    setSelectedAgent({});
    setDisabled(false);
    setLoading(false);
    setFileToUpload(null);
    setTimeout(() => {
      setSuccess(false);
      setSuccessMessage("");
      setErrorMessage("");
    }, 3000);
  };

  // handlers

  const handleCreateAgent = () => {
    console.log("handleCreateAgent", selectedAgent);
    if (selectedAgent.fullName && selectedAgent.email && selectedAgent.role) {
      setLoading(true);
      setDisabled(true);
      if (selectedAgent.circuitId && selectedAgent.circuitId.length) {
        const string = selectedAgent.circuitId.toString();
        Object.assign(selectedAgent, { circuitId: string });
      }
      if (selectedAgent.titleId && selectedAgent.titleId.length) {
        const string = selectedAgent.titleId.toString();
        Object.assign(selectedAgent, { titleId: string });
      }
      createAgent({
        variables: selectedAgent,
      });
    } else {
      alert("Please fill out all required fields.");
      setLoading(false);
      setDisabled(false);
    }
  };

  const [getUploadLink] = useLazyQuery(GET_UPLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetUploadLink_Result(result),
    onError: (error) => handleGetUploadLink_Error(error),
  });

  const handleGetUploadLink_Result = (result) => {
    if (result.getUploadLink.link) {
      console.log(result.getUploadLink.link);
      handleUploadLogo(result.getUploadLink.link);
    }
  };
  const handleGetUploadLink_Error = (error) => {
    console.log(error);
  };
  const handleUploadLogo = async (link) => {
    const response = await axios({
      method: "PUT",
      url: link,
      headers: {
        "Content-Type": fileToUpload.type,
      },
      data: fileToUpload,
    });
    console.log(response);
  };

  const handleUpdateAgent = async () => {
    console.log("handleUpdateAgent");
    let uploadFile = false;
    if (fileToUpload !== null) {
      Object.assign(selectedAgent, {
        logoPath: `${selectedAgent.agentId}/${fileToUpload.name}`,
      });
      uploadFile = true;
    }
    setLoading(true);
    setDisabled(true);
    // handle upload before updating DB
    if (uploadFile) {
      await getUploadLink({
        variables: { file: `${selectedAgent.agentId}/${fileToUpload.name}` },
      });
    }
    if (selectedAgent.circuitId && selectedAgent.circuitId.length) {
      const string = selectedAgent.circuitId.toString();
      Object.assign(selectedAgent, { circuitId: string });
    } else {
      Object.assign(selectedAgent, { circuitId: "" });
    }
    if (selectedAgent.titleId && selectedAgent.titleId.length) {
      const string = selectedAgent.titleId.toString();
      Object.assign(selectedAgent, { titleId: string });
    }
    updateAgent({
      variables: selectedAgent,
    });
  };

  const handleSelectAgent = (agent) => {
    console.log(agent);
    setSelectedAgent(agent);
    if (agent.studioId) {
      handleBuildTitleList(agent.studioId);
    }
    if (agent.role === "Agency") {
      handleBuildTitleList("agency");
    }
  };

  const handleBuildTitleList = (studioId) => {
    // build titles list
    let titles = titlesList.map((item) =>
      Object.assign({}, item, {
        text: item.name,
        value: item.titleId,
      })
    );
    if (studioId === "agency") {
      setTitles(titles);
      return;
    }
    titles = titlesList
      .filter((x) => x.studioId === studioId)
      .map((item) =>
        Object.assign({}, item, {
          text: item.name,
          value: item.titleId,
        })
      );
    console.log("titles", titles);
    setTitles(titles);
  };

  const handleAddAgent = () => {
    setMode("Add");
    setSelectedAgent({ enableNotifications: true });
    setFileToUpload(null);
    setLogoPreview("");
    setModalOpen(true);
  };

  const handleEditAgent = () => {
    setMode("Update");
    setLogoPreview("");
    setFileToUpload(null);
    setModalOpen(true);
    if (selectedAgent.logoPath) {
      //need to download existing logo for preview
      getDownloadLink({ variables: { file: selectedAgent.logoPath } });
    }
  };

  const [getDownloadLink] = useLazyQuery(GET_DOWNLOAD_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleGetDownloadLink_Result(result),
    onError: (error) => handleGetDownloadLink_Error(error),
  });

  const handleGetDownloadLink_Result = (result) => {
    if (result && result.getDownloadLink.link) {
      setLogoPreview(result.getDownloadLink.link);
    }
  };

  const handleGetDownloadLink_Error = (error) => {
    console.log(error);
  };

  const handleDeactivateAgent = () => {
    setLoading(true);
    updateAgent({
      variables: {
        agentId: selectedAgent.agentId,
        active: false,
        deactivated: true,
      },
    });
  };

  const handleDeleteAgent = () => {
    setLoading(true);
    deleteAgent({
      variables: {
        agentId: selectedAgent.agentId,
        deleted: true,
      },
    });
  };

  const handleChangeFieldString = (e, data) => {
    let key = data.id;
    let value = data.value;
    if (key === "readOnly") {
      value = !selectedAgent.readOnly;
    }
    if (key === "disableExport") {
      value = !selectedAgent.disableExport;
    }
    if (key === "enableNotifications") {
      value = !selectedAgent.enableNotifications;
    }
    const agent = { ...selectedAgent };
    Object.assign(agent, { [key]: value });
    setSelectedAgent(agent);
    if (agent.studioId) {
      handleBuildTitleList(agent.studioId);
    }
    if (agent.role === "Agency") {
      handleBuildTitleList("agency");
    }
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...agentsList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }
    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.fullName || "").localeCompare(b.fullName || "")
      );
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.fullName || "").localeCompare(a.fullName || "")
      );
    }
    if (column === "email" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.email || "").localeCompare(b.email || ""));
    }
    if (column === "email" && newOrder === "descending") {
      sortList.sort((a, b) => (b.email || "").localeCompare(a.email || ""));
    }
    if (column === "role" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.role || "").localeCompare(b.role || ""));
    }
    if (column === "role" && newOrder === "descending") {
      sortList.sort((a, b) => (b.role || "").localeCompare(a.role || ""));
    }
    if (column === "phone" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.phone || "").localeCompare(b.phone || ""));
    }
    if (column === "phone" && newOrder === "descending") {
      sortList.sort((a, b) => (b.phone || "").localeCompare(a.phone || ""));
    }
    if (column === "company" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.company || "").localeCompare(b.company || ""));
    }
    if (column === "company" && newOrder === "descending") {
      sortList.sort((a, b) => (b.company || "").localeCompare(a.company || ""));
    }
    if (column === "address" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.address || "").localeCompare(b.address || ""));
    }
    if (column === "address" && newOrder === "descending") {
      sortList.sort((a, b) => (b.address || "").localeCompare(a.address || ""));
    }
    if (column === "city" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.city || "").localeCompare(b.city || ""));
    }
    if (column === "city" && newOrder === "descending") {
      sortList.sort((a, b) => (b.city || "").localeCompare(a.city || ""));
    }
    if (column === "state" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.state || "").localeCompare(b.state || ""));
    }
    if (column === "state" && newOrder === "descending") {
      sortList.sort((a, b) => (b.state || "").localeCompare(a.state || ""));
    }
    if (column === "zipCode" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.zipCode || "").localeCompare(b.zipCode || ""));
    }
    if (column === "zipCode" && newOrder === "descending") {
      sortList.sort((a, b) => (b.zipCode || "").localeCompare(a.zipCode || ""));
    }
    if (column === "status" && newOrder === "ascending") {
      sortList.sort((a, b) => b.active - a.active);
    }
    if (column === "status" && newOrder === "descending") {
      sortList.sort((a, b) => a.active - b.active);
    }
    if (column === "lastLogin" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.lastLogin || "").localeCompare(b.lastLogin || "")
      );
    }
    if (column === "lastLogin" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.lastLogin || "").localeCompare(a.lastLogin || "")
      );
    }
    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setAgentsList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleFilterUsers = (e) => {
    const searchTerm = e.target.value;
    const filteredArray = agentsList.filter((x) =>
      `${x.fullName} ${x.email} ${x.role}`.includes(searchTerm)
    );
    if (searchTerm === "") {
      setFiltered([]);
    } else {
      setFiltered(filteredArray);
    }
  };

  const handleChangeThemeColor = (color) => {
    const agent = { ...selectedAgent };
    Object.assign(agent, { themeColor: color.hex });
    setSelectedAgent(agent);
  };

  const handleChangeBackgroundColor = (color) => {
    const agent = { ...selectedAgent };
    Object.assign(agent, { backgroundColor: color.hex });
    setSelectedAgent(agent);
  };

  const handleSubmitLogo = (files) => {
    console.log(files);
  };

  const handleChangeLogo = (file) => {
    console.log(file);
    setFileToUpload(file);
  };

  const handleTogglePending = () => {
    setPending(!pending);
  };

  const handleSendActivationManually = () => {
    setLoading(true);
    sendActivationManually({ variables: { email: selectedAgent.email } });
  };

  const handleRefresh = () => {
    setInit(true);
    circuits();
  };
  const AgentsList = () => {
    let agents = agentsList;
    if (filteredList && filteredList.length > 0) {
      agents = filteredList;
    }
    if (pending) {
      agents = agents.filter((x) => x.pendingApproval);
    }
    if (!pending) {
      agents = agents.filter((x) => !x.pendingApproval);
    }
    return agents.map((item, index) => {
      return (
        <Table.Row
          active={selectedAgent.agentId === item.agentId}
          key={index}
          onClick={() => handleSelectAgent(item)}
        >
          <Table.Cell>{item.fullName}</Table.Cell>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell>{item.phone}</Table.Cell>
          <Table.Cell>
            {item.company}
            {/* {item.role === "Admin"
              ? "Paper Airplane"
              : item.studioName
              ? item.studioName
              : item.circuitName} */}
          </Table.Cell>
          <Table.Cell>{item.address}</Table.Cell>
          <Table.Cell>{item.city}</Table.Cell>
          <Table.Cell>{item.state}</Table.Cell>
          <Table.Cell>{item.zipCode}</Table.Cell>
          <Table.Cell>{item.role}</Table.Cell>
          <Table.Cell>
            {item.deactivated ? (
              <Label color="orange" content="Deactivated" />
            ) : item.pendingApproval ? (
              <Label color="blue" content="Pending" />
            ) : item.active ? (
              <Label color="green" content="Active" />
            ) : (
              <Label color="grey" content="Inactive" />
            )}
          </Table.Cell>
          <Table.Cell>
            {item.lastLogin ? moment(item.lastLogin).format("LLL") : ""}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      {errorMessage && <Alert failed successMessage={errorMessage} />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Button
            icon="refresh"
            style={{ opacity: 0, pointerEvents: "none" }}
          />
          <Header
            style={{ textAlign: "center", margin: "0px" }}
            content="Users"
          />
          <Button
            title="Refresh Users List"
            disabled={initializing}
            loading={initializing}
            icon="refresh"
            onClick={handleRefresh}
          />
        </div>

        <Input
          style={{ marginBottom: "10px" }}
          fluid
          placeholder="Search..."
          icon="search"
          iconPosition="left"
          onChange={handleFilterUsers}
        />
        <Checkbox
          style={{ marginBottom: "10px" }}
          toggle
          checked={pending}
          label="Show Pending"
          onChange={handleTogglePending}
        />

        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{ alignItems: "center", backgroundColor: "#F1F1F1" }}
            >
              <Header content="Loading Users..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              sortable
              selectable
              style={{
                borderTop: "0px",
                whiteSpace: "nowrap",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "name" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "name" ? sort.order : null}
                    width={4}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "email" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "email" ? sort.order : null}
                    width={6}
                    onClick={() => handleSort("email")}
                  >
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "phone" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "phone" ? sort.order : null}
                    width={6}
                    onClick={() => handleSort("phone")}
                  >
                    Phone
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "company" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "company" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("company")}
                  >
                    Company
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "address" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "address" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("address")}
                  >
                    Address
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "city" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "city" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("city")}
                  >
                    City
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "state" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "state" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("state")}
                  >
                    State
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "zipCode" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "zipCode" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("zipCode")}
                  >
                    Zip Code
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "role" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "role" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("role")}
                  >
                    Role
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "status" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "status" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "lastLogin" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "lastLogin" ? sort.order : null}
                    width={3}
                    onClick={() => handleSort("lastLogin")}
                  >
                    Last Login
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <AgentsList />
              </Table.Body>
            </Table>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={mode === "Add" ? "Add User" : "Edit User"}
          actions={
            <div>
              <Button
                icon="check"
                size="large"
                loading={loading}
                disabled={disabled}
                positive
                content={mode === "Add" ? "Add" : "Update"}
                onClick={mode === "Add" ? handleCreateAgent : handleUpdateAgent}
              />
            </div>
          }
        >
          <AddEditAgent
            loading={loading}
            errorMessage={errorMessage}
            userId={selectedAgent.agentId}
            mode={mode}
            studios={studioList}
            circuits={circuitList}
            roleChangeDisabled={selectedAgent.agentId === loginContext.agentId}
            handleCreateAgent={handleCreateAgent}
            handleUpdateAgent={handleUpdateAgent}
            handleDeactivateAgent={handleDeactivateAgent}
            handleChangeThemeColor={handleChangeThemeColor}
            handleChangeBackgroundColor={handleChangeBackgroundColor}
            handleSubmitLogo={handleSubmitLogo}
            handleChangeLogo={handleChangeLogo}
            fileToUpload={fileToUpload}
            logoPreview={logoPreview}
            handleChangeFieldString={handleChangeFieldString}
            selectedAgent={selectedAgent}
            titles={titleOptions}
          />
        </PageModal>
        <div>
          <Button
            icon="plus"
            size="large"
            style={{ marginTop: "20px" }}
            positive
            floated="right"
            content="Add"
            onClick={handleAddAgent}
          />
          <Button
            icon="edit"
            size="large"
            style={{ marginTop: "20px" }}
            primary
            disabled={Object.keys(selectedAgent).length === 0}
            floated="right"
            content="Edit"
            onClick={handleEditAgent}
          />
          <Button
            icon="send"
            size="large"
            style={{ marginTop: "20px" }}
            primary
            loading={loading}
            disabled={
              Object.keys(selectedAgent).length === 0 ||
              selectedAgent.active ||
              loading
            }
            floated="right"
            content="Activate"
            onClick={handleSendActivationManually}
          />

          <Button
            style={{ marginTop: "20px" }}
            icon="minus"
            size="large"
            loading={loading}
            disabled={
              Object.keys(selectedAgent).length === 0 ||
              disabled ||
              selectedAgent.role === "Admin" ||
              selectedAgent.deactivated
            }
            color="orange"
            floated="right"
            content="Deactivate"
            onClick={handleDeactivateAgent}
          />
          <Button
            style={{ marginTop: "20px" }}
            icon="ban"
            size="large"
            loading={loading}
            disabled={
              Object.keys(selectedAgent).length === 0 ||
              disabled ||
              selectedAgent.role === "Admin"
            }
            negative
            floated="right"
            content="Delete"
            onClick={handleDeleteAgent}
          />
          {loginContext.email === "petez@megameeting.com" && (
            <Dropdown
              upward
              button
              text="API Access"
              style={{ marginTop: "20px" }}
              disabled={
                Object.keys(selectedAgent).length === 0 ||
                disabled ||
                selectedAgent.role === "Admin"
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={selectedAgent.apikey}
                  onClick={handleCreateKey}
                >
                  Generate API Key
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={!selectedAgent.apikey}
                  onClick={handleRevokeKey}
                >
                  Revoke API Key
                </Dropdown.Item>
                {selectedAgent.apikey && (
                  <CopyToClipboard text={selectedAgent.apikey}>
                    <Dropdown.Item>{selectedAgent.apikey}</Dropdown.Item>
                  </CopyToClipboard>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};
export default Users;
