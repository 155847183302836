export const importFields = [
  {
    // Visible in table header and when matching columns.
    label: "Theater Name",
    // This is the key used for this field when we call onSubmit.
    key: "name",
    // Allows for better automatic column matching. Optional.
    alternateMatches: ["theater name"],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: "input",
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: "Theater Name",
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: "required",
        errorMessage: "Theater Name is required",
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: "error",
      },
    ],
  },
  {
    label: "Cinema ID",
    key: "cinemaId",
    alternateMatches: ["cinema ID"],
    fieldType: {
      type: "input",
    },
    example: "c12345",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Last Name is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Rentrack ID",
    key: "rtkTheaterId",
    alternateMatches: ["rentrack ID"],
    fieldType: {
      type: "input",
    },
    example: "5555",
    //   validations: [
    //     {
    //       rule: "required",
    //       errorMessage: "Company is required",
    //       level: "error",
    //     },
    //   ],
  },
  {
    label: "City",
    key: "city",
    alternateMatches: ["city"],
    fieldType: {
      type: "input",
    },
    example: "Boston",
    validations: [
      {
        rule: "required",
        errorMessage: "City is required",
        level: "error",
      },
    ],
  },
  {
    label: "State",
    key: "state",
    alternateMatches: ["state"],
    fieldType: {
      type: "input",
    },
    example: "MA",
    validations: [
      {
        rule: "required",
        errorMessage: "State is required",
        level: "error",
      },
    ],
  },
  {
    label: "Zip Code",
    key: "zipCode",
    alternateMatches: ["zipCode"],
    fieldType: {
      type: "input",
    },
    example: "90210",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Zip Code is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Circuit ID",
    key: "circuitId",
    alternateMatches: ["circuit ID"],
    fieldType: {
      type: "input",
    },
    example: "62222222222222222",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
  {
    label: "Circuit",
    key: "circuit",
    alternateMatches: ["circuit"],
    fieldType: {
      type: "input",
    },
    example: "AMC",
    // validations: [
    //   {
    //     rule: "required",
    //     errorMessage: "Address is required",
    //     level: "error",
    //   },
    // ],
  },
];
