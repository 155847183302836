import {
  Form,
  Message,
  Dropdown,
  List,
  Header,
  Button,
  Label,
  Icon,
  Divider,
} from "semantic-ui-react";

const AddEditCircuit = (props) => {
  const {
    selectedCircuit,
    handleChangeStringField,
    errorMessage,
    searchResults,
    selectedTheaters,
    searchValue,
    addedTheaters,
    handleTheaterSearchValue,
    handleSelectTheater,
    handleRemoveTheater,
    handleSearch,
    editMode,
  } = props;
  const ListItems = () => {
    return selectedTheaters.map((item, index) => {
      return (
        <List.Item key={index}>
          <Header as="h3" style={{ color: "#555555" }}>
            {item.name}
            <Header.Subheader style={{ color: "#888888" }}>
              {item.city}, {item.state} {item.zipCode}
            </Header.Subheader>
          </Header>
        </List.Item>
      );
    });
  };
  const SearchResults = () => {
    return searchResults.map((item, index) => {
      return (
        <Dropdown.Item key={index} onClick={() => handleSelectTheater(item)}>
          <Header>
            {item.name}
            <Header.Subheader>
              {item.city}, {item.state} {item.zipCode}
            </Header.Subheader>
          </Header>
        </Dropdown.Item>
      );
    });
  };
  const AddedTheaters = () => {
    return addedTheaters.map((item, index) => {
      return (
        <Label color="blue" size="large" key={index}>
          {item.name}{" "}
          <Icon name="delete" onClick={() => handleRemoveTheater(item)} />
        </Label>
      );
    });
  };
  return (
    <Form size="large">
      <Form.Input
        id="circuit"
        label="Circuit Name"
        placeholder="Circuit Name"
        value={selectedCircuit.circuit || ""}
        onChange={handleChangeStringField}
      />
      {/* <Form.Field>
        <label>Theaters</label>
        <Dropdown
          lazyLoad
          multiple
          search
          selection
          id="theaters"
          placeholder="Select Theaters"
          options={theatersList}
          value={selectedTheaters}
          onChange={handleChangeSelectedTheaters}
        />
      </Form.Field> */}
      {editMode && (
        <Form.Field>
          <label>Add Theaters</label>
          <div style={{ display: "flex" }}>
            <Dropdown
              fluid
              icon={null}
              placeholder="Search by Theater Name"
              search
              selection
              searchQuery={searchValue}
              onSearchChange={handleTheaterSearchValue}
              open={searchResults && searchResults.length > 0}
            >
              <Dropdown.Menu>
                <SearchResults />
              </Dropdown.Menu>
            </Dropdown>
            <Button
              style={{ marginLeft: "10px" }}
              secondary
              content="Search"
              onClick={handleSearch}
            />
          </div>
          {addedTheaters.length > 0 && (
            <div style={{ marginTop: "10px" }}>
              <AddedTheaters />
            </div>
          )}
        </Form.Field>
      )}
      {editMode && <Divider />}
      {editMode && selectedTheaters.length > 0 && (
        <Form.Field>
          <Header as="h4" content="Associated Theaters" />
          <div
            style={{
              maxHeight: "200px",
              overflowY: "scroll",
              padding: "10px",
              border: "1px solid #C9C9C9",
              borderRadius: "5px",
            }}
          >
            <List divided>
              <ListItems />
            </List>
          </div>
        </Form.Field>
      )}

      {errorMessage && (
        <Form.Field>
          <Message visible error header="Error" content={errorMessage} />
        </Form.Field>
      )}
    </Form>
  );
};
export default AddEditCircuit;
