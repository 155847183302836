import { useEffect, useState, useRef } from "react";
import {
  Header,
  Table,
  Segment,
  Icon,
  Input,
  Button,
  Dropdown,
  Label,
  Checkbox,
  List,
  Form,
} from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  THEATERS,
  CIRCUITS,
  ADD_THEATER,
  UPDATE_THEATER,
  CAMPAIGNS,
  TAG_THEATERS,
  UNTAG_THEATERS,
} from "../graphQueries";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { importFields } from "./importFields";
import { tagFields } from "./tagFields";
import PageModal from "../PageModal";
import AddEditTheater from "./AddEditTheater";
import Alert from "../Alert/Alert";
const Theaters = (props) => {
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({ column: "circuit", order: "ascending" });
  const [filteredList, setFiltered] = useState([]);
  const [theatersList, setTheatersList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [circuitsList, setCircuitsList] = useState([]);
  const [importOpen, setImportOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [recordsDisplayed, setRecordsDisplayed] = useState(0);
  const [importOption, setImportOption] = useState("");
  const [preImportOpen, setPreOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTheater, setSelectedTheater] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [preTag, setPreTag] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [untag, setUntag] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedCircuit, setSelectedCircuit] = useState("");

  const clearTimerRef = useRef();

  useEffect(() => {
    console.log(theatersList);
    handleFilter();
  }, [theatersList]);

  useEffect(() => {
    console.log(selectedTheater);
  }, [selectedTheater]);

  useEffect(() => {
    console.log("selectedCampaign", selectedCampaign);
  });

  useEffect(() => {
    circuits();
    // theaters();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [filter]);

  // circuits query
  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });

  const handleCircuits_Result = (result) => {
    if (result.circuits) {
      let array = result.circuits.map((item) =>
        Object.assign({}, item, { text: item.circuit, value: item.circuitId })
      );
      array.unshift({
        text: "All Circuits",
        circuit: "All Circuits",
        circuitId: "all",
        value: "all",
      });
      setCircuitsList(array);
      campaigns();
    }
  };

  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  const getCampaignName = (campaignId) => {
    const campaign = campaignsList.filter((x) => x.campaignId === campaignId);
    if (campaign.length) {
      return campaign[0].titleName;
    }
  };

  // theaters query
  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });
  const handleTheaters_Result = (result) => {
    if (result.theaters && result.theaters.length) {
      let array = result.theaters.map((item) => Object.assign({}, item, {}));
      for (const a of array) {
        const circ = circuitsList.filter((x) => x.circuitId === a.circuitId);
        Object.assign(a, { circuit: circ[0].circuit });
        const arrayOfCampaigns = a.campaigns ? a.campaigns.split(",") : [];
        Object.assign(a, { campaigns: arrayOfCampaigns });
      }
      const sorted = array.sort((a, b) =>
        (a.circuit || "").localeCompare(b.circuit || "")
      );
      setTheatersList(sorted);
      setInit(false);
      setNumPages(Math.ceil(array.length / 50));
      setRecordsDisplayed(array.length);
      // handleSort("circuit");
    }
  };
  const handleTheaters_Error = (error) => {
    console.log(error);
  };

  // campaigns query
  const [campaigns] = useLazyQuery(CAMPAIGNS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCampaigns_Result(result),
    onError: (error) => handleCampaigns_Error(error),
  });

  const handleCampaigns_Result = (result) => {
    if (result.campaigns) {
      let array = result.campaigns.map((item) =>
        Object.assign({}, item, {
          text: item.titleName,
          value: item.campaignId,
        })
      );
      setCampaignsList(array);
    }
    theaters();
  };

  const handleCampaigns_Error = (error) => {
    console.log(error);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...theatersList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }
    if (column === "circuit" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.circuit || "").localeCompare(b.circuit || ""));
    }
    if (column === "circuit" && newOrder === "descending") {
      sortList.sort((a, b) => (b.circuit || "").localeCompare(a.circuit || ""));
    }
    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) => (b.name || "").localeCompare(a.name || ""));
    }
    if (column === "city" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.city || "").localeCompare(b.city || ""));
    }
    if (column === "city" && newOrder === "descending") {
      sortList.sort((a, b) => (b.city || "").localeCompare(a.city || ""));
    }
    if (column === "state" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.state || "").localeCompare(b.state || ""));
    }
    if (column === "state" && newOrder === "descending") {
      sortList.sort((a, b) => (b.state || "").localeCompare(a.state || ""));
    }
    if (column === "zipCode" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.zipCode || "").localeCompare(b.zipCode || ""));
    }
    if (column === "zipCode" && newOrder === "descending") {
      sortList.sort((a, b) => (b.zipCode || "").localeCompare(a.zipCode || ""));
    }
    if (column === "rtkTheaterId" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.rtkTheaterId || "").localeCompare(b.rtkTheaterId || "")
      );
    }
    if (column === "rtkTheaterId" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.rtkTheaterId || "").localeCompare(a.rtkTheaterId || "")
      );
    }
    if (column === "cinemaId" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.cinemaId || "").localeCompare(b.cinemaId || "")
      );
    }
    if (column === "cinemaId" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.cinemaId || "").localeCompare(a.cinemaId || "")
      );
    }
    if (column === "alternateId" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.alternateId || "").localeCompare(b.alternateId || "")
      );
    }
    if (column === "alternateId" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.alternateId || "").localeCompare(a.alternateId || "")
      );
    }

    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setTheatersList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const handleSetFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleFilter = () => {
    setSearching(true);
    const searchTerm = filter.toUpperCase();
    const termsArray = searchTerm.split(" ");
    console.log(termsArray);
    let filteredArray = [...theatersList];
    for (const t of termsArray) {
      filteredArray = filteredArray.filter((x) =>
        `${x.circuit} ${x.name} ${x.city} ${x.state} ${x.zipCode} ${x.campaigns} ${x.rtkTheaterId} ${x.alternateId} ${x.cinemaId}`
          .toUpperCase()
          .includes(t)
      );
    }
    // const filteredArray = theatersList.filter((x) =>
    //   `${x.circuit} ${x.name} ${x.city} ${x.state} ${x.zipCode} ${x.campaigns} ${x.rtkTheaterId} ${x.cinemaId}`
    //     .toUpperCase()
    //     .includes(searchTerm)
    // );
    clearTimeout(clearTimerRef.current);
    clearTimerRef.current = setTimeout(() => {
      setSearching(false);
      if (searchTerm === "") {
        setFiltered([]);
      } else {
        setFiltered(filteredArray);
      }
    }, 500);
  };

  const handleRefresh = () => {
    circuits();
    setInit(true);
  };

  const handleTagClose = () => {
    setTagOpen(false);
    setPreTag(false);
  };

  const handleTagSubmit = (data) => {
    console.log(data);
    const theaters = data.validData;
    for (const t of theaters) {
      Object.assign(t, { campaignId: selectedCampaign });
    }
    console.log(theaters);
    tagTheaters({ variables: { theaterInfo: theaters } });
  };

  const [tagTheaters] = useMutation(TAG_THEATERS, {
    onCompleted: (result) => handleTagTheaters_Result(result),
    onError: (error) => handleTagTheatesr_Error(error),
  });

  const handleTagTheaters_Result = (result) => {
    console.log(result);
    if (result && result.tagTheaters.status === "succeeded") {
      if (result.tagTheaters.tagResult) {
        const array = result.tagTheaters.tagResult.split(",");
        setSuccessMessage(
          `${array.length} were not tagged: ${result.tagTheaters.tagResult}`
        );
        setTimeout(() => {
          setSuccessMessage("");
        }, 30000);
      } else {
        setSuccessMessage(`All tagged successfully!`);
        setTimeout(() => {
          setSuccessMessage("");
        }, 4000);
      }
    }
  };

  const handleTagTheatesr_Error = (error) => {
    console.log(error);
  };

  const [untagTheaters] = useMutation(UNTAG_THEATERS, {
    onCompleted: (result) => handleUntagTheaters_Result(result),
    onError: (error) => handleUntagTheaters_Error(error),
  });

  const handleUntagTheaters_Result = (result) => {
    console.log(result);
    if (result.untagTheaters.status === "succeeded") {
      setSuccessMessage(result.untagTheaters.message);
      handleRefresh();
      resetStateTimer();
    }
  };

  const handleUntagTheaters_Error = (error) => {
    console.log(error);
  };

  const handleImportClose = () => {
    setImportOpen(false);
    setPreOpen(false);
    setImportOption("");
  };

  const handleImportSubmit = (data) => {
    console.log(data);
    // determine if theaters exist
    const key = importOption;
    const newTheaters = data.validData;
    const existingTheaters = [...theatersList];
    let matches = 0;
    let toAdd = [];
    for (const n of newTheaters) {
      const found = existingTheaters.filter((x) => x[key] === n[key]);
      if (found.length) {
        matches++;
      } else {
        toAdd.push(n);
      }
    }
    console.log("matches", matches);
    console.log("total new", newTheaters.length);
    console.log("toAdd", toAdd);
  };

  const clearError = () => {};

  const handleChangePage = (direction) => {
    if (direction === "up") {
      setPage(page + 1);
    }
    if (direction === "down") {
      setPage(page - 1);
    }
    const scrollingContainer = document.getElementById("scrollingContainer");
    if (scrollingContainer) {
      scrollingContainer.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const getPages = () => {
    let arrayOfPages = [];
    let count = 1;
    while (count <= numPages) {
      const obj = {
        text: count,
        value: count,
      };
      arrayOfPages.push(obj);
      count++;
    }
    // console.log(arrayOfPages);
    return arrayOfPages;
  };

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleSelectTheater = (obj) => {
    setSelectedTheater(obj);
  };

  const handleChangeFieldString = (e, data) => {
    const key = data.id;
    const value = data.value;
    const obj = { ...selectedTheater };
    Object.assign(obj, { [key]: value });
    setSelectedTheater(obj);
  };

  const handleAddTheater = () => {
    setModalOpen(true);
    setEditMode(false);
    setSelectedTheater({});
  };

  const handleEditTheater = () => {
    setModalOpen(true);
    setEditMode(true);
  };

  const handleSaveNewTheater = () => {
    setLoading(true);
    setDisabled(true);
    console.log(selectedTheater);
    // attach circuit name
    const foundCircuit = circuitsList.filter(
      (x) => x.circuitId === selectedTheater.circuitId
    );
    if (foundCircuit.length) {
      Object.assign(selectedTheater, { circuit: foundCircuit[0].circuit });
    }
    const obj = { ...selectedTheater };
    if (selectedTheater.campaigns) {
      Object.assign(obj, { campaigns: selectedTheater.campaigns.join(",") });
    }
    addTheater({ variables: obj });
  };

  const handleUpdateTheater = () => {
    console.log(selectedTheater);
    setLoading(true);
    setDisabled(true);
    const obj = { ...selectedTheater };
    if (selectedTheater.campaigns) {
      Object.assign(obj, { campaigns: selectedTheater.campaigns.join(",") });
    }
    updateTheater({ variables: obj });
  };

  const [addTheater] = useMutation(ADD_THEATER, {
    onCompleted: (result) => handleAddTheater_Result(result),
    onError: (error) => handleAddTheater_Error(error),
  });

  const handleAddTheater_Result = (result) => {
    console.log(result);
    if (result.addTheater.status === "succeeded") {
      setSuccessMessage("Theater Added Successfully!");
      setLoading(false);
      theaters();
      resetStateTimer();
    }
  };

  const handleAddTheater_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    resetStateTimer();
  };

  const [updateTheater] = useMutation(UPDATE_THEATER, {
    onCompleted: (result) => handleUpdateTheater_Result(result),
    onError: (error) => handleUpdateTheater_Error(error),
  });

  const handleUpdateTheater_Result = (result) => {
    console.log(result);
    if (result.updateTheater.status === "succeeded") {
      setSuccessMessage("Theater Updated Successfully!");
      setLoading(false);
      theaters();
      resetStateTimer();
    }
  };

  const handleUpdateTheater_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    resetStateTimer();
  };

  const resetStateTimer = () => {
    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
      setErrorMessage("");
      setSuccessMessage("");
      setModalOpen(false);
      setEditMode(false);
      setSelectedTheater({});
      setSelectedCampaign("");
      setSelectedCircuit("");
      setUntag(false);
    }, 2000);
  };

  const handleSelectCampaign = (e, data) => {
    console.log("inside handleSelectCampaign");
    console.log(data);
    setSelectedCampaign(data.value);
  };

  const handleSelectCircuit = (e, data) => {
    setSelectedCircuit(data.value);
  };

  const handleUntag = () => {
    if (!selectedCircuit || !selectedCampaign) return;
    setDisabled(true);
    setLoading(true);
    untagTheaters({
      variables: {
        circuitId: selectedCircuit,
        campaignId: selectedCampaign,
      },
    });
  };

  const handleChangeCampaigns = (e, data) => {
    const obj = { ...selectedTheater };
    Object.assign(obj, { campaigns: data.value });
    setSelectedTheater(obj);
  };

  const getCampaigns = (array) => {
    let newArray = [];
    for (const a of array) {
      const campObj = campaignsList.filter((x) => x.campaignId === a);
      if (campObj.length) {
        newArray.push(campObj[0].titleName);
      }
    }
    return newArray.map((item, index) => {
      return (
        <Label color="blue" key={index}>
          {item}
        </Label>
      );
    });
  };

  const TheatersList = () => {
    let theaters = theatersList;
    if (filteredList && filteredList.length > 0) {
      theaters = filteredList;
    }
    setNumPages(Math.ceil(theaters.length / 50));
    setRecordsDisplayed(theaters.length);
    const paged = paginate(theaters, 50, page);
    return paged.map((item, index) => {
      return (
        <Table.Row
          key={index}
          active={item.theaterId === selectedTheater.theaterId}
          onClick={() => handleSelectTheater(item)}
        >
          <Table.Cell>{item.theaterId}</Table.Cell>
          <Table.Cell>{item.circuit}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.city}</Table.Cell>
          <Table.Cell>{item.state}</Table.Cell>
          <Table.Cell>{item.zipCode}</Table.Cell>
          <Table.Cell>{item.rtkTheaterId}</Table.Cell>
          <Table.Cell>{item.alternateId}</Table.Cell>
          <Table.Cell>{item.cinemaId}</Table.Cell>
          <Table.Cell>{getCampaigns(item.campaigns)}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {successMessage && <Alert successMessage={successMessage} />}
      {errorMessage && <Alert failed={true} successMessage={errorMessage} />}
      {preImportOpen && (
        <PageModal
          clearError={clearError}
          open={preImportOpen}
          setOpen={setPreOpen}
          heading="Select Theater Type"
          actions={
            <Button
              disabled={importOption === ""}
              primary
              content="Continue"
              onClick={() => {
                setImportOpen(true);
                setPreOpen(false);
              }}
            />
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Header content="NOTE: This feature doesn't work yet." />
            <Checkbox
              style={{ margin: "10px" }}
              radio
              label="Cinema ID"
              onChange={() => setImportOption("cinemaId")}
              checked={importOption === "cinemaId"}
            />
            <Checkbox
              style={{ margin: "10px" }}
              radio
              label="Rentrack ID"
              onChange={() => setImportOption("rtkTheaterId")}
              checked={importOption === "rtkTheaterId"}
            />
          </div>
        </PageModal>
      )}
      {preTag && (
        <PageModal
          clearError={clearError}
          open={preTag}
          setOpen={setPreTag}
          heading="Tag Theaters for Campaign"
          noScroll
          actions={
            <Button
              primary
              content="Next"
              onClick={() => {
                setTagOpen(true);
                setPreTag(false);
              }}
            />
          }
        >
          <div>
            <p>
              <ul>
                <li>
                  Select a campaign, and then continue to import a list of
                  theaters with Rentrak IDs to tag with this campaign.
                </li>
                <li>
                  The spreadsheet to import needs only 1 column with the heading
                  rtkTheaterId. No other fields are needed in the spreadsheet.{" "}
                </li>
                <li>
                  <b>NOTE: </b> For this to take effect, Custom Theater List
                  must be enabled for the campaign, which can be
                  enabled/disabled at any time.
                </li>
              </ul>
            </p>
            <Dropdown
              placeholder="Select Campaign..."
              fluid
              value={selectedCampaign || ""}
              options={campaignsList}
              onChange={handleSelectCampaign}
              selection
              selectOnBlur={false}
            />
          </div>
        </PageModal>
      )}
      {untag && (
        <PageModal
          clearError={clearError}
          open={untag}
          setOpen={setUntag}
          heading="Untag Theaters from Campaign"
          noScroll
          actions={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="large"
                disabled={disabled}
                loading={loading}
                content="Cancel"
                onClick={() => setUntag(false)}
              />
              <Button
                size="large"
                disabled={disabled}
                loading={loading}
                primary
                content="Confirm"
                onClick={handleUntag}
              />
            </div>
          }
        >
          <div>
            <Header.Subheader>
              This interface allows you to untag all theaters of a single
              circuit from a single campaign. If the circuit is only partially
              tagged, all tagged theaters under the circuit will still be
              untagged. <b>Note: </b>this make take a few seconds to process for
              larger circuits.
            </Header.Subheader>
            <br />
            <p>
              <List ordered size="large">
                <List.Item>Select a Circuit.</List.Item>
                <List.Item>Select a Campaign.</List.Item>
                <List.Item>Click Confirm.</List.Item>
              </List>
            </p>
            <Form size="large">
              <Form.Group widths={"equal"}>
                <Form.Select
                  label="Select Circuit"
                  placeholder="Select Circuit..."
                  fluid
                  value={selectedCircuit || ""}
                  options={circuitsList}
                  onChange={handleSelectCircuit}
                  selection
                  search
                />
                <Form.Select
                  label="Select Campaign"
                  placeholder="Select Campaign..."
                  fluid
                  value={selectedCampaign || ""}
                  options={campaignsList}
                  onChange={handleSelectCampaign}
                  selection
                  search
                />
              </Form.Group>
            </Form>
          </div>
        </PageModal>
      )}
      {tagOpen && (
        <ReactSpreadsheetImport
          isOpen={tagOpen}
          onClose={handleTagClose}
          onSubmit={handleTagSubmit}
          fields={tagFields}
        />
      )}
      {importOpen && (
        <ReactSpreadsheetImport
          isOpen={importOpen}
          onClose={handleImportClose}
          onSubmit={handleImportSubmit}
          fields={importFields}
        />
      )}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Button
            icon="refresh"
            style={{ opacity: 0, pointerEvents: "none" }}
          />
          <Header
            style={{
              textAlign: "center",
              margin: "0px",
            }}
            content="Theaters"
          />
          <Button
            title="Refresh Theaters List"
            disabled={initializing}
            loading={initializing}
            icon="refresh"
            // style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={handleRefresh}
          />
        </div>
        <Input
          style={{ marginBottom: "20px" }}
          placeholder="Search by Circuit, Name, City, State, or Zip Code..."
          icon={
            searching ? (
              <Icon loading name="circle notch" />
            ) : (
              <Icon name="search" />
            )
          }
          iconPosition="left"
          value={filter}
          onChange={handleSetFilter}
          action={
            <Button
              content="Clear Search"
              secondary={filter}
              disabled={!filter}
              onClick={() => setFilter("")}
            />
          }
        />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Theaters..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              sortable
              selectable
              style={{
                borderTop: "0px",
                whiteSpace: "nowrap",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "id" ? "aliceblue" : "",
                    }}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "circuit" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "circuit" ? sort.order : null}
                    onClick={() => handleSort("circuit")}
                  >
                    Circuit
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "name" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "name" ? sort.order : null}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "city" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "city" ? sort.order : null}
                    onClick={() => handleSort("city")}
                  >
                    City
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "state" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "state" ? sort.order : null}
                    onClick={() => handleSort("state")}
                  >
                    State
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "zipCode" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "zipCode" ? sort.order : null}
                    onClick={() => handleSort("zipCode")}
                  >
                    Zip Code
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "rtkTheaterId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "rtkTheaterId" ? sort.order : null}
                    onClick={() => handleSort("rtkTheaterId")}
                  >
                    Rentrack ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "alternateId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "alternateId" ? sort.order : null}
                    onClick={() => handleSort("alternateId")}
                  >
                    Alternate ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "cinemaId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "cinemaId" ? sort.order : null}
                    onClick={() => handleSort("cinemaId")}
                  >
                    Cinema ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "cinemaId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "cinemaId" ? sort.order : null}
                    // onClick={() => handleSort("cinemaId")}
                  >
                    Campaigns
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <TheatersList />
              </Table.Body>
            </Table>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={editMode ? "Edit Theater" : "Add Theater"}
          actions={
            <div>
              <Button
                loading={loading}
                disabled={disabled}
                size="large"
                positive
                icon="check"
                content="Save"
                onClick={editMode ? handleUpdateTheater : handleSaveNewTheater}
              />
            </div>
          }
        >
          <AddEditTheater
            campaignsList={campaignsList}
            circuitsList={circuitsList}
            selectedTheater={selectedTheater}
            handleChangeFieldString={handleChangeFieldString}
            handleChangeCampaigns={handleChangeCampaigns}
          />
        </PageModal>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              size="large"
              style={{ marginTop: "20px" }}
              disabled={page === 1}
              icon="left arrow"
              onClick={() => handleChangePage("down")}
            />
            <Dropdown
              style={{ marginRight: "5px" }}
              selection
              compact
              scrolling
              options={getPages()}
              onChange={(e, data) => setPage(data.value)}
              value={page}
            />
            <Button
              size="large"
              style={{ marginTop: "20px" }}
              disabled={page === numPages}
              icon="right arrow"
              onClick={() => handleChangePage("up")}
            />
            <span>
              {page === 1 ? 1 : ((page - 1) * 50 + 1).toLocaleString()} to{" "}
              {page * 50 + 1 > recordsDisplayed
                ? recordsDisplayed.toLocaleString()
                : (page * 50 + 1).toLocaleString()}{" "}
            </span>
            <span>
              <b>({recordsDisplayed.toLocaleString()} total)</b>
            </span>
          </div>
          <div>
            <Button
              disabled={Object.keys(selectedTheater).length === 0}
              size="large"
              icon="edit"
              primary
              content="Edit"
              onClick={handleEditTheater}
            />
            <Button
              size="large"
              icon="plus"
              positive
              content="Add"
              onClick={handleAddTheater}
            />
            {/* <Button
              title="Import is not yet working"
              disabled
              icon="upload"
              size="large"
              style={{ marginTop: "20px" }}
              positive
              content="Import (TBD)"
              onClick={() => setPreOpen(true)}
            /> */}
            <Button
              content="Tag Theaters"
              icon="tag"
              size="large"
              style={{ marginTop: "20px" }}
              positive
              onClick={() => setPreTag(true)}
            />
            <Button
              content="Untag Theaters"
              icon="tag"
              size="large"
              style={{ marginTop: "20px" }}
              negative
              onClick={() => setUntag(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Theaters;
