import {
  Form,
  Message,
  Header,
  Segment,
  Input,
  Label,
  Image,
  Icon,
} from "semantic-ui-react";
import { SketchPicker } from "react-color";
import { FileUploader } from "react-drag-drop-files";
import { states } from "../states";

const AddEditAgent = (props) => {
  const {
    userId,
    studios,
    circuits,
    roleChangeDisabled,
    handleChangeThemeColor,
    handleChangeBackgroundColor,
    fileToUpload,
    handleChangeLogo,
    logoPreview,
    selectedAgent,
    handleChangeFieldString,
    titles,
  } = props;
  const roles = [
    {
      text: "Admin",
      value: "Admin",
    },
    {
      text: "Exhibitor",
      value: "Exhibitor",
    },
    {
      text: "Studio",
      value: "Studio",
    },
    {
      text: "Agency",
      value: "Agency",
    },
  ];
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

  return (
    <Form size="large">
      {userId ? <Header as="h5" content={`User ID: ${userId}`} /> : null}
      <Form.Input
        required
        id="fullName"
        label="Full Name"
        placeholder="Full Name"
        value={selectedAgent.fullName || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        required
        id="email"
        label="Email"
        placeholder="Email"
        value={selectedAgent.email || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="phone"
        label="Phone"
        placeholder="Phone"
        value={selectedAgent.phone || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="address"
        label="Street Address"
        placeholder="Street Address"
        value={selectedAgent.address || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Input
        id="city"
        label="City"
        placeholder="City"
        value={selectedAgent.city || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Select
        search
        onChange={handleChangeFieldString}
        id="state"
        placeholder="State"
        label="State"
        value={selectedAgent.state || ""}
        options={states}
      />
      <Form.Input
        id="zipCode"
        label="Zip Code"
        placeholder="Zip Code"
        value={selectedAgent.zipCode || ""}
        onChange={handleChangeFieldString}
      />
      <Form.Select
        required
        id="role"
        label="Role"
        placeholder="Select Role"
        options={roles}
        value={selectedAgent.role || ""}
        onChange={handleChangeFieldString}
        disabled={roleChangeDisabled}
      />
      {roleChangeDisabled && (
        <b>
          Admins cannot change their own role. If you want to change your role
          for testing purposes, have another admin make the change, or create a
          new user with a different email address.
        </b>
      )}
      {selectedAgent.role === "Studio" && (
        <Form.Select
          required={selectedAgent.role === "Studio"}
          id="studioId"
          search
          label="Studio"
          placeholder="Select Studio"
          options={studios}
          value={selectedAgent.studioId || ""}
          onChange={handleChangeFieldString}
        />
      )}
      {(selectedAgent.role === "Studio" || selectedAgent.role === "Agency") && (
        <Form.Select
          id="titleId"
          multiple
          search
          label="Titles"
          placeholder="Select Titles or Leave Blank for All Titles"
          options={titles}
          value={selectedAgent.titleId || ""}
          onChange={handleChangeFieldString}
        />
      )}
      {selectedAgent.role === "Exhibitor" && (
        <Form.Select
          multiple
          required={selectedAgent.role === "Exhibitor"}
          id="circuitId"
          search
          label="Circuit"
          placeholder="Circuit"
          options={circuits}
          value={selectedAgent.circuitId || []}
          onChange={handleChangeFieldString}
        />
      )}
      <Form.Checkbox
        toggle
        checked={selectedAgent.readOnly}
        id="readOnly"
        label="Read Only Access"
        onChange={handleChangeFieldString}
      />
      <Form.Checkbox
        toggle
        checked={selectedAgent.disableExport}
        id="disableExport"
        label="Disable Export"
        onChange={handleChangeFieldString}
      />
      <Form.Checkbox
        toggle
        checked={selectedAgent.enableNotifications}
        id="enableNotifications"
        label="Notifications"
        onChange={handleChangeFieldString}
      />
      <Form.Group widths="equal">
        <Form.Field>
          <label>Theme Color</label>
          <Input
            value={selectedAgent.themeColor || ""}
            label={
              <Label
                style={{
                  backgroundColor: selectedAgent.themeColor,
                  width: "50px",
                }}
              />
            }
            labelPosition="right"
          />
          <SketchPicker
            color={selectedAgent.themeColor || ""}
            onChangeComplete={handleChangeThemeColor}
          />
        </Form.Field>
        <Form.Field>
          <label>Background Color</label>
          <Input
            value={selectedAgent.backgroundColor || ""}
            label={
              <Label
                style={{
                  backgroundColor: selectedAgent.backgroundColor,
                  width: "50px",
                }}
              />
            }
            labelPosition="right"
          />
          <SketchPicker
            color={selectedAgent.backgroundColor || ""}
            onChangeComplete={handleChangeBackgroundColor}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Logo</label>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Image
            size="small"
            src={
              fileToUpload !== null
                ? URL.createObjectURL(fileToUpload)
                : logoPreview
                ? logoPreview
                : "/images/placeholder.png"
            }
          />
          <FileUploader
            handleChange={handleChangeLogo}
            name="file"
            types={fileTypes}
            hoverTitle=" "
            children={
              <div
                style={{
                  borderStyle: "dashed",
                  flex: 1,
                  height: "100%",
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  borderColor: "#888888",
                }}
              >
                <Header>
                  <Icon name="upload" />
                  <Header.Content>
                    Drop a file here
                    <Header.Subheader>Or click to browse</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            }
          />
        </div>
      </Form.Field>
      <Segment padded="very" style={{ backgroundColor: "#F1F1F1" }}>
        <p>
          <b>Note: </b> New users added will automatically receive a link via
          email to activate their account. Upon following the link they will be
          prompted to set their password before logging in.
        </p>
      </Segment>
    </Form>
  );
};
export default AddEditAgent;
