import {
  Modal,
  Button,
  Dropdown,
  Header,
  Image,
  Divider,
} from "semantic-ui-react";
const SelectTitle = (props) => {
  const {
    titleList,
    selectedTitle,
    setIsSelectTitle,
    handleChangeTitle,
    isStudioPage,
    studioObj,
  } = props;
  const DropdownItems = () => {
    return titleList.map((item, index) => {
      return (
        <Dropdown.Item
          disabled={item.expired || item.premature}
          key={index}
          onClick={(e) => handleChangeTitle(e, item)}
        >
          {item.name}{" "}
          {item.expired
            ? `(No Longer Available)`
            : item.premature
            ? `(Not Yet Available)`
            : null}
        </Dropdown.Item>
      );
    });
  };
  return (
    <Modal open size="tiny" style={{ background: "rgba(255,255,255,0.6)" }}>
      <Modal.Content style={{ background: "rgba(255,255,255,0.6)" }}>
        {isStudioPage && (
          <div>
            <Image src={studioObj.artworkLink} />
            <Header
              style={{ textAlign: "center" }}
              content="Group Sales Home Page"
            />
            <Divider />
          </div>
        )}
        <div>
          <Header content="Select a Title" />
          <Dropdown
            placeholder="Select a Title"
            selection
            fluid
            options={titleList}
            value={selectedTitle.value || ""}
          >
            <Dropdown.Menu>
              <DropdownItems />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button
          primary
          disabled={Object.keys(selectedTitle).length === 0}
          content="Continue"
          onClick={() => setIsSelectTitle(false)}
        />
      </Modal.Actions>
    </Modal>
  );
};
export default SelectTitle;
